import React, {useState,useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc, getCountFromServer} from 'firebase/firestore';
import {auth_, db} from './../firebase';
import {getAuth, onAuthStateChanged} from "firebase/auth";

import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../components/Popup/Popup";
import { Settings } from "lucide-react";

import {BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell} from 'recharts';
import _ from 'lodash';
import { all } from "axios";
const ReadingChallenge = () =>{
    
    const user = auth_.currentUser;

    const navigate = useNavigate();

    const [processedData, setProcessedData] = useState({
        genreDistribution: [],
        addedOvertime: [],
        monthlyAdditions: []
    })


    const [genreDistributionData, setGenreDistribution] = useState([]);


    const onFinishedBookButton  = () =>{
 
        navigate('/finishedreading');

    }

    // const [user, setUser] = useState("");
    const [booksReadCount, countFinishedBooks] = useState(0);
    const [bookGoal, showBookGoal] = useState(0);
    const [bookGoalSettingForm, showBookGoalSettingForm] = useState(false);

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [loading, setLoading] = useState(true);

    

    const COLORS = ['#2196F3','#4CAF50','#FFC107', '#FF8042',];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

   

    const getFinishedBooksCount= async() =>{
        try{
        const firestore = getFirestore();
        const c = collection(firestore, "Users", user.uid, "Finished Book");
        const count = await getCountFromServer(c);
        
        countFinishedBooks(count.data().count);

        console.log("Number of books!");
        console.log(count.data().count);
        }catch(e){
            console.log("Error:", e);
        }
        }




    const getFinishedReadingBooks = async() =>{
        try{
            const finishedReadingBooksSnapshot = await getDocs(collection(db, "Users", user.uid, "Finished Book"));
            const readBooks = finishedReadingBooksSnapshot.docs.map(doc=>({
                ...doc.data()
            }));


            showFinishedReadingBooks(readBooks);

            const booksWithDates = readBooks.map(
                book =>{
                    const normalizedDate = normalizeDate(book.DateandTime);
                    if(!normalizedDate){
                        console.log("could not normalize date:", book);
                        return null;
                    }

                    return{
                        ...book,
                        normalizedDate
                    }
                    
                }).filter(Boolean);


        
        
            // sort by date
            const sortedBooks = _.sortBy(booksWithDates, 'normalizedDate');
            
            // create cumulative data for line chart
            const addedOvertime  = sortedBooks.map((book, index)=>{

                if(!(book.normalizedDate instanceof Date)){
                    // console.log('Invalid date');
                    return null;
                }


                const dateStr = book.normalizedDate.toISOString().split('T')[0];
                return {
                    date: dateStr,
                    total: index + 1
                }
                

            });


            const monthlyGroups = _.groupBy(booksWithDates, book =>{
                try{

                    if(!(book.normalizedDate instanceof Date)){
                        console.warn('Invalid date found in monthly grouping: ', book.normalizedDate);
                        return 'unknown';

                    }

                    return book.normalizedDate.toISOString().slice(0,7);

                }catch(e){
                    console.error('Error processing date for monthly grouping');
                    return 'unknown';
                }


            });

            const monthlyAdditions = Object.entries(monthlyGroups).filter(([month])=>month !== 'unknown').map(([month, books])=>({
                month,
                count: books.length
            }));


            const allBooksSnapshot = await getDocs(collection(db, "Books"));
            const allBooks = allBooksSnapshot.docs.map(doc =>({
                id: doc.id,
                ...doc.data()
            }));

            const bookInfoMap = new Map();
                
            allBooks.forEach(book => {
                if(book.title){
                   
                bookInfoMap.set(book.title, book.category )

            }});

            console.log(bookInfoMap);
            

            const enrichedBooks = readBooks.map(book =>{
                // const bookTitle = book.bookTitle?.toLowerCase();
                // const fullBookInfo = bookTitle ? bookInfoMap.get(bookTitle) : null;

                // console.log(book);

                return {
                    ...book,
                    genre: bookInfoMap.get(book.bookTitle)
                }
            });

           


            const allGenres = _.flatMap(enrichedBooks, book => book.genre || []);

            // console.log("Genres");
            // console.log(allGenres);

            const genreCount = _.countBy(allGenres);
            const genreDistribution = Object.entries(genreCount).filter(([genre]) => genre).map(([genre, count])=>({
                genre: genre || "Unknown", 
                count
            })).sort((a,b)=>b.count - a.count);

            // console.log("Genres");
            // console.log(genreDistribution);

            // setGenreDistribution(genreDistribution);


            setProcessedData({
                genreDistribution: genreDistribution,
                addedOvertime: addedOvertime,
                monthlyAdditions: _.sortBy(monthlyAdditions, 'month')

            });





            
        }catch(e){

            console.error("Error reading challenge:", e);
        }finally{
            setLoading(false);
        }
        }

       

        const getBookGoal = async() =>{
            try{
            await getDocs(collection(db, "Users", user.uid, "ReadingChallenge")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                    // console.log("BOOKS:");
                    // console.log(book[0].numBooksWantReadGoalAmount);
                    showBookGoal(book[0].numBooksWantReadGoalAmount);   
                    
                    console.log("Book Goal");
                    console.log(book[0].numBooksWantReadGoalAmount);
            });   
        }catch(e){
            console.log("Error: ", e)
        }
        }
    const numBooksToRead = async(event)=>{
        event.preventDefault();

        try{

            const firestore = getFirestore();
            console.log(event.target.numBooks.value);
            // const books = await getDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"));
            // console.log(name);
            if(event.target.numBooks.value===null|| event.target.numBooks.value===0){
                emptyNotification();
            }
            // else if(books.exists()){
                // console.log(name);
                // duplicateNotification();
            // }
            else{
                setDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"),{
                    numBooksWantReadGoalAmount: event?.target?.numBooks?.value,
    
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }




    }

    const ProgressBar = ({progress})=>{
        const cappedProgress = Math.min(progress, 100);
        const overarchieved = progress > 100;

        console.log(cappedProgress);
        console.log(overarchieved);

        return(
            <div className="relative">
                <div className="w-full bg-gray-200 rounded-full h-6">
                    <div className={`rounded-full h-6 transition-all duration-500 flex items-center justify-end pr-2 text-white text-sm 
                        ${overarchieved? 'bg-green-600':'bg-blue-600'}`}
                        style={{width: `${cappedProgress}`}}>
                            {progress.toFixed(0)}%
                    </div>
                </div>
                {
                    overarchieved && (
                        <div className="absolute top-0 right-0 -mr-4 bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
                            +{(((booksReadCount/bookGoal)*100)-100).toFixed(0)}%
                        </div>
                    )
                }
           </div>
        );

    }

    // function normalize dates
    const normalizeDate = (dateInput)=>{
        try{
            // console.log("DATE");
            // console.log(dateInput);
            // console.log(type(dateInput));
            // console.log("+----");
            if (!dateInput){ 
                // console.log("bla");
                
                return null};

            if( typeof dateInput === "object" && "seconds" in dateInput){
                // console.log("1");
                return Date(dateInput.seconds * 1000);
            }

            // handle string dates
            if (typeof dateInput === 'string'){
                // console.log("2");

                if(dateInput.includes('at')){

                    const [datePart, timePart] = dateInput.split(' at ');
                    const [, day, month, year] = datePart.split(' ');
                    const timeStr = timePart.trim();
                    const fullDateStr = `${day} ${month} ${year}`;
                    const parsedDate = new Date(fullDateStr);

                    if(!isNaN(parsedDate.getTime())){
                        // console.log(parsedDate);
                        return parsedDate;
                    }


                }
                
                const parsedDate = new Date(dateInput);
                return isNaN(parsedDate.getTime()) ? null : parsedDate;
            }

            if (dateInput instanceof Date){
                console.log("3");
                return isNaN(dateInput.getTime()) ? null : dateInput;
            }

            return null;
        }catch(e){
            console.log('Error normalizing date: ', e, ' for input: ', dateInput);

            return null;


        }

    }

    useEffect(()=>{

        getFinishedBooksCount();
        getFinishedReadingBooks();
        getBookGoal();

        

       
        },[])

    return (
        <section className="bg-gray-900">
        <div className="p-6 max-w-6xl mx-auto bg-gray-900 min-h-screen">
            <div className="mb-8 flex justify-between items-center">
                <div>
                    <h1 className="text-3xl font-bold mb-2">Reading Challenges</h1>
                    <p className="text-gray-200">Track your reading progress throughout the year</p>
                </div>
                <div className="flex gap-4">
                <select
                value={selectedYear}
                onChange={(e)=>setSelectedYear(e.target.value)}
                className="bg-gray-700 border border-gray-300 rounded-lg px-4 py-2">
                    {[2024, 2023].map(
                        year=>(
                            <option key={year} value={year}>
                                {year}
                            </option>
                        )
                    )}
                </select>
                <button
                onClick={()=>showBookGoalSettingForm(!bookGoalSettingForm)}
                className="bg-gray-900 p-2 rounded-lg hover:bg-gray-300 transition-colors">
                    <Settings className="w-5 h-5"/>
                </button>
                </div>
            </div>

            {bookGoalSettingForm &&(
                <div className="bg-gray-700 p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
                    <h2 className="text-xl font-bold mb-4">Adjust Reading Goal</h2>
                    <p className="text-sm text-gray-200">How many books do you want to read?</p>
                <form onSubmit={numBooksToRead}>
                    <input
                    className="w-full border rounded px-4 py-2 mb-4 mt-4" 
                    name = "numBooks"
                    value = {booksReadCount}
                    type="number" 
                    placeholder="Enter number books..."/>
                    <div className="flex justify-start gap-2">
                    <button
                            onClick={()=>showBookGoalSettingForm(false)}
                            
                            className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">Cancel</button>
                    <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
                    type="submit">Submit</button>
                    </div>
                </form>
            </div>
            )}
            
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your book club has been created!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue creating your book club"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter name for your book club!"
            />} 
            
            {/* {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                  title = "Error"
                content = "Book club already exists!"
            />}  */}

            {/* Progress Bar */}

            
            <div className="bg-gray-700 p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
                <div className="flex justify-between mb-2">
                    <span className="font-semibold">{booksReadCount? booksReadCount: "0"} of {bookGoal} books</span>
                    <span className="text-gray-300">{
                    Math.round((booksReadCount/bookGoal)*100)
                    }% complete</span>
                </div>
                {/* <div
                className="bg-blue-600 rounded-full h-4 transition-all duration-500"
                style={{width:`${readingChallengeProgress}%`}}>

                </div> */}
                <ProgressBar progress={(booksReadCount/bookGoal)*100}/>

            </div>

            {processedData.genreDistribution.length > 0 && (
                <div className="h-96 mb-20">
                    <h3 className="text-lg font-semibold mb-4">Genre Distribution</h3>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Pie
                            data={processedData.genreDistribution}
                            dataKey="count"
                            nameKey = "genre"
                            cx="50%"
                            cy="50%"
                            fill="#8884d8"
                            outerRadius={100}
                            label ={(entry)=> `${entry.genre}: ${entry.count}`}
                           
                            />
                            {processedData.genreDistribution.map((entry, index)=>(
                                <Cell 
                                key={`cell-${index}`} 
                                fill={COLORS[index % COLORS.length]}
                                strokeWidth={1}/>
                            ))}
                        </PieChart>
                        <Tooltip formatter={(value, name)=>[`${value} books`, name]}/>
                        <Legend layout="vertical" align="right" verticalAlign="middle" iconType="circle"/>


                    </ResponsiveContainer>   

                </div>
            )}


           
        {processedData.addedOvertime.length > 0 && (
            <div className="h-96 mb-20">
                <h3 className="text-lg font-semibold mb-4">Cumulative Books Added</h3>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={processedData.addedOvertime}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis 
                        dataKey="date"
                        tick={{fontSize: 12}}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        label={{
                            value:"Date",
                            position: "bottom",
                            offset: 40

                        }}
                        />
                        <YAxis
                        label={{
                            value: "Total Books",
                            angle: -90,
                            position: "insideLeft",
                            offset: -35
                        }}
                        />
                        <Tooltip/>
                        <Legend verticalAlign="top" height={36}/>
                        <Line
                        type="monotome"
                        stroke="#8884d8"
                        name="Total Books"  
                        dataKey="total"
                        dot={true}
                        strokeWidth={2}
                        isAnimationActive={true}
                        animationDuration={1000}
                        />

                    </LineChart>

                </ResponsiveContainer>
            </div>
            )}


        {processedData.monthlyAdditions.length > 0 && (
            <div className="h-96 mb-20 md-col-span-2">
                <h3 className="text-lg font-semibold mb-4">Monthly Additions</h3>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={processedData.monthlyAdditions}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis 
                        dataKey="month"
                        tick={{fontSize: 12}}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        label={{
                            value:"Month",
                            position: "bottom",
                            offset: 40

                        }}
                        />
                        <YAxis/>
                        <Tooltip/>
                        <Legend verticalAlign="top" height={36}/>
                        <Bar
                        fill="#8884d8"
                        name="Books Added"  
                        dataKey="count"
                        />

                    </BarChart>

                </ResponsiveContainer>
            </div>
            )}

           


            {/* Book List */}
            <h2 className="text-2xl font-semibold">Finished Books</h2> 
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                
                {FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                    
                        <div className="flex bg-gray-700 rounded-lg overflow-hidden"> 
                           
                            <img 
                            src={book.bookImage} 
                            className="w-24 h-36 object-cover"/>
                            <div className="p-4 flex-1">
                                <h3 className="font-semibold">{book.bookTitle}</h3>
                                <p className="text-gray-300 text-sm">Author</p>
                            </div>
                        </div>

                    ))}
            </div>

            <button
            className="mt-2 bg-blue-700 text-white px-4 py-2 rounded-lg hover:bg-blue-300 transition-colors" 
            onClick={onFinishedBookButton}>
                View All Books Read
            </button>

        </div>
        </section>

    );
};
export default ReadingChallenge;