import { getNextKeyDef } from "@testing-library/user-event/dist/keyboard/getNextKeyDef";
import React, {useState,useEffect, useRef} from "react";
import { useNavigate, useLocation} from 'react-router-dom';
import {Nav,NavLink, NavMenu, Hamburger} from "./NavbarElements";
import {Link } from "react-router-dom";
import { auth_, db, fetchNotifications } from "../../firebase";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook, faBars, faBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { connectStorageEmulator } from "firebase/storage";
import { Bell, Book, BookOpen, Briefcase, ChevronDown, DoorOpen, Glasses, Home, HomeIcon, Library, Lightbulb, Mail, Menu, Notebook, NotebookPen, Search, Settings, TrendingUp, User, Users, X } from "lucide-react";
import { set } from "react-ga";


const LoggedInNavbar = () => {

    const user = auth_.currentUser;

    const [openCommunityNav, setCommunityNavOpen] = React.useState(false);
    const [openProfileNav, setProfileNavOpen] = React.useState(false);
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    let [notifications, setNotifications] = useState([]);
    let [messages, setMessages] = useState([]);
    const [searchFocused, setSearchFocused] = useState(false);
    const [communityDropdown, setCommunityDropdown] = useState(false);
    const [everyThingDropdown, setEveryThingDropdown] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activeTab, setActiveTab] = useState('home');
    const [isSearchLoading, setSearchIsLoading] = useState(false);
    const searchResultsRef = useRef(null);
    const searchTimeoutRef = useRef(null);


    const menuRef = useRef(null);
    const communityMenuRef = useRef(null);
    const profileMenuRef = useRef(null);

    const buttonRef = useRef(null);
    const dropdownTimeoutRef = useRef(null);

    const [allBooks, setBooks] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [uniqueCategories, setUniqueCategories] =useState([]);
    const [selectedCategory, setSelectedCategory ] = useState("All");

    const location = useLocation();

    const navItems = [
        {
            id:'home',
            label: 'Home',
            icon: Home,
            hasDropdown: false,
            link: "/"
        },
        {
            id:'mybooks',
            label: 'My Books',
            icon: Book,
            hasDropdown: false,
            link: "/mybooks"
        },
        {
            id:'community',
            label: 'Community',
            icon: Users,
            hasDropdown: true,
            dropdownItems: [
                {label: "Book Clubs", icon: BookOpen, link: "/bookclubs" },
                {label: "Discussion Forums", icon: Users, link: "/discussionboards"},
                {label: "Groups", icon: Users, link: "/groups"},
                {label: "Find Readers", icon: Users, link: "/readers"  },
                {label: "Find Business Partners", icon: Briefcase, link: "/projects"  },

            ]
        },
        {
            id:'everything_else',
            label: 'Everything Else',
            icon: Users,
            hasDropdown: true,
            dropdownItems: [
                {label: "Settings", icon: Settings, link: "/settings" },
                // {label: "HabitTracker", icon: Settings, link: "/habit_tracker" },
                // {label: "Templates", icon: Settings, link: "/templates" },
                {label: "Ebooks Unleased", icon: Glasses, link: "/ebooks_unleashed" },
                {label: "Blogs/Articles", icon: Notebook, link: "/blogs"  },
                {label: "Write A Story", icon: NotebookPen, link: "/blog_stuff"  },
                {label: "Book Categories", icon: Library, link: "/categories" },
                {label: "Book Recommendations", icon: Lightbulb, link: "/bookrecs"  },
                {label: "Search Books", icon: Search, link: "/search_books" },
                {label: "Reading Challenge", icon: TrendingUp, link: "/reading_challenge" },
                // {label: "Book Clubs", icon: BookOpen, link: "/bookclubs" },
                // {label: "Discussion Boards", icon: Users, link: "/discussionboards" },
                // {label: "Sign Out", icon: DoorOpen, link: "/" }

            ]
        }
    ];

    const handleSearch = (searchValue) =>{

    }

    const getBooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const allBooksData = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
            setBooks(allBooksData);

            const allCategories = new Set();

            allBooksData.forEach(book=>{

                if(Array.isArray(book.category)){
                    book.category.forEach(
                        cat=>allCategories.add(cat)
                    );
                }
  
            });

            const sortedCategories = ["All", ...Array.from(allCategories).sort()];

            setUniqueCategories(sortedCategories);

        });
    }

    



    const filteredBooks = allBooks.filter(book=>{

        if(!searchQuery.trim()) return false;
       

        const matchesSearch = book?.title?.toLowerCase().includes(searchQuery?.toLowerCase())||
        book?.author?.toLowerCase().includes(searchQuery?.toLowerCase());

        // check genres
        // const matchesCategory = selectedCategory==="All" || book?.category?.includes(selectedCategory);

        
        const matchesCategory = selectedCategory==="All" || book?.category?.includes(selectedCategory);

        

        return matchesSearch && matchesCategory;
        
    });

    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_details/'+title,{
            state: {
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }

    const SearchBarComponent = ()=>(
         <div className="relative w-full group" ref={searchResultsRef}>
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Search className={`h-5 w-5 transition-colors duration-300 ${searchFocused?'text-blue-500':'text-gray-300'}`}/>

            </div>
           
            <input
            type="text"
            value={searchQuery}
            onChange={(e)=>setSearchQuery(e.target.value)}
            placeholder="Search books..."
            // className="block w-full pl-12 pr-4 py-3 bg-gray-50/50 border-0 rounded-2xl focus:ring-2 focus:ring-blue-500/20 focus:bg-white transition-all duration-300 ease-in-out shadow-sm hover:bg-gray-50/80 hover:shadow-sm hover:text-gray-900"
            className="text-gray-900  hover:text-gray-50 w-full pl-10 pr-4 py-3 bg-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-900"
            onFocus={()=>setSearchFocused(true)}
            // onBlur={()=>setSearchFocused(false)}
            />
     
            {/* <div>
                {isSearchLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"/>
                ):(
                    <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
                )}
            </div> */}
            {/* {filteredBooks.length>0 &&( */}
                <div className="absolute mt-2 w-full bg-white rounded-xl shadow-lg border border-gray-100 overflow-visible overflow-y-scroll">
                    {filteredBooks.map((book)=>(
                        <button
                        onClick={() =>onBookDetailsButton(book.title,book.description,book.cover,book.amzn_link,book.ibook_link,book.bookshop_org_link)}
                        className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center gap-3 transition-colors">
                            <div className="w-10 h-14 bg-gray-100 rounded-md overflow-hidden flow-shrink-0">
                                
                                {book.cover?(
                                    <img 
                                    src={book.cover}
                                    className="w-full h-full object-cover"/>
                                ):
                                (
                                    <div>
                                        <BookOpen className="w-6 h-6 text-gray-400"/>
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900">{book.title}</p>

                            </div>

                        </button>
                    ))}
                </div>
            {/*  )} */}
            {/* <div className="absolute right-3 top-1/2 -translate-y-1/2 space-x-1">
                <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
            </div> */}

        </div>

      

       
    );


   

 
  


    const logout = async()=>{
        try{
            
            await signOut(auth_);
            console.log("signing out now");
            navigate('/');
            window.location.reload();
        }catch(err){

            console.error(err);
        }
    }


    const fetchNotifications = async () =>{
        await getDocs(collection(db, "Users", user.uid, "Notifications")).then((querySnapshot)=>{
          let messages = querySnapshot.docs.map((doc)=>({
              ...doc.data()
          }));
            setNotifications(messages); 

    });  
}

const fetchMessages = async () =>{
    await getDocs(collection(db, "Users", user.uid, "Inbox")).then((querySnapshot)=>{
    let messages = querySnapshot.docs.map((doc)=>({
                  ...doc.data()
              }));

    // console.log(messages);
    setMessages(messages);
        });  
        }

const something = ()=>{
    navigate(
        "/notifications",{
            state: {
                notificationsRead: isNotificationRead?.length
            }
        }
    )
}


const inbox = ()=>{
    navigate(
        "/inbox"
    )
}

const handleOutsideClick = (event) =>{
    if(menuRef.current && 
        !menuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setIsOpen(false);

    }

    if(communityMenuRef.current && 
        !communityMenuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setCommunityNavOpen(false);

    }


    if(profileMenuRef.current && 
        !profileMenuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setProfileNavOpen(false);

    }

    
  
}

const handleMouseEnter = (id) =>{
    // if(window.innerWidth>=768){
    //     setActiveDropdown(id);
    // }

    if(dropdownTimeoutRef.current){
        clearTimeout(dropdownTimeoutRef.current);
    }

    setActiveDropdown(id);
}


const handleMouseLeave = () =>{
    // if(window.innerWidth>=768){
    //     setActiveDropdown(null);
    // }
    dropdownTimeoutRef.current = setTimeout(()=>{
        setActiveDropdown(null);
    }, 300)
}


useEffect(()=>{
    setIsMenuOpen(false);
    setActiveDropdown(null);
    setSearchQuery("");

   

},[location.pathname]);

useEffect(()=>{
    getBooks();
    fetchNotifications();
    fetchMessages();

    const handleOutsideClick =(e)=>{
        if(!e.target.closest('.nav-item-container')){
            setActiveDropdown(null);
        }

    }

    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);


},[]);


    let isNotificationRead = "";
    let isMessageRead = "";

    if(notifications){
        isNotificationRead = notifications?.filter((item)=> item.isRead===false).
        map((notif)=>notif.isRead);
    }

    if(messages){
        isMessageRead = messages?.
        map((message)=>message.name);
    }
    return (
    <div className="h-[81px]">
        <nav class="fixed w-full top-0 left-0 z-50">
            {/* Gradient border line */}
            <div className="h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>

            {/* Main Navigation */}
            <div className="backdrop-blur-lg border-b bg-gray-900/50 bg-gray/70">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between items-center h-full">
                        {/* logo */}
                        <div className="flex items-center">
                            <a href="/">
                            <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-purple-600 hover:to-pink-600 transition-all duration-500 cursor-pointer">
                                Entrepreneurship Books App
                            </span>
                            </a>
                        </div>
                        <div className="hidden md:flex flex-1 max-w-2xl mx-8">
                            {/* <SearchBarComponent/> */}

                            <div className="relative w-full group" ref={searchResultsRef}>
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Search className={`h-5 w-5 transition-colors duration-300 ${searchFocused?'text-blue-500':'text-gray-300'}`}/>

            </div>
           
            <input
            type="text"
            value={searchQuery}
            onChange={(e)=>setSearchQuery(e.target.value)}
            placeholder="Search books..."
            // className="block w-full pl-12 pr-4 py-3 bg-gray-50/50 border-0 rounded-2xl focus:ring-2 focus:ring-blue-500/20 focus:bg-white transition-all duration-300 ease-in-out shadow-sm hover:bg-gray-50/80 hover:shadow-sm hover:text-gray-900"
            className="text-gray-900  hover:text-gray-50 w-full pl-10 pr-4 py-3 bg-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-900"
            onFocus={()=>setSearchFocused(true)}
            onBlur={()=>setSearchFocused(false)}
            />
     
            {/* <div>
                {isSearchLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"/>
                ):(
                    <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
                )}
            </div> */}
            {/* {filteredBooks.length>0 &&( */}
                <div className="absolute mt-2 w-full bg-white rounded-xl shadow-lg border border-gray-100 overflow-visible overflow-y-scroll">
                    {filteredBooks.map((book)=>(
                        <button
                        onClick={() =>onBookDetailsButton(book.title,book.description,book.cover,book.amzn_link,book.ibook_link,book.bookshop_org_link)}
                        className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center gap-3 transition-colors">
                            <div className="w-10 h-14 bg-gray-100 rounded-md overflow-hidden flow-shrink-0">
                                
                                {book.cover?(
                                    <img 
                                    src={book.cover}
                                    className="w-full h-full object-cover"/>
                                ):
                                (
                                    <div>
                                        <BookOpen className="w-6 h-6 text-gray-400"/>
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900">{book.title}</p>

                            </div>

                        </button>
                    ))}
                </div>
            {/*  )} */}
            {/* <div className="absolute right-3 top-1/2 -translate-y-1/2 space-x-1">
                <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
            </div> */}

        </div>
                        </div>

                        

                        {/* Desktop Navigation */}
                        <div className="hidden md:flex items-center space-x-4">
                            {/* <div className="relative inline-block"> */}
                                {navItems.map(({id, label, icon: Icon, hasDropdown, dropdownItems, link})=>(
                                    <div
                                    key={id}
                                    className="nav-item-container relative inline-block"
                                    onMouseEnter={()=>handleMouseEnter(id)}
                                    onMouseLeave={handleMouseLeave}
                                    
                                    >
                                        <button
                                        
                                        // onMouseLeave={handleMouseLeave}
                                        onClick={()=>{
                                            setActiveTab(id)
                                            navigate(link)}}
                                        className={`flex items-center px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-300 ease-in-out group hover:scale-105 active:scale-95 ${
                                            activeTab === id ? 'text-blue-600 bg-blue-300/80 shadow-sm':
                                            'text-gray-600 hover:bg-blue-300/80'
                                        }`}>
                                            <span className="text-gray-200  ml-2">{label}</span>
                                            {hasDropdown &&(
                                                <ChevronDown
                                                className={`ml-1 h-4 w-4 transition-transform duration-300 ${
                                                    activeDropdown === id ? 'rotate-180':''
                                                }`}/>

                                            )}
                                        </button>
                                        {
                                            hasDropdown && activeDropdown === id &&(
                                                <div className="absolute top-full left-0 mt-2 w-64 rounded-2xl bg-white shadow-lg border border-gray-100 transition-all duration-300 ease-in-out transform opacity-100 scale-100 origin-top-left divide-y divide-gray-50">
                                                     {dropdownItems.map((item, idx)=>(
                                                        <button
                                                        onClick={()=>{
                                                            setActiveTab(id)
                                                            navigate(item.link)}} 
                                                        key ={idx}
                                                        className="flex items-center w-full px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 first:rounded-t-2xl last:rounded-b-2xl transition-all duration-200 group">
                                                        <item.icon className="h-5 w-5 text-gray-400"/>
                                                        <span className="ml-3 font-medium">{item.label}</span>
                                                    </button>

                                                    ))}
                                                    
                                                </div>
                                            )
                                        }

                                    </div>
                                ))}
                            
                        </div>

                        {/* Right side actions */}
                        <div className="flex items-center space-x-2">
                            <button
                            onClick={()=>navigate("/inbox")} 
                            className="p-2.5 rounded-xl text-gray-300 hover:bg-gray-50/50 transition-all duration-300 relative group">
                                <Mail className="h-5 w-5 group-hover:text-blue-500 transition-colors duration-300"/>
                            </button>
                            <button
                            onClick={()=>navigate("/notifications")} 
                            className="p-2.5 rounded-xl text-gray-300 hover:bg-gray-50/50 transition-all duration-300 relative group">
                                <Bell className="h-5 w-5 group-hover:text-blue-500 transition-colors duration-300"/>
                                <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-rose-500 rounded-full group-hover:animate-ping"></span>

                            </button>
                            <button className="p-2.5 rounded-xl bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-lg shadow-blue-500/20 hover:shadow-blue-500/40 hover:from-purple-500 hover:to-pink-500 transition-all duration-300 hover:scale-105 active:scale-95">
                                {/* <User className="h-5 w-5"/> */}

                                {user?.photoURL?(<img className="w-[40px] h-[40px] object-cover" src={user?.photoURL}/>):<User className="h-5 w-5"/>}

                            </button>
                            {/* mobile menu button */}
                            <button
                            onClick={()=>setIsMenuOpen(!isMenuOpen)}
                            className="md:hidden p-2.5 rounded-xl text-gray-600 hover:bg-gray-50/80 transition-all duration-300">
                                {isMenuOpen ?
                                <X className="h-5 w-5"/>:
                                <Menu className="h-5 w-5"/>}

                            </button>

                        </div>

                    </div>

                    {/* Mobile Menu */}
                    <div className="md:hidden flex-1 mx-4 mb-2">
                            {/* <SearchBarComponent/> */}
{/* Mobile Search */}
                            <div className=" relative w-full group" ref={searchResultsRef}>
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Search className={`h-5 w-5 transition-colors duration-300 ${searchFocused?'text-blue-500':'text-gray-300'}`}/>

            </div>
           
            <input
            type="text"
            value={searchQuery}
            onChange={(e)=>setSearchQuery(e.target.value)}
            placeholder="Search books..."
            // className="block w-full pl-12 pr-4 py-3 bg-gray-50/50 border-0 rounded-2xl focus:ring-2 focus:ring-blue-500/20 focus:bg-white transition-all duration-300 ease-in-out shadow-sm hover:bg-gray-50/80 hover:shadow-sm hover:text-gray-900"
            className="text-gray-900  hover:text-gray-50 w-full pl-10 pr-4 py-3 bg-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-900"
            onFocus={()=>setSearchFocused(true)}
            onBlur={()=>setSearchFocused(false)}
            />
     
            {/* <div>
                {isSearchLoading ? (
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"/>
                ):(
                    <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
                )}
            </div> */}
            {/* {filteredBooks.length>0 &&( */}
                <div className="absolute mt-2 w-full bg-white rounded-xl shadow-lg border border-gray-100 overflow-visible overflow-y-scroll">
                    {filteredBooks.map((book)=>(
                        <button
                        onClick={() =>onBookDetailsButton(book.title,book.description,book.cover,book.amzn_link,book.ibook_link,book.bookshop_org_link)}
                        className="w-full px-4 py-3 text-left hover:bg-gray-50 flex items-center gap-3 transition-colors">
                            <div className="w-10 h-14 bg-gray-100 rounded-md overflow-hidden flow-shrink-0">
                                
                                {book.cover?(
                                    <img 
                                    src={book.cover}
                                    className="w-full h-full object-cover"/>
                                ):
                                (
                                    <div>
                                        <BookOpen className="w-6 h-6 text-gray-400"/>
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900">{book.title}</p>

                            </div>

                        </button>
                    ))}
                </div>
            {/*  )} */}
            {/* <div className="absolute right-3 top-1/2 -translate-y-1/2 space-x-1">
                <span className="text-xs text-gray-900 bg-gray-100 px-2 py-1 rounded-md">K</span>
            </div> */}

        </div>
                        </div>




                    {/* Mobile Menu */}
                    <div className={`md:hidden transition-all duration-500 ease-in-out ${
                        isMenuOpen? 'max-h-screen opacity-100':
                        'max-h-0 opacity-0'

                    } overflow-hidden`}>

                        <div className=" px-2 py-4 space-y-1">
                            {/* <div className="relative"> */}
                                {navItems.map(({id, label, icon: Icon,hasDropdown, dropdownItems, link})=>(
                                    <div key={id} className="nav-item-container space-y-2">
                                        <button
                                         key={id}
                                         className={`w-full flex items-center justify-between px-4 py-3 rounded-lg text-sm font-medium
                                            transition-all duration-300
                                            ${activeTab===id?
                                                'text-blue-600 bg-gray-300/80':
                                                'text-gray-600 hover:bg-gray-300/80'
                                            }`}
                                         onClick={()=>{
                                            navigate(link)
                                            setActiveTab(id)
                                            // if(dropdownItems){
                                            setActiveDropdown(activeDropdown === id? null: id)
                                            // }else{
                                                // setIsMenuOpen(false)
                                            
                                        //  }
                                         }}>
                                            <div className="px-2 flex items-center">
                                                <Icon className={`h-5 w-5 ${activeTab=== id? 'text-blue-500':'text-gray-400'}`}/>
                                                <span className="text-gray-200 ml-3">{label}</span>
                                            </div>
                                            {hasDropdown &&(
                                                <ChevronDown className={`h-4 w-4 transition-transform duration-300 ${
                                                    activeTab === id ? 'rotate-180': ''
                                                }`}/>
                                            )}

                                        </button>

                                        {hasDropdown && activeDropdown === id &&(
                                                <div className="pl-4 space-y-1">
                                                    {
                                                        dropdownItems.map((item, idx)=>(
                                                            <button
                                                            key={idx}
                                                            onClick={()=>navigate(item.link)} 
                                                            className="w-full flex items-center px-4 py-3 rounded-xl text-sm text-gray-600
                                                            hover:bg-gray-300/80 transition-all duration-200">
                                                                <item.icon className="h-5 w-5 text-gray-200"/>
                                                                <span className="text-gray-200 ml-3">{item.label}</span>
                                                            </button>
                                                        ))
                                                    }

                                                </div>
                                            )
                                        }
                                    </div>

                                ))}

                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </nav>
    </div>

    );
};


export default LoggedInNavbar;