import React, {useState, useEffect, act} from 'react';
import {ArrowLeft, Save, HelpCircle, Plus, Check, Edit, Trash, Info} from 'lucide-react';
import {doc, getFirestore, setDoc, getDoc} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {jsPDF} from 'jspdf';


const CustomerInterviewTemplate = () =>{
    const [activeSection, setActiveSection] = useState('preparation');
    const [questions, setQuestions] = useState([
        {id: 1, text: 'What challenges do you face with [problem area]?', notes: ''},
        {id: 2, text: 'How are you currently solving this problem?', notes: ''},
        {id: 3, text: 'What do you like/dislike about your current solution?', notes: ''},
        {id: 4, text: 'How much time/money fo you spend on this problem?', notes: ''},
        {id: 5, text: 'What would and ideal solution look like?', notes: ''},

    ]);

    const [editing, setEditing] = useState(null);
    const [newQuestion, setNewQuestion] = useState("");

    const handleAddQuestion = () =>{
        if(newQuestion.trim() !=""){
            setQuestions([
                ...questions,
                {id: questions.length +1, text: newQuestion, notes: ''}
            ]);
            setNewQuestion("");
        }
    };

    const handleDeleteQuestion = (id) => {
        setQuestions(questions.filter(q => q.id !== id));
    }

    const handleUpdateNotes = (id, notes) =>{
        setQuestions(questions.map(q=>q.id === id? {...q, notes}: q));
    }

    const handleEditQuestion = (id) =>{
        setEditing(id);
        setNewQuestion(questions.find(q=>q.id === id)?.text || "");
    }

    const handleSaveEdit =  () =>{
        if(newQuestion.trim()!==""){
            setQuestions(questions.map(q=>q.id === editing ? {...q, text: newQuestion}:q));
            setEditing(null);
            setNewQuestion("");
        }
    }

    const sections = [
        {
            id: 'preparation',
            title: 'Interview Preparation',
            content: (
                <div className='space-y-4'>
                        <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-4'>
                            <div className='flex items-start gap-2'>
                                <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                            <div>
                            <h3 className='font-medium text-blue-800 mb-1'>Before You Begin</h3>
                            <p className='text-sm text-blue-700'>
                                Customer interviews help validate your business idea and understand user needs. Aim to learn, not sell.
                                Listen more than you talk and be open to unexpected insights.
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    <label className='block text-sm font-medium mb-1'>Interviewee Name</label>
                    <input 
                    type='text'
                    className='w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white'
                    placeholder='John Smith'/>
                </div>

                <div>
                    <label className='block text-sm font-medium mb-1'>Company/Role</label>
                    <input 
                    type='text'
                    className='w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white'
                    placeholder='Marketing Manager at ABC Company'/>
                </div>

                <div>
                    <label className='block text-sm font-medium mb-1'>Interview Goal</label>
                    <textarea 
                    type='text'
                    className='w-full p-2 border rounded-lg h-24 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white'
                    placeholder='What do you want to learn from this interview? E.g., Validate pain points around social media management for small businesses'></textarea>
                </div>


                <div>
                    <label className='block text-sm font-medium mb-1'>Industry/Segment</label>
                    <input 
                    type='text'
                    className='w-full bg-white p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                    placeholder='E.g., Small Business, Healthcare, Education'/>
                </div>


                <div>
                    <label className='block text-sm font-medium mb-1'>Date & Time</label>
                    <input 
                    type='datetime-local'
                    className='w-full bg-white p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500'/>
                </div>
            </div>
            )
        },
        {
            id: 'questions',
            title: 'Interview Questions',
            content: (
                <div className='space-y-4'>
                    <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-4 bg-white'>
                    <div className='flex items-start gap-2'>
                        <Info className='w-5 h-5 text-blue-500 mt-0.5'/>

                    <div>
                    <h3 className='font-medium text-blue-800 mb-1'>Effective Questions</h3>

                    <p className='text-sm text-blue-700'>
                        Ask open-ended questions that start with "how" or "why" rather than yes/no questions.
                        Focus on past behaviors rather than hypothetical situations.

                    </p>

                        </div>
                    </div>
                    </div>
                

                <div className='space-y-3'>
                    {questions.map((question)=>(
                        <div key={question.id} className='border rounded-lg p-3'>
                            <div className='flex justify-between items-start mb-2'>
                                <div className='font-medium'>
                                    {editing === question.id ? (
                                        <input
                                            type="text"
                                            value ={newQuestion}
                                            onChange={(e)=>setNewQuestion(e.target.value)}
                                            className='w-full text-black p-2 bg-white border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                                        />

                                    ):(
                                        <span>{question.text}</span>
                                    )}

                                </div>

                                <div className='flex items-center gap-2'>
                                    {editing == question.id?(
                                        <button onClick={handleSaveEdit} className='p-1 text-gray-600 hover:bg-gray-100 rounded'>
                                            <Check className='w-4 h-4'/>
                                        </button>

                                    ):(
                                        <button onClick={()=>handleEditQuestion(question.id)} className='p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded'>
                                            <Edit className='w-4 h-4'/>
                                        </button>
                                    )}
                                    <button onClick={()=> handleDeleteQuestion(question.id)}
                                        className='p-1 text-gray-400 hover:text-red-600 hover:bg-gray-100 rounded'>
                                        <Trash className='w-4 h-4'/>
                                    </button>
                                </div>
                            </div>
                            {editing !== question.id && (
                                <div>
                                    <label className='block text-xs text-gray-500 mb-1'>Notes</label>
                                    <textarea className='w-full text-black bg-white p-2 border rounded-lg h-16 text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                                    placeholder='Capture answers here during the interview...'
                                    value={question.notes}
                                    onChange={(e)=> handleUpdateNotes(question.id, e.target.value)}></textarea>
                                </div>
                            )}
                        </div>
                    ))}

                </div>

                <div className='pt-3'>
                    <label className='block text-sm font-medium mb-1'>Add New Question</label>
                    <div className='flex gap-2'>
                        <input
                            type="text"
                            className='text-black flex-grow p-2 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                            placeholder='Type a new question...'
                            value={newQuestion}
                            onChange={(e)=>setNewQuestion(e.target.value)}
                            onKeyPress={(e)=>e.key === 'Enter' && handleAddQuestion()}
                        />
                        <button onClick={handleAddQuestion}
                        className='flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700'>
                            <Plus className='w-4 h-4'/>
                        </button>

                    </div>

                </div>

                </div>
            )
        },
        {
            id: 'followup',
            title: 'Follow Up Notes',
            content: (
                <div className='space-y-4'>
                    <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-4'>
                        <div className='flex items-start gap-2'>
                            <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                            <div>
                                <h3 className='font-medium text-blue-800 mb-1'>After The Interview</h3>
                                <p className='text-sm text-blue-700'>
                                    Record your thoughts immediately after the interview. What surprised you? Did anything confirm
                                    your hypothesis? What new insights did you gain?
                                </p>
                            </div>

                        </div>

                    </div>

                    <div>
                    
                        <label className='block text-sm font-medium mb-1'>Key Takeaways</label>
                        <textarea
                         className='w-full text-black bg-white p-2 border rounded-lg h-36 focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                         placeholder='What are the most important things you learned?'
                        ></textarea>

                    </div>

                    <div>
                    <label className='block text-sm font-medium mb-1'>Pain Points Identified</label>
                        <textarea
                         className='w-full text-black bg-white p-2 border rounded-lg h-36 focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                         placeholder='What problems or challenges did the interviewee mention?'
                        ></textarea>
                        
                    </div>

                    <div>
                    <label className='block text-sm font-medium mb-1'>Next Steps</label>
                        <textarea
                         className='w-full text-black bg-white p-2 border rounded-lg h-36 focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                         placeholder='What actions will you take based on this interview?'
                        ></textarea>
                        
                    </div>

                </div>

            )
        }

    ];

    return(
        <section className='bg-gray-900'>
            <div className='max-w-6xl mx-auto p-3 md:p-6'>
                {/* Header */}
                <div className='flex items-center justify-between mb-6'>
                    <div className='flex items-center'>
                        <h1 className='text-xl md:text-2xl font-bold'>Customer Interview Template</h1>
                    </div>
                    <button className='flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 text-sm md:px-4'>
                        <Save className='w-4 h-4'/>
                        <span className='hidden md:inline'>Save Interview</span>
                    </button>

                </div>
                {/* Main Content */}
                <div className='flex flex-col md:flex-row gap-6'>
                    {/* left navigation - goes to top on mobile */}
                    <div className='w-full md:w-64 order-2 md:order-1'>
                        <div className='bg-white rounded-lg border p-2 md:p-3 md:sticky md:top-6'>
                            {sections.map((section)=>(
                                <button
                                key={section.id}
                                onClick={()=>setActiveSection(section.id)}
                                className={`w-full text-left p-3 rounded-lg flex items-center justify-between mb-1 ${
                                    activeSection === section.id ?
                                    'bg-blue-50 text-blue-700 font-medium':
                                    'hover:bg-gray-50'
                                }`}
                                >
                                    <span>{section.title}</span>
                                </button>
                            ))}
                            <div className='p-3 mt-4 bg-yellow-50 rounded-lg'>
                                <div className='flex items-start gap-2'>
                                    <HelpCircle className='w-5 h-5 text-yellow-600 mt-0.5'/>
                                    <div className='text-sm text-yellow-800'>
                                        <span className='font-medium block mb-1'>Tips</span>
                                        <ul className='space-y-1 text-xs'>
                                            <li>* Listen more than you talk</li>
                                            <li>* Don't ask leading questions</li>
                                            <li>* Ask "why" to dig deeper</li>
                                            <li>* Record with permission</li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

             
                {/* Right Content */}
                <div className='flex-grow order-1 md: order-2'>
                    <div className='bg-white rounded-lg border p-4 md:p-6'>
                        <h2 className='text-lg md:text-xl font-semibold mb-4'>
                            {sections.find(s=>s.id === activeSection)?.title}
                        </h2>
                        {sections.find(s=>s.id === activeSection)?.content}
                    </div>

                </div>
                </div>
                {/* Progress */}
                <div className='mt-6 bg-gray-100 h-2 rounded-full'>
                    <div className='bg-blue-600 h-full rounded-full' 
                    style={{width: activeSection === 'preparation' ? '33%':activeSection ==='questions'? '66%':'100%'}}></div>
                </div>

            </div>
        </section>
    );

}

export default CustomerInterviewTemplate;