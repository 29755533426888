import React, { useState, useEffect,setState } from "react";
import { useNavigate, useParams, useLocation, Link} from 'react-router-dom';
import { doc, query, where, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc, deleteDoc, onSnapshot, sum} from 'firebase/firestore';
import {db, auth_} from './../firebase';
import axios from 'axios';
import Popup from "../components/Popup/Popup";
import MoveBookPopup from "../components/Popup/moveBookPopup";
import AdsComponent from './../components/AdComponents';
import {Share2, BookOpen, Library, Heart, Star, BookPlus, Check, BookLock, ShoppingBagIcon, BookMarked} from 'lucide-react';
import { set } from "react-ga";
import PDFAccessControl from '../books/PDFAccessControl';

const BookDetails = () =>{
    const {bookTitle} = useParams();
    const navigate = useNavigate();
    const user = auth_.currentUser;
    const [ratingStats, setRatingStats] = useState({
        average: 0,
        count: 0
    })
    const location = useLocation();
    const [bookie, setBook] = useState("");
    const [result, setResult] = useState([]);
    const [apiKey, setApiKeyhowReader] = useState("AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw");
    const [isOpen, setIsOpen] = useState(false);
    const [isDuplicate, setDuplicate] = useState(false);
    const [isBookAlreadyIn_FinishedBookShelf, setBookAlreadyIn_FinishedBookShelf] = useState(false);
    const [isBookAlreadyIn_CurrentlyReadingBookShelf, setBookAlreadyIn_CurrentlyReadingBookShelf] = useState(false);
    const [isBookAlreadyIn_WantToReadBookShelf, setBookAlreadyIn_WantToReadBookShelff] = useState(false);

    // Radio Buttons
    const [selectedFinishedReadingValue, setSelectedFinishedReadingValue] = useState("option1");
    const [selectedCurrentlyReadingValue, setSelectedCurrentlyReadingValue] = useState("option2");
    const [selectedWantToReadValue, setSelectedWantToReadValue] = useState("option3");

    const [isBookAddedToBookShelfOpen, setBookAddedToBookShelf] = useState(false);

    const [isAddToLibraryDropdownOpen, setAddToLibraryDropdownOpen] = useState(false);



    const successAddingBookToShelfNotification = () =>{
        // console.log("success message");
        setBookAddedToBookShelf(!isBookAddedToBookShelfOpen);   
    }

    const handleRadioChangeForFinishedReadingShelf = (value) =>{
   
            setSelectedFinishedReadingValue(value);
  
       
    }

    const handleRadioChangeForCurrentlyReadingShelf = (value) =>{
        setSelectedCurrentlyReadingValue(value);
    }

    const handleRadioChangeForWantToReadReadingShelf = (value) =>{
        setSelectedWantToReadValue(value);
    }


    function handleChange(event){
        const book = event.target.value;
        setBook(book);
    }

    function handleSubmit(book){
        // event.preventDefault();
        axios.get("https://www.googleapis.com/books/v1/volumes?q="
        +book+"&key="
        +apiKey+"&maxResults=1").then(
            data =>{
                // console.log(data.data.items);
                setResult(data.data.items);
            }
        )
       
    }


    const buyBookPopup = () =>{
        // console.log("empty message");
        setIsOpen(!isOpen);   
    }

    const bookInShelfPopup = () =>{
        setDuplicate(!isDuplicate);
    }


    const confirmMoveFinishedReadingBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedFinishedReadingValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedFinishedReadingValue === "option1"){
            options = "Finished Book";
            bookInShelfPopup();

                
        }else if(selectedFinishedReadingValue === "option2"){
            options = "Currently Reading";
            // console.log(options);

            deleteDoc(doc(firestore,"Users",user.uid, "Finished Book", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Finished Book to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();

                successAddingBookToShelfNotification();
        }else{
            options = "Want to Read";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Finished Book", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Finished Book to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }


    const confirmMoveCurrentlyReadingBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedFinishedReadingValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedFinishedReadingValue === "option1"){
            options = "Finished Book";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Currently Reading", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Currently Reading to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


                
        }else if(selectedFinishedReadingValue === "option2"){
            options = "Currently Reading";
            // console.log(options);
            bookInShelfPopup();


        }else{
            options = "Want to Read";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Currently Reading", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Currently Reading to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }

    const confirmMoveWantToReadBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedWantToReadValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedWantToReadValue === "option1"){
            options = "Finished Book";
            deleteDoc(doc(firestore,"Users",user.uid, "Want to Read", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Want to Read to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();

                
        }else if(selectedWantToReadValue === "option2"){
            options = "Currently Reading";
            // console.log(options);

            deleteDoc(doc(firestore,"Users",user.uid, "Want to Read", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Want to Read to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();

        }else{
            options = "Want to Read";
            bookInShelfPopup();

        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }

    const MoveFinishedBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_FinishedBookShelf(!isBookAlreadyIn_FinishedBookShelf);   
    }

    const MoveCurrentReadingBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_CurrentlyReadingBookShelf(!isBookAlreadyIn_CurrentlyReadingBookShelf);   
    }

    const MoveWantToReadBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_WantToReadBookShelff(!isBookAlreadyIn_WantToReadBookShelf);   
    }


    const onToFinishedBooksShelf = async (title, category, image, uid, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
            // if(read.exists() || current.exists() || want.exists()){
            //     // return (<div><p>Already exists!</p></div>);
            //     console.log("book exists in shelves. Would you like to move it?");
            //     // if(read.exists()){
            //         MoveFinishedBook();
                
            // // }

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title,
                    book_uid: uid

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, category, image, uid, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     return (<div></div>);

            //     {/* {console.log("book exists in shelves. Would you like to move it?")}; */}
                

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Currently Reading", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title,
                    book_uid: uid

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, category, image,uid, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     console.log("book exists in shelves. Would you like to move it?");

            // }else{
                if(read.exists()){
                    MoveFinishedBook();
    
                }else if(current.exists()){
                    MoveCurrentReadingBook();
    
                }else if(want.exists()){
                    MoveWantToReadBook();
    
                }else{
                setDoc(doc(firestore,"Users",user.uid, "Want to Read", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title,
                    book_uid: uid

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, category, image, uid, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user.uid, "Wishlist",title));
       

            if(wishlist.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Wishlist", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title,
                    book_uid: uid

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: " + event );

        }


    }


    const onToFavoritesBooksShelf = async (title, category, image, uid, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user.uid, "Favorites",title));
    

            if(favorities.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Favorites", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title,
                    book_uid: uid

                });
                // console.log("done!");
                successAddingBookToShelfNotification();
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onBuyBook =()=>{
        buyBookPopup();
    }

    const BookAlreadyInShelfPopup =()=>{
        bookInShelfPopup();
    }


    const onRateBook = (book_name)=>{
        navigate('/rate_book/'+book_name);

    }


    const fetchBook = async ()=>{
        try{

            if(location?.state?.book_uid){
                const bookRef = doc(db, 'Books', location?.state?.book_uid);
                const bookSnap = await getDoc(bookRef);

                if(bookSnap.exists()){
                    setBook({id: bookSnap.id, ...bookSnap.data()})
                }


            }else{
                // search title
                const q = query(
                    collection(db, 'Books'),
                    where('title', '==',location?.state?.book_title)
                );

                const querySnapshot = await getDocs(q);

                if(!querySnapshot.empty){
                    const bookData = querySnapshot.docs[0];
                    setBook({id: bookData.id, ...bookData.data()});
                }else{
                    
                }

            }


            // get ratings for this book

            const ratingsRef = collection(db, 'BookRatings', location?.state?.book_title,"Ratings");
            // const ratingSnap = await getDocs(ratingsRef);
            console.log(location?.state?.book_title);

     
            // real time listener for ratings
             const unsubscribe = onSnapshot(ratingsRef, (snapshot)=>{
                let totalRating = 0;
                const count = snapshot.size;
                console.log(count);

                snapshot.forEach((doc)=>{
                    const ratingData = doc.data();
                    // convert ratings to string then add them together
                    totalRating += parseFloat(ratingData.rating);
        
                });
                

                setRatingStats({
                    average: count>0? totalRating/count:0,
                    count: count
                });
             


             });

             return () => unsubscribe();





            // const ratingsQuery = query(
            //     collection(db, 'BookRatings'),
            //     where()
            // )
            

        }catch(e){
            console.log(e)

        }
    }



    useEffect(()=>{
        fetchBook();
        handleSubmit(location?.state?.book_title);
    },[location?.state?.book_title])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div class="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 random-lg shadow-sm">
        <AdsComponent dataAdSlot='7635585815'/>


            {isBookAddedToBookShelfOpen && 
                    <div>
                    <Popup
                    handleClose = {successAddingBookToShelfNotification}
                    title = "Congratulations!"
                    content = "Your book club has been created!"
                />
                </div>}

            {/* Buying Book Popup */}
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {buyBookPopup}
                    title="Buy Books From One Of Our Affliates Below:"
                    content = {
                    <div>
                        <p><a className ="buybook-links" href={location.state.amazon}>Amazon</a></p>
                        <p><a className ="buybook-links" href={location.state.apple}>Apple</a></p>
                        <p><a className ="buybook-links" href={location.state.bookshop}>Bookshop.org</a></p>
                    </div>
                    }
                />
                </div>}

            {/* Duplicate Bookshelf Popup */}
            {isDuplicate && 
                    <div>
                    <Popup
                    handleClose = {BookAlreadyInShelfPopup}
                    title = "Book Already In Shelf!"
                    content = "This book is already in this shelf"
                />
                </div>}

                {/* Already in Finished Books Book Shelf */}
                {isBookAlreadyIn_FinishedBookShelf&&<div>
                    <MoveBookPopup
                    handleClose = {MoveFinishedBook}
                    title = "Book Already In Finished Reading Book Shelf"
                    content = {
                    <div>
                        
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                            <form onSubmit={confirmMoveFinishedReadingBook}>
                            <div className="radioGroup">
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                        value="option1"
                                        checked = {selectedFinishedReadingValue==="option1"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedFinishedReadingValue==="option2"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedFinishedReadingValue==="option3"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}

                {/* Already in Currently Reading Book Shelf */}
                {isBookAlreadyIn_CurrentlyReadingBookShelf&&<div>
                    <MoveBookPopup
                    handleClose = {MoveCurrentReadingBook}
                    title = "Book Already In Currently Reading Book Shelf"
                    content = {
                    <div>
                        
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                            <form onSubmit={confirmMoveCurrentlyReadingBook}>
                            <div className="radioGroup">
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                        value="option1"
                                        checked = {selectedCurrentlyReadingValue==="option1"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedCurrentlyReadingValue==="option2"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedCurrentlyReadingValue==="option3"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}

                {/* Already in Want to Read Book Shelf */}
                {isBookAlreadyIn_WantToReadBookShelf&&<div>
                    <MoveBookPopup
                    // handleMoveBookConfirmation = {confirmMoveBook}
                    handleClose = {MoveWantToReadBook}
                    title = "Book Already In Want To Read Book Shelf"
                    content = {
                    <div>
                        
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                        <form onSubmit={confirmMoveWantToReadBook}>
                            <div className="radioGroup">
            
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                      
                                        value="option1"
                                        checked = {selectedWantToReadValue==="option1"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                       
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedWantToReadValue==="option2"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedWantToReadValue==="option3"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}








{/* ------------------------------------------------------- */}
            <div class="flex sm: flex-col md:flex-row gap-6 md:gap-8">

            {result.map(
                book=>(
                    <div>
                        <div class="flex justify-center md:justify-start md:w-64 flex-shrink-0">
                            <img class="shadow-lg rounded-lg w-48 md:w-full h-auto" src={bookie.cover}/>
                            {/* <img class="shadow-lg rounded-lg w-48 md:w-full h-auto" src={location?.state?.book_title?location.state.book_image:''}/> */}

                        </div>
                        <div class="flex-1">
                        <div class="mb-6 md:mb-8 text-center md:text-left">
                        <h1 class="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-2">{bookie.title}</h1>
                        <p class="text-lg sm:text-xl text-gray-600 mb-3">By {bookie.author}</p>


                        {/* categories */}
                        <div className="w-full">
                        <div className="flex flex-wrap gap-2 px-2">
                            {bookie.category && bookie.category.map((category)=>(
                                <span
                                key={category}
                                className="px-3 py-1.5 bg-blue-500 text-white rounded-full text-xs sm:text-sm transition-colors"
                                >
                                    {category}
                                </span>
                            ))}
                        </div>
                        </div>

                        {/* Rating */}
                        <div className="space-y-1 py-4">
                            <p className="flex text-gray-600">Current Rating:</p>
                            <div className="flex items-center gap-2">
                                <div className="flex">
                                {[1,2,3,4,5].map((star)=>(
                                    <Star
                                        key={star}
                                        className={
                                            `
                                            w-5 h-5 ${
                                                star <= ratingStats.average
                                                ? 'fill-yellow-400 text-yellow-400':
                                                'fill-gray-200 text-gray-200'
                                            }
                                            `
                                        }
                                    />
                                ))}
                                </div>

                                <span className="text-gray-600">({ratingStats.average.toFixed(1)})</span>
                                <span className="text-sm text-gray-500">


                                </span>
                            </div>
                    
                        </div>


                        <div class="relative">
                        <button class="w-full mt-4 mb-4 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2"
                        onClick={()=>setAddToLibraryDropdownOpen(!isAddToLibraryDropdownOpen)}>
                            <Library class="h-4 w-4"/>
                            Add to Shelf
                        </button>

                        {isAddToLibraryDropdownOpen && (
                            <div class="absolute top-full left-0 w-full mt-1 bg-white border border-gray-200 text-gray-900 rounded-lg shadow-lg py-1 z-10">
                                <button 
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 
                                        onClick={(e)=>onToFinishedBooksShelf(bookie.title, bookie.category, bookie.cover, bookie.uid,e)}>
                                            <BookLock class="h-4 w-4 text-blue-500"/>
                                            Finished Reading
                                        </button>
                                        <button
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 
                                        onClick={(e)=>onToCurrentlyReadingBooksShelf(bookie.title, bookie.category, bookie.cover, bookie.uid,e)}>
                                            <BookOpen class="h-4 w-4 text-blue-500"/>
                                            Currently Read
                                        </button>
                                        <button
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 

                                        onClick={(e)=>onToWantToReadBooksShelf(bookie.title, bookie.category, bookie.cover, bookie.uid,e)}>
                                            <BookMarked class="h-4 w-4"/>
                                            Want to Read
                                        </button>
                                        <button 
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                            onClick={(e)=>onToWishlistBooksShelf(bookie.title, bookie.category, bookie.cover, bookie.uid,e)}>
                                                <Star class="h-4 w-4 text-yellow-500"/>
                                                Add to Wishlist
                                            </button>
                                        <button
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                            onClick={(e)=>onToFavoritesBooksShelf(bookie.title, bookie.category, bookie.cover, bookie.uid, e)}>
                                            <Heart class="h-4 w-4 text-red-500"/>
                                            Add to Favorites
                                        </button>
                                        {/* <div class="border-t border-gray-200 my-1"></div>
                                        <button
                                        class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                            // onClick={}
                                            >
                                            <BookPlus class="h-4 w-4"/>
                                            Create New Shelf
                                        </button> */}
                                    </div>
                                )}  
                            </div>
                        <div class="flex flex-col sm:flex-row items-center gap-4">

                        <button 
                        class="w-full px-4 py-2 border border-gray-300 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center gap-2"
                        onClick={()=>onRateBook(bookie.title,)}>
                            <Star class="h-4 w-4"/>
                            Rate Book
                        </button>                     
                        {/* <button 
                        class="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2" 
                        onClick={()=>onBuyBook()} >
                            <ShoppingBagIcon class="h-4 w-4"/>
                            Buy Book
                        </button> */}

                      

                        {/* <div class="flex gap-6 sm:gap-4"> */}
                        <button
                        class="w-full px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2">
                            <Share2 class="h-4 w-4"/>
                            Share
                        </button>

    
                        {/* </div> */}


                        </div>
                   
                        </div>
                        </div>

                        <div className="w-full space-y-2">
                            
                            {bookie.ebooks_unleashed && (
                                // <button
                                //     className="w-full py-3 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors flex items-center justify-center gap-2"
                                // >
                                //     <span>Buy and Read Now</span>
                                //     <span className="font-semibold">{bookie.price}</span>
                                // </button>
                                <div>
                                    <p className="text-gray-600">Available here:</p>
                                    {bookie.price!="FREE" ? (<PDFAccessControl
                                    bookId={bookie.uid}
                                    price={bookie.price}
                                    title={bookie.title}
                                    price_id={bookie.prod_price_id}
                                />):(
                                    <Link to={bookie.shop_link}  className="btn-vertical">
                                        <button
                                        class="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2">
                                            <BookOpen class="h-4 w-4"/>
                                            Read Now Here!
                                        </button>
                                    </Link>

                                )}
                                </div>

                                
                            )}
                            {/* <p className="text-gray-600">Buy from:</p> */}
                            {(bookie.bookshop_org_link || bookie.amzn_link || bookie.ibook_link) && (
                                <p>We earn a commision when you by a book.</p>
                            )}

                            {/* Amazon Link */}
                            {bookie.amzn_link && (
                                <a
                                    href ={bookie.amzn_link}
                                    className="w-full py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-md transition-colors flex items-center justify-center gap-2"
                                >
                                    Buy From Amazon

                                </a>
                            )}

                            {bookie.ibook_link && (
                                <a
                                    href ={bookie.ibook_link}
                                    className="w-full py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-md transition-colors flex items-center justify-center gap-2"
                                >
                                    Buy From Apple

                                </a>
                            )}

                            {bookie.bookshop_org_link && (
                                <a
                                    href ={bookie.bookshop_org_link}
                                    className="w-full py-3 bg-orange-500 hover:bg-orange-600 text-white rounded-md transition-colors flex items-center justify-center gap-2"
                                >
                                    Buy From Bookshop.org

                                </a>
                            )}

                        </div>




                        <div class="mb-6 md:mb-8">
                        <h2 class="text-lg font-semibold mb-2"> Description</h2>
                        <p class="text-gray-600 text-sm sm:text-base">{book.volumeInfo.description}</p>
                        </div>
                        
                    </div>
                    
                ))}
                </div>
           

            
            

        </div>
        </section>

    );
};
export default BookDetails;