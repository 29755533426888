import React, { useState, useEffect,setState } from "react";
import { useNavigate, useParams, useLocation, Link} from 'react-router-dom';
import { doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc, deleteDoc} from 'firebase/firestore';
import {db, auth_} from '../../firebase';
import axios from 'axios';
import Popup from "../../components/Popup/Popup";
import MoveBookPopup from "../../components/Popup/moveBookPopup";
import AdsComponent from '../../components/AdComponents';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {Share2, BookOpen, Library, Heart, Star, BookPlus, Check, BookLock, ShoppingBagIcon, BookMarked} from 'lucide-react';
import PDFAccessControl from '../../books/PDFAccessControl';



const EbookUnleashedBookDetails = () =>{
    const params = useParams();
    const navigate = useNavigate();
    const user = auth_.currentUser;
    const location = useLocation();
    const [book, setBook] = useState("");
    const [result, setResult] = useState([]);
    const [apiKey, setApiKeyhowReader] = useState("AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw");
    const [isOpen, setIsOpen] = useState(false);
    const [isDuplicate, setDuplicate] = useState(false);
    const [isBookAlreadyIn_FinishedBookShelf, setBookAlreadyIn_FinishedBookShelf] = useState(false);
    const [isBookAlreadyIn_CurrentlyReadingBookShelf, setBookAlreadyIn_CurrentlyReadingBookShelf] = useState(false);
    const [isBookAlreadyIn_WantToReadBookShelf, setBookAlreadyIn_WantToReadBookShelff] = useState(false);

    // Radio Buttons
    const [selectedFinishedReadingValue, setSelectedFinishedReadingValue] = useState("option1");
    const [selectedCurrentlyReadingValue, setSelectedCurrentlyReadingValue] = useState("option2");
    const [selectedWantToReadValue, setSelectedWantToReadValue] = useState("option3");

    const [isBookAddedToBookShelfOpen, setBookAddedToBookShelf] = useState(false);
    const [isAddToLibraryDropdownOpen, setAddToLibraryDropdownOpen] = useState(false);

    const analytics = getAnalytics();

    logEvent(analytics, "Ebook Unleashed Details Page");

    const successAddingBookToShelfNotification = () =>{
        // console.log("success message");
        setBookAddedToBookShelf(!isBookAddedToBookShelfOpen);   
    }

    const handleRadioChangeForFinishedReadingShelf = (value) =>{
   
            setSelectedFinishedReadingValue(value);
  
       
    }

    const handleRadioChangeForCurrentlyReadingShelf = (value) =>{
        setSelectedCurrentlyReadingValue(value);
    }

    const handleRadioChangeForWantToReadReadingShelf = (value) =>{
        setSelectedWantToReadValue(value);
    }


    function handleChange(event){
        const book = event.target.value;
        setBook(book);
    }

    function handleSubmit(book){
        // event.preventDefault();
        axios.get("https://www.googleapis.com/books/v1/volumes?q="
        +book+"&key="
        +apiKey+"&maxResults=1").then(
            data =>{
                // console.log(data.data.items);
                setResult(data.data.items);
            }
        )
       
    }


    const buyBookPopup = () =>{
        // console.log("empty message");
        setIsOpen(!isOpen);   
    }

    const bookInShelfPopup = () =>{
        setDuplicate(!isDuplicate);
    }


    const confirmMoveFinishedReadingBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedFinishedReadingValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedFinishedReadingValue === "option1"){
            options = "Finished Book";
            bookInShelfPopup();

                
        }else if(selectedFinishedReadingValue === "option2"){
            options = "Currently Reading";
            // console.log(options);

            deleteDoc(doc(firestore,"Users",user.uid, "Finished Book", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Finished Book to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();

                successAddingBookToShelfNotification();
        }else{
            options = "Want to Read";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Finished Book", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Finished Book to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }


    const confirmMoveCurrentlyReadingBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedFinishedReadingValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedFinishedReadingValue === "option1"){
            options = "Finished Book";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Currently Reading", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Currently Reading to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


                
        }else if(selectedFinishedReadingValue === "option2"){
            options = "Currently Reading";
            // console.log(options);
            bookInShelfPopup();


        }else{
            options = "Want to Read";
            

            deleteDoc(doc(firestore,"Users",user.uid, "Currently Reading", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Currently Reading to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();


        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }

    const confirmMoveWantToReadBook = (option)=>{
        option.preventDefault();
        var options = "";
        // console.log(selectedWantToReadValue);
        
        let date = new Date();
        date = date.toString();
        const firestore = getFirestore();

        try{
        if(selectedWantToReadValue === "option1"){
            options = "Finished Book";
            deleteDoc(doc(firestore,"Users",user.uid, "Want to Read", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options , location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Want to Read to " + options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();

                
        }else if(selectedWantToReadValue === "option2"){
            options = "Currently Reading";
            // console.log(options);

            deleteDoc(doc(firestore,"Users",user.uid, "Want to Read", location.state.book_title));
   
            setDoc(doc(firestore,"Users",user.uid, options, location.state.book_title),{
                    DateandTime: date,
                    bookCategory: location.state.book_category,
                    bookImage: location.state.book_image,
                    MoveBook: "Book Moved from Want to Read to " +options,
                    bookTitle: location.state.book_title,

                });
                // bookInShelfPopup();
                successAddingBookToShelfNotification();

        }else{
            options = "Want to Read";
            bookInShelfPopup();

        }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }

    }

    const MoveFinishedBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_FinishedBookShelf(!isBookAlreadyIn_FinishedBookShelf);   
    }

    const MoveCurrentReadingBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_CurrentlyReadingBookShelf(!isBookAlreadyIn_CurrentlyReadingBookShelf);   
    }

    const MoveWantToReadBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_WantToReadBookShelff(!isBookAlreadyIn_WantToReadBookShelf);   
    }


    const onToFinishedBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
            // if(read.exists() || current.exists() || want.exists()){
            //     // return (<div><p>Already exists!</p></div>);
            //     console.log("book exists in shelves. Would you like to move it?");
            //     // if(read.exists()){
            //         MoveFinishedBook();
                
            // // }

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     return (<div></div>);

            //     {/* {console.log("book exists in shelves. Would you like to move it?")}; */}
                

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Currently Reading", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     console.log("book exists in shelves. Would you like to move it?");

            // }else{
                if(read.exists()){
                    MoveFinishedBook();
    
                }else if(current.exists()){
                    MoveCurrentReadingBook();
    
                }else if(want.exists()){
                    MoveWantToReadBook();
    
                }else{
                setDoc(doc(firestore,"Users",user.uid, "Want to Read", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user.uid, "Wishlist",title));
       

            if(wishlist.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Wishlist", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: " + event );

        }


    }


    const onToFavoritesBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user.uid, "Favorites",title));
    

            if(favorities.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Favorites", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onBuyBook =()=>{
        // buyBookPopup();
    }

    const BookAlreadyInShelfPopup =()=>{
        bookInShelfPopup();
    }


    const onRateBook = (book_name)=>{
        navigate('/rate_book',{
            state: {book_title: book_name }
        });

    }

    useEffect(()=>{
        handleSubmit(location?.state?.book_title)
    },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">

        <div class="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 random-lg shadow-sm">
        <AdsComponent dataAdSlot='7635585815'/>

            

            {isBookAddedToBookShelfOpen && 
                    <div>
                    <Popup
                    handleClose = {successAddingBookToShelfNotification}
                    title = "Congratulations!"
                    content = "Your book club has been created!"
                />
                </div>}

            {/* Buying Book Popup */}
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {buyBookPopup}
                    title = "Buy Books From One Of Our Affliates Below:"
                    content = {
                    <div>
                        <p><a className ="buybook-links" href={location.state.amazon}>Amazon</a></p>
                        <p><a className ="buybook-links" href={location.state.apple}>Apple</a></p>
                        <p><a className ="buybook-links" href={location.state.bookshop}>Bookshop.org</a></p>
                    </div>
                    }
                />
                </div>}

            {/* Duplicate Bookshelf Popup */}
            {isDuplicate && 
                    <div>
                    <Popup
                    handleClose = {BookAlreadyInShelfPopup}
                    title = "Book Already In Shelf!"
                    content = "This book is already in this shelf"
                />
                </div>}

                {/* Already in Finished Books Book Shelf */}
                {isBookAlreadyIn_FinishedBookShelf&&<div>
                    <MoveBookPopup
                    handleClose = {MoveFinishedBook}
                    content = {
                    <div>
                        <h2>Book Already In Finished Reading Book Shelf</h2>
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                            <form onSubmit={confirmMoveFinishedReadingBook}>
                            <div className="radioGroup">
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                        value="option1"
                                        checked = {selectedFinishedReadingValue==="option1"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedFinishedReadingValue==="option2"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedFinishedReadingValue==="option3"}
                                        onChange={()=>handleRadioChangeForFinishedReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}

                {/* Already in Currently Reading Book Shelf */}
                {isBookAlreadyIn_CurrentlyReadingBookShelf&&<div>
                    <MoveBookPopup
                    handleClose = {MoveCurrentReadingBook}
                    content = {
                    <div>
                        <h2>Book Already In Currently Reading Book Shelf</h2>
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                            <form onSubmit={confirmMoveCurrentlyReadingBook}>
                            <div className="radioGroup">
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                        value="option1"
                                        checked = {selectedCurrentlyReadingValue==="option1"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedCurrentlyReadingValue==="option2"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedCurrentlyReadingValue==="option3"}
                                        onChange={()=>handleRadioChangeForCurrentlyReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}

                {/* Already in Want to Read Book Shelf */}
                {isBookAlreadyIn_WantToReadBookShelf&&<div>
                    <MoveBookPopup
                    // handleMoveBookConfirmation = {confirmMoveBook}
                    handleClose = {MoveWantToReadBook}
                    content = {
                    <div>
                        <h2>Book Already In Want To Read Book Shelf</h2>
                        <p>Would you like to move the book?</p>
                        <div className="horizontal-line"></div>
                        <div className="radioGroupContainer">
                        <form onSubmit={confirmMoveWantToReadBook}>
                            <div className="radioGroup">
            
                                <div className="radioButton">
                                    <input
                                        type="radio"
                                        id ="option1"
                                      
                                        value="option1"
                                        checked = {selectedWantToReadValue==="option1"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option1")}
                                    />
                                    <label
                                        htmlFor="option1"
                                        className="radioLabel">Finished Books Shelf</label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                       
                                        id ="option2"
                                        value="option2"
                                        checked = {selectedWantToReadValue==="option2"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option2")}
                                    />
                                    <label
                                        htmlFor="option2"
                                        className="radioLabel">
                                        Currently Reading Books Shelf
                                    </label>
                                </div>

                                <div className="radioButton">
                                    <input
                                    className="radioInput"
                                        type="radio"
                                
                                        id ="option3"
                                        value="option3"
                                        checked = {selectedWantToReadValue==="option3"}
                                        onChange={()=>handleRadioChangeForWantToReadReadingShelf("option3")}
                                    />
                                    <label
                                        htmlFor="option3"
                                        className="radioLabel">
                                        Want To Read Books Shelf
                                    </label>
                                </div>

                            </div>
                            <button type="submit">Confirm</button>
                            </form>
                        </div>

                    </div>
                    }
                />
                </div>}








{/* ------------------------------------------------------- */}


          
                    <div>
                    <div class="flex justify-center md:justify-start md:w-64 flex-shrink-0">
                        <img 
                            class="shadow-lg rounded-lg w-48 md:w-full h-auto"
                            src={location?.state?.book_image} />
                    </div>
                    <div class="flex-1">
                    <div class="mb-6 md:mb-8 text-center md:text-left">
                        <h1 class="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-2">{location?.state?.book_title}</h1>
                        <p class="text-lg sm:text-xl text-gray-600 mb-3">Author</p>

                        <div class="flex justify-between items-center mt-2 mb-2">
                            <span class="font-semibold">Price</span>
                            <span class="text-green-600 font-bold">{location?.state?.book_price}</span>

                        </div>

                        
                      
                        {/* <button onClick={()=>onBuyBook()} className="btn-vertical">Buy Book</button> */}
                        {location?.state?.book_price =="FREE"? <Link to={location?.state?.shop}  className="btn-vertical"><button
                        class="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2">
                            <BookOpen class="h-4 w-4"/>
                            Read Now Here!
                            </button></Link>:
                        <Link to={location?.state?.shop}  className="btn-vertical"><button 
                        class="w-full bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center gap-2">
                            <ShoppingBagIcon class="h-4 w-4"/>
                            Buy Book PDF*
                        </button><p>*Please allow up to 48hrs to receive PDFs via email.</p></Link>}
                        
                        <PDFAccessControl
                            bookId={location?.state?.uid}
                            price={location?.state?.book_price}
                            title={location?.state?.book_title}
                        />
                        

                        <div class="relative">
                            <button 
                            class="w-full mt-4 mb-4 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2"
                            onClick={()=>setAddToLibraryDropdownOpen(!isAddToLibraryDropdownOpen)}>
                                <Library class="h-4 w-4"/>
                                Add to Shelf
                            </button>
                            {isAddToLibraryDropdownOpen && (
                            <div class="absolute top-full left-0 w-full mt-1 bg-white border border-gray-200 text-gray-900 rounded-lg shadow-lg py-1 z-10">
                                <button 
                                class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                onClick={(e)=>onToFinishedBooksShelf(location?.state?.book_title, location?.state?.book_category, location?.state?.book_image,e)}>
                                    <BookLock class="h-4 w-4 text-blue-500"/>
                                    Finished Reading
                                </button>
                                <button class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                onClick={(e)=>onToCurrentlyReadingBooksShelf(location?.state?.book_title, location?.state.book_category, location?.state?.book_image,e)}>
                                    <BookOpen class="h-4 w-4 text-blue-500"/>
                                    Currently Read
                                </button>
                                <button class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                onClick={(e)=>onToWantToReadBooksShelf(location?.state?.book_title, location?.state.book_category, location?.state?.book_image,e)}>
                                    <BookMarked class="h-4 w-4"/>
                                    Want to Read
                                </button>
                                <button 
                                class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                onClick={(e)=>onToWishlistBooksShelf(location?.state?.book_title, location?.state?.book_category, location?.state?.book_image,e)}>
                                    <Star class="h-4 w-4 text-yellow-500"/>
                                    Add to Wishlist
                                </button>
                                <button 
                                class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                onClick={(e)=>onToFavoritesBooksShelf(location?.state?.book_title, location?.state?.book_category, location?.state?.book_image,e)}>
                                    <Heart class="h-4 w-4 text-red-500"/>
                                    Add to Favorites
                                </button>
                            </div>
                            )}
                        </div>
                        

                        <div class="flex flex-col sm:flex-row items-center gap-4">
                            <button 
                            class="w-full px-4 py-2 border border-gray-300 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors flex items-center justify-center gap-2"
                            onClick={()=>onRateBook(location?.state?.book_title,)}>
                                <Star class="h-4 w-4"/>
                                Rate Book
                            </button>
                            <button
                            class="w-full px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2">
                                <Share2 class="h-4 w-4"/>
                                Share
                            </button>
                        </div>
                        

                        <div class="mb-6 mt-2 md:mb-8">
                        <h2 class="text-lg font-semibold mb-2"> Description</h2>
                        <p class="text-gray-600 text-sm sm:text-base">{location?.state?.book_description}</p>
                        </div>
                        <p>Published: {location?.state?.book_published}</p>
                    
                    </div>    
                    </div>
                    </div>
        </div>
        </section>
    );
};
export default EbookUnleashedBookDetails;