import React, {useState} from 'react';
import {ArrowLeft, Save, Download, PlusCircle, X, DollarSign, Calculator, HelpCircle, Check, Edit, Trash, Info} from 'lucide-react';
import {doc, getFirestore, setDoc, getDoc} from 'firebase/firestore';

const BusinessBudgetTemplate = () =>{
    const [activeTab, setActiveTab] = useState('startup');
    const [showTotals, setShowTotals] = useState(true);
    // start up cost (one-time expenses)
    const [startupCosts, setStartupCosts] = useState([
        {id: 1, category: 'Legal', item: 'Business Registration', amount: 500, notes: ''},
        {id: 2, category: 'Equipment', item: 'Computer/Hardware', amount: 2000, notes: ''},
        {id: 3, category: 'Software', item: 'Software Licenses', amount: 1000, notes: ''},
        {id: 4, category: 'Marketing', item: 'Website Development', amount: 3000, notes: ''},
        {id: 5, category: 'Space', item: 'Security Deposit', amount: 2500, notes: ''},

    ]);

    // monthly operating expenses
    const [monthlyExpenses, setMonthlyExpenses] = useState([
        {id: 1, category: 'Rent', item: 'Office Space', amount: 1500, notes: ''},
        {id: 2, category: 'Utilities', item: 'Internet & Phone', amount: 100, notes: ''},
        {id: 3, category: 'Salaries', item: 'Employees', amount: 5000, notes: ''},
        {id: 4, category: 'Marketing', item: 'Digital Advertising', amount: 1000, notes: ''},
        {id: 5, category: 'Software', item: 'SaaS Subscription', amount: 300, notes: ''},

    ]);


    // revenue projections
    const [revenueProjections, setRevenueProjections] = useState([
        {id: 1, month: 1, revenue: 3000, expenses: 8000, profit: -5000},
        {id: 2, month: 2, revenue: 5000, expenses: 8000, profit: -3000},
        {id: 3, month: 3, revenue: 8000, expenses: 8500, profit: -500},
        {id: 4, month: 4, revenue: 12000, expenses: 9000, profit: 3000},
        {id: 5, month: 5, revenue: 15000, expenses: 9500, profit: 5500},

    ]);

    // calculate totals
    const startupTotal = startupCosts.reduce((sum, item) => sum + item.amount,0);
    const monthlyTotal = monthlyExpenses.reduce((sum, item) => sum + item.amount,0);
    const sixMonthOperatingCost = monthlyTotal * 6;
    const totalInitialInvestment = startupTotal + sixMonthOperatingCost;

    // adding new items
    const [newItem, setNewItem] = useState({
        category: '',
        item: '',
        amount: '',
        notes: ''
     });

     const handleAddStartupCost = () =>{
        if(newItem.category && newItem.item && newItem.amount){
            setStartupCosts([
                ...startupCosts,
                {
                    id: startupCosts.length +1,
                    category: newItem.category,
                    item: newItem.item,
                    amount: Number(newItem.amount),
                    notes: newItem.notes
                }
            ]);

            setNewItem({
                category: '',
                item: '',
                amount: '',
                notes: ''
            });

        }

     }

     const handleMonthlyExpense = () =>{
        if(newItem.category && newItem.item && newItem.amount){
            setMonthlyExpenses([
                ...monthlyExpenses,
                {
                    id: monthlyExpenses.length +1,
                    category: newItem.category,
                    item: newItem.item,
                    amount: Number(newItem.amount),
                    notes: newItem.notes
                }
            ]);

            setNewItem({
                category: '',
                item: '',
                amount: '',
                notes: ''
            });

        }

     }

     const handleRemoveItem = (id, type)=>{
        if(type === 'startup'){
            setStartupCosts(startupCosts.filter(item=>item.id !== id));

        }else if(type === 'monthly'){
            setMonthlyExpenses(monthlyExpenses.filter(item=>item.id !== id));
        }

     }

     const handleInputChange = (e) =>{
        const {name, value} = e.target;
        setNewItem({
            ...newItem,
            [name]: value
        });

     }
    

    return(
        <section className='bg-gray-900'>
            <div className='max-w-6xl mx-auto p-3 md:p-6'>
                {/* header */}
                <div className='flex items-center justify-between mb-6'>
                    <div className='flex items-center'>
                        <h1 className='text-xl md:text-2xl font-bold'>Business Budget Template</h1>
                    </div>
                    <div className='flex gap-2'>
                        <button className='flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 text-sm md:px-4'>
                            <Download className='w-4 h-4'/>
                            <span className='hidden md:inline'>Export</span>
                        </button>
                        <button className='flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 text-sm md:px-4'>
                            <Save className='w-4 h-4'/>
                            <span className='hidden md:inline'>Save Interview</span>
                        </button>
                    </div>
                </div>
                {/* Tabs */}
                <div className='flex mb-6 border-b overflow-x-auto'>
                    <button
                        onClick={()=>setActiveTab('startup')}
                        className={`px-4 py-2 whitespace-nowrap ${
                            activeTab === 'startup' ? 'border-b-2 border-blue-600 text-blue-600 font-medium':
                            'text-gray-600'
                        }`}
                    >
                        Startup Costs
                    </button>

                    <button
                    onClick={()=>setActiveTab('monthly')}
                    className={`px-4 py-2 whitespace-nowrap ${
                        activeTab === 'monthly' ? 'border-b-2 border-blue-600 text-blue-600 font-medium':
                        'text-gray-600'
                    }`}
                    >
                        Monthly Expenses
                    </button>

                    <button
                    onClick={()=>setActiveTab('projections')}
                    className={`px-4 py-2 whitespace-nowrap ${
                        activeTab === 'projections' ? 'border-b-2 border-blue-600 text-blue-600 font-medium':
                        'text-gray-600'
                    }`}
                    >
                        6-Month Projections
                    </button>

                    <button
                    onClick={()=>setActiveTab('summary')}
                    className={`px-4 py-2 whitespace-nowrap ${
                        activeTab === 'summary' ? 'border-b-2 border-blue-600 text-blue-600 font-medium':
                        'text-gray-600'
                    }`}
                    >
                        Summary
                    </button>

                </div>

                {/* Content Area */}
                <div className='bg-white border rounded-lg p-4 md:p-6 mb-6'>
                    {/* startup cost tab */}
                    {activeTab === 'startup' && (
                        <div>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-lg md:text-xl font-semibold'>One-Time Business Costs</h2>
                                {showTotals && (
                                    <div className='text-lg font-semibold text-blue-600'>
                                        Total: {startupTotal.toLocaleString()}
                                    </div>
                                )}
                            </div>

                            <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6'>
                                <div className='flex items-start gap-2'>
                                    <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                                    <div>
                                        <h3 className='font-medium text-blue-800 mb-1'>About Startup Costs</h3>
                                        <p className='text-sm text-blue-700'>
                                            These are one-time expenses you will need to pay before or during the early steps of your business.
                                            Include everything you need to purchase to get started.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Table Header */}
                            <div className='hidden md:grid grid-cols-12 gap-4 border-b pb-2 mb-2 font-medium'>
                                <div className='col-span-2'>Category</div>
                                <div className='col-span-4'>Item</div>
                                <div className='col-span-2'>Amount</div>
                                <div className='col-span-3'>Notes</div>
                                <div className='col-span-1'></div>
                            </div>

                            {/* Startup Costs Items */}
                            <div className='space-y-3 mb-6'>
                                {startupCosts.map((item)=>(
                                    <div key={item.id}
                                    className='grid grid-cols-1 md:grid-cols-12 gap-2 md:gap-4 bg-gray-50 p-3 rounded-lg md:bg-transparent md:p-6'
                                    >
                                        <div className='col-span-1 md:col-span-2'>
                                            <div className='text-xs text-gray-500 md:hidden'>Category</div>
                                            <div>{item.category}</div>
                                        </div>

                                        <div className='col-span-1 md:col-span-4'>
                                            <div className='text-xs text-gray-500 md:hidden'>Item</div>
                                            <div>{item.item}</div>
                                        </div>

                                        <div className='col-span-1 md:col-span-2'>
                                            <div className='text-xs text-gray-500 md:hidden'>Amount</div>
                                            <div>${item.amount.toLocaleString()}</div>
                                        </div>


                                        <div className='col-span-1 md:col-span-3'>
                                            <div className='text-xs text-gray-500 md:hidden'>Notes</div>
                                            <div className='text-sm text-gray-600'>{item.notes}</div>
                                        </div>

                                        <div className='col-span-1 flex justify-end'>
                                            <button
                                                onClick={()=>handleRemoveItem(item.id, 'startup')}
                                                className='p-1 text-gray-400 hover:text-red-600 hover:bg-gray-100 rounded'
                                            >
                                                <X className='w-4 h-4'/>
                                            </button>

                                        </div>
                                    </div>
                                ))}

                            </div>

                            {/* add new item form */}
                            <div className='bg-gray-50 p-4 rounded-lg'>
                                <h3 className='font-medium mb-3'>Add New Startup Cost</h3>
                                <div className='grid grid-cols-1 md:grid-cols-12 gap-3'>
                                    <div className='md:col-span-2'>
                                        <input
                                            type="text"
                                            name="category"
                                            placeholder='Category'
                                            value={newItem.category}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>


                                    <div className='md:col-span-4'>
                                        <input
                                            type="text"
                                            name="item"
                                            placeholder='Item Description'
                                            value={newItem.item}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>


                                    <div className='md:col-span-2'>
                                        <div className='relative'>
                                            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                                <DollarSign className='w-4 h-4 text-gray-400'/>
                                            </div>
                                        <input
                                            type="number"
                                            name="amount"
                                            placeholder='Amount'
                                            value={newItem.amount}
                                            onChange={handleInputChange}
                                            className='w-full pl-8 p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>
                                    </div>


                                    <div className='md:col-span-3'>
                                        <input
                                            type="text"
                                            name="notes"
                                            placeholder='Notes (Optional)'
                                            value={newItem.notes}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>

                                    <div className='md:col-span-1'>
                                        <button
                                        onClick={handleAddStartupCost}
                                        disabled = {!newItem.category || !newItem.item || !newItem.amount} 
                                        className='w-full h-full bg-blue-600 text-white rounded-lg disabled:bg-blue-300 flex items-center justify-center'>
                                            <PlusCircle className='w-5 h-5'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* monthly expenses tab */}
                    {activeTab === 'monthly' && (
                        <div>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-lg md:text-xl font-semibold'>Monthly Operating Expenses</h2>
                                {showTotals && (
                                    <div className='text-lg font-semibold text-blue-600'>
                                        Monthly Total: ${monthlyTotal.toLocaleString()}
                                    </div>
                                )}
                            </div>

                            <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6'>
                                <div className='flex items-start gap-2'>
                                    <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                                    <div>
                                        <h3 className='font-medium text-blue-800 mb-1'>About Monthly Expenses</h3>
                                        <p className='text-sm text-blue-700'>
                                            These recurring expenses help you calculate monthly burn rate and how much revenue you will need to break even.
                                        </p>
                                    </div>
                                </div>
                            </div>
                             {/* Table Header */}
                             <div className='hidden md:grid grid-cols-12 gap-4 border-b pb-2 mb-2 font-medium'>
                                <div className='col-span-2'>Category</div>
                                <div className='col-span-4'>Item</div>
                                <div className='col-span-2'>Amount</div>
                                <div className='col-span-3'>Notes</div>
                                <div className='col-span-1'></div>
                            </div>

                            {/* Startup Costs Items */}
                            <div className='space-y-3 mb-6'>
                                {monthlyExpenses.map((item)=>(
                                    <div key={item.id}
                                    className='grid grid-cols-1 md:grid-cols-12 gap-2 md:gap-4 bg-gray-50 p-3 rounded-lg md:bg-transparent md:p-6'
                                    >
                                        <div className='col-span-1 md:col-span-2'>
                                            <div className='text-xs text-gray-500 md:hidden'>Category</div>
                                            <div>{item.category}</div>
                                        </div>

                                        <div className='col-span-1 md:col-span-4'>
                                            <div className='text-xs text-gray-500 md:hidden'>Item</div>
                                            <div>{item.item}</div>
                                        </div>

                                        <div className='col-span-1 md:col-span-2'>
                                            <div className='text-xs text-gray-500 md:hidden'>Amount</div>
                                            <div>${item.amount.toLocaleString()}</div>
                                        </div>


                                        <div className='col-span-1 md:col-span-3'>
                                            <div className='text-xs text-gray-500 md:hidden'>Notes</div>
                                            <div className='text-sm text-gray-600'>{item.notes}</div>
                                        </div>

                                        <div className='col-span-1 flex justify-end'>
                                            <button
                                                onClick={()=>handleRemoveItem(item.id, 'monthly')}
                                                className='p-1 text-gray-400 hover:text-red-600 hover:bg-gray-100 rounded'
                                            >
                                                <X className='w-4 h-4'/>
                                            </button>

                                        </div>
                                    </div>
                                ))}

                            </div>

                            {/* add new item form */}
                            <div className='bg-gray-50 p-4 rounded-lg'>
                                <h3 className='font-medium mb-3'>Add Monthly Expenses</h3>
                                <div className='grid grid-cols-1 md:grid-cols-12 gap-3'>
                                    <div className='md:col-span-2'>
                                        <input
                                            type="text"
                                            name="category"
                                            placeholder='Category'
                                            value={newItem.category}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>


                                    <div className='md:col-span-4'>
                                        <input
                                            type="text"
                                            name="item"
                                            placeholder='Item Description'
                                            value={newItem.item}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>


                                    <div className='md:col-span-2'>
                                        <div className='relative'>
                                            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                                                <DollarSign className='w-4 h-4 text-gray-400'/>
                                            </div>
                                        <input
                                            type="number"
                                            name="amount"
                                            placeholder='Amount'
                                            value={newItem.amount}
                                            onChange={handleInputChange}
                                            className='w-full pl-8 p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>
                                    </div>


                                    <div className='md:col-span-3'>
                                        <input
                                            type="text"
                                            name="notes"
                                            placeholder='Notes (Optional)'
                                            value={newItem.notes}
                                            onChange={handleInputChange}
                                            className='w-full p-2 border rounded-lg bg-white text-black'
                                        />
                                    </div>

                                    <div className='md:col-span-1'>
                                        <button
                                        onClick={handleMonthlyExpense}
                                        disabled = {!newItem.category || !newItem.item || !newItem.amount} 
                                        className='w-full h-full bg-blue-600 text-white rounded-lg disabled:bg-blue-300 flex items-center justify-center'>
                                            <PlusCircle className='w-5 h-5'/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                     {/* 6-month projections tab */}
                     {activeTab === 'projections' && (
                        <div>
                            <div className='flex justify-between items-center mb-4'>
                                <h2 className='text-lg md:text-xl font-semibold'>6-Month Projections</h2>
                            </div>

                            <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6'>
                                <div className='flex items-start gap-2'>
                                    <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                                    <div>
                                        <h3 className='font-medium text-blue-800 mb-1'>About Projections</h3>
                                        <p className='text-sm text-blue-700'>
                                            This shows your estimated revenue, expenses, and profit over the past 5 months.
                                            Edit the revenuw members based on your business plan.

                                        </p>
                                    </div>

                                </div>
                            </div>
                            {/* Projections Table */}
                            <div className='overflow-x-auto'>
                                <table className='w-full border-collapse'>
                                    <thead>
                                        <tr className='border-b'>
                                            <th className='py-2 px-4 text-left'>Month</th>
                                            <th className='py-2 px-4 text-right'>Revenue</th>
                                            <th className='py-2 px-4 text-right'>Expenses</th>
                                            <th className='py-2 px-4 text-right'>Profit/Loss</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {revenueProjections.map((month)=>(
                                            <tr key={month.id} className='border-b'>
                                                <td className='py-3 px-4'>Month {month.month}</td>
                                                <td className='py-3 px-4 text-right'>${month.revenue.toLocaleString()}</td>
                                                <td className='py-3 px-4 text-right'>${month.expenses.toLocaleString()}</td>
                                                <td className={`py-3 px-4 text-right font-medium ${
                                                    month.profit >=0 ? 'text-green-600': 'text-red-600'
                                                }`}>${month.profit.toLocaleString()}</td>

                                            </tr>
                                        ))}

                                    </tbody>

                                </table>

                            </div>
                            {/* chart placeholder */}
                            <div className='mt-6 border border-dashed border-gray-300 rounded-lg p-8 bg-gray-50 flex items-center justify-center'>
                                <div className='text-center text-gray-500'>
                                    <Calculator className='w-12 h-12 mx-auto mb-2 text-gray-400'/>
                                    <p>Revenue & Expense Projection Chart</p>
                                    <p className='text-sm'>(Chart visualization would appear here)</p>

                                </div>
                            </div>
                            
                        </div>
                    )}

                     {/* summary tab */}
                     {activeTab === 'summary' && (
                        <div>
                            <div className='mb-4'>
                                <h2 className='text-lg md:text-xl font-semibold mb-6'>Budget Summary</h2>

                                <div className='bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6'>
                                    <div className='flex items-start gap-2'>
                                        <Info className='w-5 h-5 text-blue-500 mt-0.5'/>
                                        <div>
                                            <h3 className='font-medium text-blue-800 mb-1'>Total Investment Needed</h3>
                                            <p className='text-sm text-blue-700'>
                                                This summary shows your total startup costs, operating expenses, and total funding you will need to launch and operate for 6 months.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                                    {/* summary cards */}
                                    <div className='space-y-4'>
                                        <div className='border rounded-lg p-4 bg-gray-50'>
                                            <div className='text-sm text-gray-500 mb-1'>Total One-Time Startup Costs</div>
                                            <div className='text-2xl font-bold'>${startupTotal.toLocaleString()}</div>
                                        </div>

                                        <div className='border rounded-lg p-4 bg-gray-50'>
                                            <div className='text-sm text-gray-500 mb-1'>Monthly Operating Expenses</div>
                                            <div className='text-2xl font-bold'>${monthlyTotal.toLocaleString()}</div>
                                        </div>

                                        <div className='border rounded-lg p-4 bg-gray-50'>
                                            <div className='text-sm text-gray-500 mb-1'>6-Month Operating Costs</div>
                                            <div className='text-2xl font-bold'>${sixMonthOperatingCost.toLocaleString()}</div>
                                        </div>

                                        <div className='border rounded-lg p-4 bg-blue-50 border-blue-200'>
                                            <div className='text-sm text-blue-700 mb-1'>Total Initial Invesment Needed</div>
                                            <div className='text-2xl font-bold text-blue-800'>${totalInitialInvestment.toLocaleString()}</div>
                                            <div className='text-xs text-blue-600 mt-1'>Startup costs  + 6 months of expenses</div>  
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    )}


                </div>

            </div>
        </section>
    );

}

export default BusinessBudgetTemplate;