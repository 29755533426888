import React, { useState, useEffect,setState } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { query, where, doc, onSnapshot, addDoc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc, deleteDoc, limit} from 'firebase/firestore';
import {db, auth_, get_functions} from './../firebase';
import axios from 'axios';
import Popup from "../components/Popup/Popup";
import MoveBookPopup from "../components/Popup/moveBookPopup";
import AdsComponent from './../components/AdComponents';
import {Share2, BookOpen, Library, Heart, Star, BookPlus, Check, BookLock, ShoppingBagIcon, BookMarked} from 'lucide-react';
import {getStorage, ref, getDownloadURL} from 'firebase/storage';
import {loadStripe} from '@stripe/stripe-js';
import { Alert } from "react-bootstrap";
import {connectFunctionsEmulator, getFunctions, httpsCallable} from "firebase/functions";
import { message } from "antd";

// const stripePromise = loadStripe(process.env.STRIPE_PROD_PK);


const stripePromise = loadStripe('pk_live_51OfRMQBThrEa9Znh0WMfU46vJXeF9oltDSgM1WvoygzolcPiCCBQ5OvVv5R4b4FwPuo27gqoF0uzQ5GC8GYDdh0600R8TCYs8l');


const PDFAccessControl  = ({bookId, price, title, price_id})=>{
    const [hasAccess, setHasAccess] = useState(false);
    const [pdfUrl, setPDFUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const storage = getStorage();
    // const auth = auth_;

    useEffect(()=>{
        checkAccess();
    },[bookId, auth_.currentUser]);

    const checkAccess = async ()=>{
        if(!auth_.currentUser){
            setLoading(false);
            return;
        }

        try{
            const purchaseRef = collection(db, "customers", auth_.currentUser.uid, "checkout_sessions");
            const q = query(
                purchaseRef,
                where("userId", "==", auth_.currentUser.uid),
                where("bookId", "==", bookId),
                limit(1)
            );

            const querySnapshot = await getDocs(q);

            if(!querySnapshot.empty){
                setHasAccess(true);
                try{
                    const pdfRef = ref(storage, `Books/${bookId}/${bookId}.epub`);
                    const url = await getDownloadURL(pdfRef);
                    setPDFUrl(url);
                }catch(err){
                    setError("Failed to retrieve PDF. Please try again later.");
                    // console.error("Error fetching PDF: ", err )

                }
            }

        }catch(err){
            setError("Failed to check access status: ", err);
            // console.error("access check error: ", err);
        }

        setLoading(false);
    }

    // const handlePurchase = async (priceId)=>{
    //     if(!auth_.currentUser){
    //         setError("Please sign in to purchase");
    //         return;
    //     }

    //     setBtnStatus({

    //     })

    // }


    const handlePurchase = async (priceId) => {
        //Disable submit button while checkout processing
        // setBtnStatus({
        //     isLoading: true,
        //     isDisabled: true
        // });
  
       const docRef = await addDoc(collection(db, 'customers', auth_.currentUser.uid,'checkout_sessions'),{
            price: priceId,
            mode: 'payment',
            bookId: bookId,
            userId: auth_.currentUser.uid, 
            success_url: window.location.origin,
            cancel_url: window.location.origin 
       });
  
       
       onSnapshot (docRef,async (snapshot) => {
            const { error, sessionId } = snapshot.data();
    
            if(error) {
                alert(`An error occurred: ${error.message}`);
                // setBtnStatus({
                //     isLoading: false,
                //     isDisabled: false
                // });
            }
    
            if(sessionId) {
                const stripe = await loadStripe('pk_test_51OfRMQBThrEa9Znh87bCpodFcGTeR5o2KucNcvMR0XWU43yo1KSHGD1kVOVJRXlkEJ8ltKWfLEHFPMn40apAKMKS00VUEoJOxT');
                stripe.redirectToCheckout({ sessionId });
            }
       });
    }

    const handlePurchase_v1 = async ()=>{
        if(!auth_.currentUser){
            setError("Please sign in to purchase");
            return;
        }

        try{

            const functions = get_functions;
            const stripe = stripePromise;

            if(process.env.NODE_ENV==='development'){
                    connectFunctionsEmulator(functions, 'localhost',5002);
                }

            const createStripeCheckout = httpsCallable(functions,
                'ext-firestore-stripe-payments-createCheckoutSession'
            );

            const {data} = await createStripeCheckout({
                mode: 'payment',
                line_items: [{
                    quantity: 1,
                    price_data: {
                        currency: 'usd',
                        unit_price: 5,
                        product_data:{
                            name: title,
                            metadata: {bookId}
                        }
                    }

                }],
                metadata: {bookId},
                success_url: window.location.origin+"/success",
                cancel_url: window.location.origin +"/cancel"


            });

            if(data?.sesstionId){
                const {error} = await stripe.redirectToCheckout({sessionId: data.sessionId});
                if(error){
                    throw error;
                }


            }
            







            // // const {getFunctions, httpsCallable} = require('firebase/functions');
            // console.log("Starting purchase process..");
            // const functions = get_functions;
            // console.log("Got Functions");
            // // if(window.location.hostname==='localhost'){
            // //     connectFunctionsEmulator(functions, 'localhost',5001);
            // // }

            // if(process.env.NODE_ENV==='development'){
            //         connectFunctionsEmulator(functions, 'localhost',5001);
            //     }
            
            // const createStripeCheckout = httpsCallable(functions,
            //     'ext-firestore-stripe-payments-createCheckoutSession'
            // );
            // console.log("Created callable");

    
            // console.log("Checkout params",{
            //     mode: 'payment',
            //     line_items: [{
            //         quantity: 1,
            //         price_data: {
            //             currency: 'usd',
            //             unit_price: price,
            //             product_data:{
            //                 name: title,
            //                 metadata: {bookId}
            //             }
            //         }

            //     }],
            //     metadata: {bookId},
            //     success_url: window.location.origin+"/success",
            //     cancel_url: window.location.origin +"/cancel"

            // });
            // const stripe = stripePromise;

            // const {data} = await stripe.checkout.sessions.create({
            //     mode: 'payment',
            //     line_items: [{
            //         quantity: 1,
            //         price_data: {
            //             currency: 'usd',
            //             unit_price: "5.99",
            //             product_data:{
            //                 name: title,
            //                 metadata: {bookId}
            //             }
            //         }

            //     }],
            //     metadata: {bookId},
            //     success_url: window.location.origin+"/success",
            //     cancel_url: window.location.origin +"/cancel"

            // });

            // console.log("checkout response: ", data);

            // const {error} = await stripe.redirectToCheckout({sessionId: data.sessionId});

            // if(error){
            //     throw error;
            // }

        }catch(err){

            setError("Purchase failed. Please try again.", err);
            console.error("Purchase error: ", {
                message: err.message,
                code: err.code,
                details: err.details,
                stack: err.stack
            });

        }
    };

    if(loading){
        return <div className="animate-pulse">Checking access...</div>;

    }

    if(error){
        return (
            <Alert variant="destructive">
                <p>{error}</p>
            </Alert>

        );
    }

    if(!auth_.currentUser){
        return(
            <div className="space-y-4">
                <p className="text-red-600">Please sign in to purchase or access this book.</p>

            </div>
        );
    }

    if(hasAccess){
        return(
            <div className="space-y-4">
                <div className="bg-green-50 p-4 rounded">
                    <p className="text-green-700">You own this book</p>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                    <button
                        onClick={()=> window.open(`/reader/${bookId}`,'_blank')}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                            Read Online
                    </button>

                </div>
            </div>
        );
    }

    return(
        <div className="space-y-4">
            <div className="flex items-baseline gap-2">
                {/* <span className="text-2xl font-bold">{price}</span> */}
                <span className="text-sm text-gray-500">One-time purchase</span>
            </div>

            <button
            onClick={()=>handlePurchase(price_id)} 
            className="w-full py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors flex items-center justify-center gap-2"
            // className="w-full px-4 py-3 bg-green-500 text-white rounded hover:bg-green-600 font-medium"
            >
                <span>Buy and Read Now</span>
                <span className="text-2xl font-bold">{price}</span>
            </button>

            <p className="text-sm text-gray-500">
                Instant Access * PDF Download * Online Reader 
            </p>

        </div>
    );
    

    }

    export default PDFAccessControl;