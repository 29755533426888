import React, {useState} from 'react';
import {FileText, Download, Search, Filter, Menu, Briefcase, Calculator, Users, ArrowRight, Start, Star, Clock} from 'lucide-react';
import { set } from 'react-ga';
import { useNavigate} from 'react-router-dom';


const TemplatesHomepage =  () =>{
    const navigate = useNavigate();


    const [activeCategory, setActiveCategory] = useState('all');

    const categories = [
        {id: 'all', label:'All'},
        {id: 'business', label:'Business'},
        {id: 'financial', label:'Financial'},
        {id: 'market', label:'Market Research'},
    ];

    const templates = [
        {
            category: 'business',
            title: 'One-Page Business Plan',
            description: 'Quick business overview',
            popularity: 'Popular',
            time: '20 min',
            link: '/templates/one-page-business-plan'
        },
        {
            category: 'business',
            title: 'Business Model Canvas',
            description: 'Visual business model',
            popularity: 'Trending',
            time: '30 min'
        },
        {
            category: 'financial',
            title: 'Startup Budget',
            description: 'Financial planning tool',
            popularity: '',
            time: '25 min',
            link:'/templates/business-budget'
        },
        {
            category: 'market',
            title: 'Customer Interview',
            description: 'Questions template',
            popularity: '',
            time: '15 min',
            link: '/templates/customer-interview'
        },
        {
            category: 'market',
            title: 'Competitor Analysis',
            description: 'Market comparison tool',
            popularity: '',
            time: '20 min',
            link: ''
        }

    ];


    const filteredTemplates  = activeCategory === 'all'?
    templates : templates.filter(t => t.category === activeCategory);


    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className='w-full max-w-6xl mx-auto p-4'>
            <div className='flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4'>
                <h1 className='text-2xl font-bold'>Business Templates</h1>
                <div className='w-full md:w-auto flex gap-2'>
                    <div className='relative flex-grow md:flex'>

                    </div>

                </div>

            </div>

            {/* categories filters - horizontal scrollable */}
            <div className='mb-6 bg-white overflow-x-auto p-2 rounded-lg'>
                <div className="flex gap-2 min-w-max">
                    {categories.map(category=>(
                        <button
                        key={category.id}
                        onClick={()=>setActiveCategory(category.id)}
                        className={`px-4 py-2 rounded-lg whitespace-nowrap text-sm ${
                            activeCategory === category.id ? 'bg-blue-100 text-blue-700':'bg-gray-100 text-gray-700'
                        }`}>
                            {category.label}
                        </button>
                    ))}

                </div>
            </div>

            {/* featured template */}
                <div className='mb-8 bg-gradient-to-r from-blue-50 to-indigo-50 border border-blue-100 rounded-lg p-6'>
                    <div className='flex flex-col md:flex-row gap-6'>
                        <div className='flex-grow'>
                    <div className='flex items-center text-blue-600 mb-2'>
                        <Star className="w-5 h-5 mr-2"/>
                        <span className='text-sm font-medium'>RECOMMENDED</span>
                    </div>
                    <h2 className='text-xl font-semibold mb-2'>Business Model Canvas</h2>
                    <p className='text-sm text-gray-600 mb-3'>Map out your entire business on a single page. Define your value propistion, customers, revenue streams, and more with this strategic templaete.</p>

                    <div className='flex items-center gap-2 text-sm text-gray-600 mb-4'>
                        <div className='flex items-center'>
                            <Clock className="w-4 h-4 mr-1"/>
                            <span>30 min to complete</span>
                        </div>
                    </div>
                    <button className='bg-blue-600 text-white px-4 py-2 rounded-lg text-sm w-full'>
                        Open Template
                    </button>
                    </div>
                    </div>
                </div>

            {/* templates list */}
            <div className='mb-4'>
                <h2 className='text-xl font-semibold mb-4'>All Templates</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                    {filteredTemplates.map((template, index)=>(
                        <div key={index} className='bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow'>
                            <div className='flex justify-between items-start mb-2'>
                                <div>
                                    <h3 className='font-semibold'>{template.title}</h3>
                                    <p className='text-sm text-gray-600 mb-4 line-clamp-2'>{template.description}</p>
                                </div>
                                {/* {
                                    template.popularity && (
                                        <span className='px-2 py-1 bg-blue-100 text-blue-700 text-xs rounded-full'>
                                            {template.popularity}
                                        </span>
                                    )
                                } */}

                            </div>

                            <div className='flex justify-between items-center'>
                                <span className='text-xs text-gray-500'>Est. time: {template.time}</span>
                                <button 
                                onClick={()=>navigate(template.link)}
                                className='text-blue-600 flex items-center text-xs'>
                                    Open <ArrowRight className='w-3 h-3 ml-1'/>
                                </button>

                            </div>


                        </div>

                    ))}

                </div>

            </div>


        </div>
        </section>
    );



}

export default TemplatesHomepage;