import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import Popup from "../../components/Popup/Popup";

import {v4 as uuidv4} from 'uuid';
import { MessageCircle } from "lucide-react";



const ViewSpecificDiscussionBoardQuestion = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const firestore = getFirestore();

    const [DiscussionBoardQuestion, showDiscussionBoardQuestion] = useState([]);
    const [DiscussionBoardQuestionReplies, showDiscussionBoardQuestionReplies] = useState([]);
    const [author, getAuthorInfo] = useState([]);


    const [replier, getReplierInfo] = useState([]);




    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");



    const repliesArray = [];
    const replierArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
        navigate('/discussionboard/'+location?.state?.id.toLowerCase().replace(/ /g,""));
    }



    // location.state.id
    const getDiscussionBoardQuestion = async() =>{
        const question = await getDoc(doc(firestore, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question));
        showDiscussionBoardQuestion(question.data());

        // console.log(question.data());


        const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));
        getAuthorInfo(questionAuthor.data());

    }


    // const getDiscussionBoardQuestions = async() =>{

    //     const dbQuestions = await getDocs(collection(db, "DiscussionBoards",location?.state?.id, "Questions"));
    //     const questions =  dbQuestions.docs;
     
    //     questions.forEach( async(question) => {
    //         const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));


    //         questionArray.push(question.data());
    //         showDiscussionBoardQuestions(questionArray);

    //         authorArray.push(questionAuthor.data());
    //         getAuthorInfo(authorArray);
    //       });

    // }


    const getDiscussionBoardQuestionReplies = async() =>{

        const dbQuestionReplies = await getDocs(collection(db, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question,"Replies"));
        const questionReplies =  dbQuestionReplies.docs;

        questionReplies.forEach( async(replies) => {
            const replier = await getDoc(doc(db, "Users", replies.data().replier_uid));


            repliesArray.push(replies.data());
            showDiscussionBoardQuestionReplies(repliesArray);

            replierArray.push(replier.data());
            getReplierInfo(replierArray);
          });    
    //    await getDocs(collection(db, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question,"Replies")).then((querySnapshot)=>{
    //         const replies = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //         }));

    //         showDiscussionBoardQuestionReplies(replies);

    //     });  
        
       }
   

        

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const discussionResponseUniqueID = uuidv4();


        try{

            if(event?.target?.db_reply?.value===null|| event?.target?.db_reply?.value.trim()===""){
                emptyNotification();
            }else{

            setDoc(doc(firestore,"DiscussionBoards",location?.state?.club_name, "Questions", location?.state?.user_question,"Replies", date),{
                dateReplied: date,
                replier: user?.displayName,
                replier_uid: user?.uid,
                replier_email: user?.email,
                reply: event?.target?.db_reply.value,
            });

            setDoc(doc(firestore,"Users",DiscussionBoardQuestion?.author_uid,"Notifications", DiscussionBoardQuestion?.author_uid + discussionResponseUniqueID+user.uid),{
                DateandTime: date,
                discussionBoardName: location?.state?.club_name,
                question: DiscussionBoardQuestion?.question,
                responsderDisplayName: user?.displayName,
                responseID: discussionResponseUniqueID,
                responderUID: user?.uid,
                type: "discussionboard_reply",
                isRead: false
            });

            setDoc(doc(firestore,"mail", DiscussionBoardQuestion?.author_uid + discussionResponseUniqueID+user.uid),{
                to: DiscussionBoardQuestion?.author_email,
                message: {
                    subject: "You have a new message!",
                    html:  user?.displayName + " responded to your question: "+ DiscussionBoardQuestion?.question + " in " + location?.state?.club_name +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            successNotification();
        }
      

        }catch(event){
            console.log("Error Adding Response: ",event );
            warningNotification();

        }


    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    useEffect(()=>{
        getDiscussionBoardQuestion();
        getDiscussionBoardQuestionReplies();
    },[DiscussionBoardQuestionReplies])


    return (
        <div className="w-full max-x-4xl mx-auto bg-gray-900 min-h-screen">

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your response has been submitted!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue submitting your response!"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter a response!"
            />}
            <div className="px-6">
                <div className="bg-gray-700 rounded-lg shadow mb-4 p-6">
                    <div className="flex justify-between items-start mb-4">
                        <h1 className="text-2xl font-bold">{location?.state?.user_question}</h1>
                    </div>
                    {/* Author Info */}
                    <div onClick={()=>onViewReaderProfileButton(author)} className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 rounded-full">
                            <img
                            className="w-full h-full object-cover" 
                            src={author.photoURL? author.photoURL : photoURL} 
                            alt="ProfilePic" /> 
                        </div>
                        <div>
                            <div className="font-semibold">{author?.displayName}</div>
                            <div className="text-sm text-gray-500">{DiscussionBoardQuestion?.dateAsked}</div>
                        </div>
                    </div>
                    {/* Content */}
                    <div className="prose max-w-none mb-6">
                        <p className="mb-4">{location?.state?.question_detail}</p>
                    </div>

                    {/* Actions */}
                    <div className="flex gap-4 text-gray-600">
                        <button className="text-gray-300 flex items-center gap-2 hover:text-blue-600">
                            <MessageCircle size={20}/>
                            <span>{DiscussionBoardQuestionReplies?.length} replies</span>
                        </button>
                    </div>
                </div>
                <div className="bg-gray-700 rounded-lg shadow mb-4 p-6">
                    <h3 className="text-lg font-semibold mb-4">Leave A Reply</h3>
                    <form className="" onSubmit={onSubmitReply}>
                        <textarea 
                        className="w-full border rounded-lg p-4 min-h-32 mb-4" 
                        name="db_reply" 
                        placeholder="Enter response..."/>
                        <div  className="flex justify-end">
                            <button
                            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded" 
                            type="submit">Submit Reply</button>
                        </div>
                    </form>

                </div>
                
                {/* Replies */}
                <div className="space-y-4">
                    {DiscussionBoardQuestionReplies?.map((responses, idx)=>(
                            <div className="bg-gray-700 rounded-lg shadow p-6">
                                {/* Replier Information  */}
                            <div className="flex items-center gap-4 mb-4" onClick={()=>onViewReaderProfileButton(replier[idx])}>
                                <div className="w-12 h-12 rounded-full">
                                    <img
                                    className="w-full h-full object-cover" 
                                    src={replier[idx].photoURL? replier[idx].photoURL : photoURL}
                                    alt="ProfilePic" /> 
                                </div>
                                <div>
                                    <div className="font-semibold">{replier[idx].displayName}</div>
                                    <div className="text-sm text-gray-500">{responses?.dateReplied.toDate().toDateString()}</div>
                                </div>
                            </div>

                            <div className="prose max-w-none mb-4">
                                <p>{responses?.reply}</p>
                            </div>

                            </div>
                        ))
                    }

                </div>

            </div> 

        </div>
    );
};
export default ViewSpecificDiscussionBoardQuestion;