import React, {useEffect, useState} from 'react';
import { useSearchParams } from 'react-router-dom';

const OAuthCallback = ()=>{
    const [searchParams] = useSearchParams()
    const [countdown, setCountdown] = useState(5);
    const [redirectAttempted, setRedirectAttempted] = useState(false);

    // extract with code from URL
    const authCode = searchParams.get('code');
    const error = searchParams.get('error');

    useEffect(()=>{
        // if we have an auth code, try to redirect to the app
        if(authCode & !redirectAttempted){

            // construct the deep link URL
            const deepLink = `entrepreneushipbooksapp://oauth?code=${authCode}`;

            // try to open the app
            window.location.href = deepLink;
            setRedirectAttempted(true);

            // start countdown for manual button or fallback
            const timer = setInterval(()=>{
                setCountdown((prev)=>{
                    if(prev <=1){
                        clearInterval(timer);
                        return 0;

                    }
                    return prev-1;
                
                });
            }, 1000);

            return () => clearInterval(timer);
        }

    },[authCode, redirectAttempted]);

    // handle manual app opening

    const openApp =()=>{
        if(authCode){
            window.location.href = `entrepreneurshipbooksapp://oauth?code=${authCode}`;
        }
    };

    // handle download app 
    const downloadApp = () => {
        const isIOS = /iPad|iPhone/.test(navigator.userAgent);
        if(isIOS){
            window.location.href ="https://apps.apple.com/us/app/entrepreneurship-books/id1631155648";
        }else{
            window.location.href = "https://play.google.com/store/apps/details?id=com.entrep_bookclub_app.bookclub";
        }
        
    }

  

    return (
        <div className='min-h-screen bg-gradient-to-b from-indigo-50 to-white flex flex-col items-center justify-center p-4'>
            <div className='max-w-md w-full bg-white rounded-xl shadow-lg overflow-hidden'>
                <div className='p-8'>
                    <h1 className='text-2xl font-bold text-center text-gray-800 mb-2'>
                        {error? 'Authentication Error': 'Authentication Successful'}
                    </h1>

                    {error ? (
                        <div className='text-center mb-6'>
                            <p className='text-red-500 mb-4'>
                                There was an error connecting your account.
                            </p>
                            <p className='text-gray-600'>
                                Please try again or contact support if the issue persists.
                            </p>
                        </div>

                    ):(
                        <div className='text-center mb-6'>
                            <p className='text-gray-600 mb-2'>
                                {redirectAttempted
                                ? "We're trying to open the app on  your device...":
                                "We've successfully connected your account!"}
                            </p>

                            {redirectAttempted && (
                                <p className='text-sm text-gray-500'>
                                    If the app doesnt open automatically, use the button below.
                                </p>
                            )}

                        </div>

                    )}

                    {authCode && (
                        <div className='flex flex-col gap-3'>
                            <button
                            onClick={openApp}
                            className='w-full bg-indigo-600 text-white py-3  px-4 rounded-lg hover:bg-indigo-700 transition duration-300 flex items-center justify-center ga-2'>
                                <span>Open Entrepreneurship Books App</span>
                                {countdown > 0 && redirectAttempted && (
                                    <span className='text-xs bg-white bg-opacity-20 px-2 py-1 rounded-full'>
                                        {countdown}
                                    </span>
                                )}

                            </button>

                            <div className='relative flex items-center py-2'>
                                <div className='flex-grow border-t border-gray-300'></div>
                                <span className='flex-shrink mx-3 text-gray-500 text-sm'>or</span>
                                <div className='flex-grow border-t border-gray-300'></div>
                            </div>

                            <button
                            onClick={downloadApp}
                            className='w-full border border-indigo-600 text-indigo-600 py-3 px-4 rounded-lg hover:bg-indigo-50 transition duration-300'
                            >
                                Download the App
                            </button>

                        </div>
                    )}

                    {error && (
                        <button
                        onClick = {()=> window.close()}
                        className='w-full mt-4 bg-gray-600 text-white py-3 px=4 rounded-lg hover:bg-gray-700 transition duration-300'
                        >
                            Close Window
                        </button>
                    )}

                </div>

                <div className='px-8 py-4 bg-gray-50 border-t border-gray-100'>
                    <p className='text-center text-sm text-gray-500'>
                        Return to <a href = "https://www.entrepreneurshipbooksapp.com" className='text-indigo-600 hover:underline'>Entrepreneurship Books website</a>
                    </p>
                </div>

            </div>

        </div>
    );

    
}

export default OAuthCallback;