import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen,faCheck,faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams, useLocation, Link} from 'react-router-dom';
import {collection, increment, updateDoc, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc, arrayUnion, arrayRemove, serverTimestamp, Timestamp, getCountFromServer, query, orderBy, onSnapshot, where} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import Popup from "../../components/Popup/Popup";
import {v4 as uuidv4} from 'uuid';
import { Book,  BookOpen,  Calendar, ChevronDown, ChevronUp, Copy, MessageSquare, PlusCircle, Search, Settings, User, Users2, X } from "lucide-react";
import { set } from "react-ga";
// const crypto = require("crypto");



const ViewBookClub = () =>{

    const cache = {
        books: new Map(),
        users: new Map(),
        expirationTime: 30*60*1000
    }
    const user = auth_.currentUser;

    const {clubNameId} = useParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [allBooks, setBooks] = useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [nominations, setNominations] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(user?.uid);

    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
   
    const location = useLocation();
    const date = new Date;
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");


    const [DiscussionSections, showDiscussionSections] = useState([]);

    const [BookClub, showBookClub] = useState([]);

    const [bookClubCreatorInfo, getBookClubCreatorInfo] = useState([]);
    const [BookClubReplies, showBookClubReplies] = useState([]);
    const [responder, getResponderInfo] = useState([]);


    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isPressedCreateNewSection, setNewSectionFromPressed] = useState(false);
    const [discussionSectionName, setDiscussionSectionName] = useState("");


    const [isJoined, setAsJoinedMember] = useState(false);


    const [bkSection_Description, saveBKSectionDescription] = useState("");

    const [inviteLink, setInviteLink] = useState("");
    const [isModerator, setIsModerator] = useState(false);
    const [bookClubMemberCount, countMemmbers] = useState("");

    const [activeDiscussion, setActiveDiscussion] = useState(null);
    const [expandedDiscussion, setExpandedDiscussion] = useState(null);
    const [discussions, setDiscussions] = useState([]);

    const [isNominationFormOpen, setNominationFormOpen] = useState(false);

    const [bookClubDiscussionCreation, showBookClubDiscussionCreation] = useState(false);



    const responseArray = [];
    const responderArray = [];

    const firestore = getFirestore();

    // fetch and cache a book
    const fetchBook = async(bookId)=>{
        const cached = cache.books.get(bookId);
        if(cached && Date.now()-cached.timestamp < cached.expirationTime){
            return cached.data;
        }

        const bookDoc = await getDoc(doc(db, 'Books', bookId));
        const bookData = bookDoc.data();

        cache.books.set(bookId, {
            data: {id: bookId, ...bookData},
            timestamp: Date.now()
        });
        return {id: bookId, ...bookData};

    }


    // fetch and cache a user
    const fetchUser = async(userId)=>{
        const cached = cache.users.get(userId);
        if(cached && Date.now()-cached.timestamp < cached.expirationTime){
            return cached.data;
        }

        const userDoc = await getDoc(doc(db, 'Users', userId));
        const userData = userDoc.data();

        cache.users.set(userId, {
            data: {id: userId, ...userData},
            timestamp: Date.now()
        });
        return {id: userId, ...userData};

    }

    // handle book nomination voting
    const handleVote = async (nominationId, currentVotes, hasVoted, voteType) =>{
        try{
            const nominationRef = doc(db,'BookClubs',clubNameId, 'Nominations', nominationId);
            // update vote count and users vote status
            await updateDoc(nominationRef, {
                votes: hasVoted?
                increment(voteType === 'up' ? -1 : 1):
                increment(voteType === 'up' ? 1 : -1),

                votedUsers: hasVoted?
                arrayRemove(currentUserId):
                arrayUnion(currentUserId)
            });

        }catch(error){

        }

    }

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }


    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onPastReadsButton  = (name) =>{
        navigate(name+'/pastreadshelf');
    }

    const onCurrentReadsButton  = (name) =>{
        navigate(name+'/currentreadshelf');
    }

    const onAddBookButton  = (name) =>{
        navigate('/bookclublibrary',{
            state:{
                club_name: name
            }
        });
    }


    const onViewMembers  = (name) =>{
        navigate("/"+name+'/members',{
            state:{
                club_name: name
            }
        });
    }

    // function to format dates
    const formatTimestamp =(timestamp)=>{
        if(!timestamp) return '';

        // if its a firestore timestamp
        if(timestamp.seconds){
            return new Date(timestamp.seconds * 1000).toLocaleString();
        }

        // if its already a Date object
        if(timestamp instanceof Date){
            return timestamp.toLocaleString();
        }

        // if its a string
        return timestamp;

    }

    const toggleDiscussionSection = (discussionId) =>{
        if(expandedDiscussion === discussionId){
            setExpandedDiscussion(null);
        }else{
            setExpandedDiscussion(discussionId);
        }


    }

    const getBookClub = async() =>{



        const firestore = getFirestore();

        // console.log("book club");

        // const bookClubCreationInfo = await getDoc(doc(firestore, "BookClubs", clubNameId));
        // console.log(u.data());

        const q = query(
                        collection(db, 'BookClubs'),
                        where('bookClubName', '==',clubNameId)
                    );
    
        const querySnapshot = await getDocs(q);
        const bookClubCreationInfo = querySnapshot.docs[0];

        console.log(clubNameId);
        console.log(bookClubCreationInfo?.data());

        showBookClub(bookClubCreationInfo?.data());  

        

        const creatorInfo = await getDoc(doc(firestore, "Users", bookClubCreationInfo?.data().creator_uid));
        getBookClubCreatorInfo(creatorInfo.data());

        setIsModerator(bookClubCreationInfo?.data().moderatorIDs?.includes(user?.uid)||false);
        // console.log(bookClubCreationInfo?.data());
        // console.log(bookClubCreationInfo?.data().moderatorIDs?.includes(user?.uid))
        

        // collection(firestore,"BookClubs").doc(location.state.id).get().then((snapshot)=>{
        //     const bookclub = snapshot.data();

            // console.log("book club");
            // console.log(bookclub);

            // showBookClub(bookclub);

        // });

        // const firestore = getFirestore();

        // const c = doc(firestore, "Users", location.state.id);

        // await getDocs(c).then((querySnapshot)=>{
        //     const bookclub = querySnapshot.docs.map((doc)=>({
        //         ...doc.data()
        //     }));

        //     if(bookclub.name!==""){
        //         showBookClub(bookclub);
        //     }
            
            

        // });   


    }

    const checkJoinedClub = async () =>{
        try{
            // console.log(clubNameId);

            const firestore = getFirestore();
            const member = await getDoc(doc(firestore,"BookClubs",clubNameId,"Members",user?.uid));
            // console.log(member);
            if(member.exists()){
                console.log("Member already exist!");
                setAsJoinedMember(!isJoined);
            }else{
                setAsJoinedMember(isJoined);
            }
        }catch(e){
                console.log(e);
            }

    }




    const onJoinBookClub = async () =>{
        const joinBookclubUniqueID = uuidv4();

        try{

            const firestore = getFirestore();
            // const member = await getDoc(doc(firestore,"BookClubs",location.state.id,"Members",user.uid));
            // console.log(member);

            
            
            if(isJoined){
                
                console.log("Member already exist!");

                // setAsJoinedMember(!isJoined);
                deleteDoc(doc(firestore,"BookClubs",clubNameId,"Members",user.uid));


            }else{
                console.log("Member is now added!");
                setAsJoinedMember(!isJoined);
                // console.log(location?.state?.id);
                setDoc(doc(firestore,"BookClubs",clubNameId,"Members",user.uid),{
                    followerDisplayName: user?.displayName,
                    followerUID: user?.uid,
                    
                    DateandTime: date,
                });


                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ joinBookclubUniqueID+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    newMemberDisplayName: user?.displayName,
                    joinedBookClubID: joinBookclubUniqueID,
                    newMemberrUID: user?.uid,
                    type: "bookclub_member_added",
                    isRead: false
                });


                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ joinBookclubUniqueID+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "A new person joined  your book club!",
                        html:  user?.displayName + " joined your book club "+ BookClub?.bookClubName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });

            }
  
        }catch(event){
            // warningNotification();
            console.log("Error Adding Member to Club: ",event );

        }


    }


    const getBookClubResponses = async() =>{

        const bookClubResponses = await getDocs(collection(db, "BookClubs",clubNameId, "Replies"));
        const responses = bookClubResponses.docs;

        responses.forEach( async(response) => {
            const responseAuthor = await getDoc(doc(db, "Users", response.data().replier_uid));

            responseArray.push(response.data());
            showBookClubReplies(responseArray);

            responderArray.push(responseAuthor.data());
            getResponderInfo(responderArray);
          });
         
        }    
    
        const onSubmitCommentButton = async (event) =>{
            event.preventDefault();
            let date = new Date();
            date = date.toString();
            // console.log(location.state.club_name);
            const firestore = getFirestore();
            const bookclubCommentCreatorID_ = uuidv4();

    
            try{
    
                if(event?.target?.db_reply?.value===null || event?.target?.db_reply?.value.trim()===""){
                    emptyNotification();
                }else{
    
                setDoc(doc(firestore,"BookClubs",clubNameId, "Replies", date),{
                    replier: user?.displayName,
                    reply: event?.target?.bc_comment?.value,
                    dateReplied: date,
                    replier_uid: user?.uid,
                    
                });

                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ bookclubCommentCreatorID_+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    comment: event?.target?.bc_comment?.value,
                    responderDisplayName: user?.displayName,
                    bookclubCommentCreatorID: bookclubCommentCreatorID_,
                    commentCreatorUID: user?.uid,
                    type: "bookclub_comment_added",
                    isRead: false
                });

                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ bookclubCommentCreatorID_+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "You have a new message!",
                        html:  user?.displayName + " added a message in your book club "+ BookClub?.bookClubName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });
    
                successNotification();
            }
          
    
            }catch(event){
                console.log("Error Adding Response: ",event );
                warningNotification();
    
            }
    
    
        }
    
    const onPressedCreateNewSectionButton = () =>{
        setNewSectionFromPressed(!isPressedCreateNewSection)

    }

    const createDiscussionSection = async(event) =>{
        event.preventDefault();
        console.log(discussionSectionName);
        const bookclubSectionCreatorID_ = uuidv4();
        try{

            const firestore = getFirestore();
            const section = await getDoc(doc(firestore,"BookClubs",clubNameId, "BookClubDiscussionSections",discussionSectionName));
            // console.log(name);
            if(discussionSectionName===null|| discussionSectionName.trim()==="" || bkSection_Description.trim()===""){
                emptyNotification();
            }else if(section.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
                setDoc(doc(firestore,"BookClubs",clubNameId,"BookClubDiscussionSections",discussionSectionName),{
                    discussionSectionName: discussionSectionName,
                    discussionSectionNameID: discussionSectionName.replace(/ /g,"").toLowerCase(),
                    creator: user?.displayName,
                    creator_uid: user?.uid,
                    dateCreated: date,
                    description: bkSection_Description
                });


                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ bookclubSectionCreatorID_+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    sectionName: discussionSectionName,
                    creatorDisplayName: user?.displayName,
                    bookclubSectionCreatorID: bookclubSectionCreatorID_,
                    sectionCreatorUID: user?.uid,
                    type: "bookclub_section_added",
                    isRead: false
                });
                
                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ bookclubSectionCreatorID_+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "A new section has been created in your book club!",
                        html:  user?.displayName + " added a new section called "+ discussionSectionName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });

                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }

    }

    const getDiscussionDiscussionSections = async() =>{
        await getDocs(collection(db, "BookClubs",clubNameId, "BookClubDiscussionSections")).then((querySnapshot)=>{
            const discussion_sections = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));

            // if(discussionboards.name!==""){
                showDiscussionSections(discussion_sections);
            // }
            
            

        });   


    }

    const clickDiscussionSection = (section_name) =>{
        navigate('/'+clubNameId+'/'+section_name,{
            state:{
                club_name: clubNameId,
                section: section_name,
                club_name_creator_email: bookClubCreatorInfo.email,
                club_name_creator_uid: BookClub.creator_uid
            }
        })


    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    // generate token to make invite link more secure
    // function generateInviteToken(){
    //     return crypto.randomBytes(32).toString("hex");
    // }

    const createInvite = async(clubNameId)=>{
        // const token = generateInviteToken();
        // const inviteRef = await collection("BookClubs").get();
        try{
        //     console.log("Invite formation!");
        //     console.log(clubNameId);
        // const inviteRef = await collection("BookClubs").doc(clubNameId).collection("Invites").add({
        //     // token,
        //     // clubNameId,
        //     createdAt: serverTimestamp(),
        //     expiresAt: Timestamp.fromDate(new Date(Date.now() + 7*24*60*60*1000)),
        //     usedBy: null
        // });

        // const inviteRef = await collection("BookClubs");
        // const invite = await inviteRef.get();
        const firestore = getFirestore();

        setDoc(doc(firestore,"BookClubs",clubNameId,"Invites", "x"),{
            uid: "x",
            createdAt: serverTimestamp(),
            expiresAt: Timestamp.fromDate(new Date(Date.now() + 7*24*60*60*1000)),
            usedBy: null
        });
    }catch(error){
        console.log(error);
    }

        return `${window.location.origin}/invite/${clubNameId}/x`;

    }

    // generate invite link
    const generateInviteLink = async()=>{
        try{
            
            
        const link = await createInvite(clubNameId);
        // console.log(link);
        // const link = `${window.location.origin}/invite/${location?.state?.id}`;
        setInviteLink(link);
        }catch(error){
            console.log('Error generating invite link.', error)
        }
    }

    const getMemberCount = async() =>{
                    
        const c = collection(firestore, "BookClubs",clubNameId, "Members");
        const count = await getCountFromServer(c);
        countMemmbers(count.data().count);
        }

    const handleCopy = () =>{
        navigator.clipboard.writeText(inviteLink);
    }

    const submitNomination = (book_id, submitter_id) =>{

        // let date = new Date();
        // date = date.toString();
        // const firestore = getFirestore();

       
        setDoc(doc(firestore,"BookClubs", clubNameId, "Nominations", book_id),{
            createdAt: serverTimestamp(),
            votes: 1,
            id: book_id,
            nominatedBy: submitter_id,
            reason: "",
            votedUsers: arrayUnion(submitter_id)


        });
  

    }

    const BookNominationSelection = ()=>(
        <div className="bg-gray-700 rounded-lg shadow-lg p-6 mt-8">
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                <div className="flex items-center gap-2">
                    <Book size={24}/>
                    <h2 className="text-2xl font-bold">Book Nominations</h2>
                    
                </div>
                <buttton
                onClick={()=>setNominationFormOpen(true)} 
                className="bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-purple-700">
                    <PlusCircle size={20}/>
                    Nominate Book
                </buttton>
            </div>
            <div className="space-y-4">
                {
                    nominations.map((nomination)=>(
                        <div
                        className="bg-white rounded-lg shadow-sm border border-gray-100 p-4">
                            <div className="flex gap-4">
                                {/* voting controls */}
                                <div className="flex flex-col items-center">
                                    <button
                                    onClick={()=>handleVote(
                                        nomination.id,
                                        nomination.votes,
                                        nomination.hasVoted,
                                        'up'
                                    )} 
                                    className={`p-1 transition-colors ${
                                        nomination.hasVoted?'text-purple-600':
                                        'text-gray-400 hover:text-purple-600'
                                    }`}>
                                        <ChevronUp size={24}/>
                                    </button>
                                    <span className="font-bold text-lg">{nomination.votes}</span>
                                    <button
                                    onClick={()=>handleVote(
                                        nomination.id,
                                        nomination.votes,
                                        nomination.hasVoted,
                                        'down'
                                    )} 
                                    className={"p-1 text-gray-400 hover:text-purple-600"}>
                                        <ChevronDown size={24}/>
                                    </button>

                                </div>
                                {/* content */}
                                <div className="flex-1">
                                    <div className="flrx justify-between items-start">
                                        <div>
                                            <h3>{nomination.book.title}</h3>
                                            <p>by {nomination.book.author}</p>
                                        </div>
                                        <span className="text-gray-700 my-2">{nomination.timestamp}</span>

                                    </div>

                                    <p  className="text-gray-700 my-2">{nomination.description}</p>
                                    <div className="flex items-center gap-1">
                                        {nomination.user.photoURL ?(
                                            <img 
                                            src={nomination.user.photoURL}
                                            className="w-6 h-6 rounded-full"
                                            />

                                        ):(
                                            <User size={16} className="text-gray-500"/>

                                        )}

                                        

                                        <span>Nominated by {nomination.user.name}</span>
                                    </div>

                                    <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                                        {nomination.book.category}
                                    </span>
                                </div>

                            </div>

                        </div>
                    ))

                }

            </div>

            {isNominationFormOpen &&(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10 ">
                    <div className="bg-white rounded-lg w-full max-w-2xl">
                        <div className="p-4 border-b flex justify-between items-center">
                            <h2 className="text-xl font-bold">Nominate A Book</h2>
                            <button
                            onClick={()=>setNominationFormOpen(false)}
                            className="text-gray-500 hover:text-gray-700">
                                <X size={24}/>
                            </button>

                        </div>
                        <div className="p-4">
                            <div className="relative">
                                <input
                                type="text"
                                placeholder="Search books.."
                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                value={searchQuery}
                                onChange={(e)=>setSearchQuery(e.target.value)}
                                />
                                <Search className="absolute left-3 top-2.5 text-gray-400" size={20}/>

                            </div>
                            <div className="mt-4 max-h-60 overflow-y-auto space-y-2">
                                {filteredBooks.map((book)=>(
                                    <div
                                    key = {book.uid}
                                    onClick={()=>setSelectedBook(book)}
                                    className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                                        selectedBook?.uid === book.uid ? 'border-purple-500 bg-purple-50':
                                        'hover:bg-gray-50'
                                    }`}>
                                        <div className="flex items-start gap-3">
                                            <div className="flex-shrink-0">
                                                <div className="w-10 h-12 bg-purple-100 rounded flex items-center justify-center">
                                                    <BookOpen className="text-purple-600" size={20} />
                                                </div>
                                            </div>
                                            <div>
                                                <h3 className="font-medium text-gray-900">{book.title}</h3>
                                                <p className="text-sm text-gray-600">by {book.author}</p>
                                                <div className="flex items-center mt-1">
                                                    <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">{book.category}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                ))}
                            </div>

                            {selectedBook && (
                                <div className="mt-4">
                               
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Why are you nominating this book? {selectedBook.title}
                                    </label>
                                    <textarea
                                    // name = "nomination_reason"
                                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    placeholder="Share why you think this book would be great for the group..."
                                    rows="3"/>

                                </div>
                            )}
                            <div className="mt-6 flex justify-end gap-3">
                                <button
                                onClick={()=>setNominationFormOpen(false)}
                                className="px-4 py-2 text-gray-900 border rounded-lg hover:bg-gray-50">
                                    Cancel
                                </button>
                                <button
                                onClick={()=>submitNomination(selectedBook.uid, user.uid)}
                                disabled={!selectedBook}
                                className="px-4 py-2 bg-purple-600 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-purple-700">
                                    Submit Nomination
                                </button>
                                

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    const fecthDiscussionsAndComments = async()=>{
        try {
            // get all discussions
            const discussionsRef = collection(db,"BookClubs", clubNameId, "BookClubDiscussionSections" );
            const discussionsQuery = query(discussionsRef, orderBy('dateCreated', 'desc'));
            const discussionsSnapshot = await getDocs(discussionsQuery);

            // fetch data for each discussion
            const discussionsData = await Promise.all(
                discussionsSnapshot.docs.map(async (discussionDoc)=>{
                    const discussionData = discussionDoc.data();

                    // get user data
                    const userDoc = await getDoc(doc(db, 'Users', discussionData.creator_uid));
                    const userData = userDoc.data();

                    // get comments for this discussion
                    const commentsRef = collection(db,"BookClubs", clubNameId, "BookClubDiscussionSections", discussionData.discussionSectionNameID,"Discussions" );
                    const commentsQuery = query(commentsRef, orderBy('dateReplied', 'desc'));
                    const commentsSnapshot = await getDocs(commentsQuery);

                    const comments = await Promise.all(
                        commentsSnapshot.docs.map(async (commentDoc)=>{
                            const commentData = commentDoc.data();

                            // get user data
                            const commentUserDoc = await getDoc(doc(db, 'Users', commentData.replier_uid ));
                            const commentUserData = commentUserDoc.data();

                            return {
                                id: commentData.discussionSectionNameID,
                                text: commentData.reply,
                                createdAt: commentData.dateReplied ,
                                user:{
                                    id:commentUserData.uid ,
                                    name: commentUserData?.name || 'Anonymous',
                                    photo:commentUserData?.photoURL || null
                                }

                            }
                        })
                    );

                    return{

                                id: discussionData.discussionSectionNameID ,
                                title: discussionData.discussionSectionName ,
                                content:discussionData.description  ,
                                createdAt: discussionData.dateCreated ,
                                user:{
                                    id:userData.uid ,
                                    email:userData.email ,
                                    name: userData?.name || 'Anonymous',
                                    photo: userData?.photoURL || null
                                },
                              
                                    comments: comments,
                                    commentsCounts: comments.length
                                

                    }

                })
            )

            setDiscussions(discussionsData);
   
        } catch (error) {
            console.error('Error fetching discussions', error)
            
        }
    }


    const onSubmitReply = async (event, discussion_id, creator_uid, creator_email) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const bookClubSectionReplyUniqueID = uuidv4();


        try{

            if(event?.target?.db_reply?.value===null|| event?.target?.db_reply?.value.trim()===""){
                emptyNotification();
            }else{

            setDoc(doc(firestore,"BookClubs",clubNameId, "BookClubDiscussionSections", discussion_id, "Discussions", date),{
                dateReplied: date,
                replier: user?.displayName,
                replier_uid: user?.uid,
                reply: event?.target?.db_reply?.value,
            });

            // setDoc(doc(firestore,"Users",creator_uid,"Notifications", creator_uid + bookClubSectionReplyUniqueID+user.uid),{
            //     DateandTime: date,
            //     bookclubname: clubNameId,
            //     bookclubsectionname: discussion_id,
            //     replierDisplayName: user?.displayName,
            //     bookClubSectionReplyID: bookClubSectionReplyUniqueID,
            //     responderUID: user?.uid,
            //     type: "bookclub_section_reply",
            //     isRead: false
            // });



            // setDoc(doc(firestore,"mail", creator_uid + bookClubSectionReplyUniqueID+user.uid),{
            //     to: creator_email,
            //     message: {
            //         subject: "You have a new message!",
            //         html:  user?.displayName + " added a new comment in your book club "+ clubNameId + " in " + discussion_id +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

            //     }
            // });

            successNotification();
        }
      

        }catch(event){
            console.log("Error Adding Response: ",event );
            warningNotification();

        }


    }





    const getBooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const allBooksData = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
            setBooks(allBooksData);

            const allCategories = new Set();

            allBooksData.forEach(book=>{

                if(Array.isArray(book.category)){
                    book.category.forEach(
                        cat=>allCategories.add(cat)
                    );
                }
  
            });



            // const categories = ["All", ...new Set(allBooks.map(book =>book.category))];


            // setAvailableCategories(Array.from(allCategories).sort())
        });
    }

    



    const filteredBooks = allBooks.filter(book=>{
       

        const matchesSearch = book?.title?.toLowerCase().includes(searchQuery?.toLowerCase())||
        book?.author?.toLowerCase().includes(searchQuery?.toLowerCase());
  

        return matchesSearch;
        
    });

    useEffect(()=>{
        // set real-time listener for nominations
        const nominationsRef = collection(db, "BookClubs", clubNameId, "Nominations");
        const q = query(nominationsRef);
        
        const unsubscribe = onSnapshot(q, async(snapshot) =>{
            try{

                const nominationsData = await Promise.all(
                    snapshot.docs.map(async(doc)=>{
                        const data = doc.data();

                        const [bookData, userData] = await Promise.all([
                            fetchBook(data.id),
                            fetchUser(data.nominatedBy)
                        ]);

                        return{
                            id: doc.id, ...data,
                            createdAt: data.createdAt?.toDate(),
                            book: bookData,
                            user: userData,
                            hasVoted: data.votedUsers?.includes(currentUserId) || false
                        }
                    })
                );

                setNominations(nominationsData)


            }catch(error){
                console.error('Error processing nominations:', error);
            }
        }, (error)=>{
            console.error('Error in nominations listener:', error);

        }
    );
        return ()=>unsubscribe();

    },[currentUserId]);

    useEffect(()=>{
        getBooks();
        getBookClub();
        getMemberCount();
        getBookClubResponses();
        getDiscussionDiscussionSections();
        checkJoinedClub();
        fecthDiscussionsAndComments();

    },[])


    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className="max-w-6xl mx-auto p-6">
            <div className="bg-gray-700 rounded-lg shadow-lg p-6 mb-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div>
                    <h1 className="text-3xl font-bold mb-2">{BookClub?.bookClubName}</h1>
                    
                </div>

                {isModerator?<Link to ={`/admin/${clubNameId}`}>
                <button className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg flex items-center gap-2">
                    <Settings size={20}/>
                    Admin Dashboard
                </button>
                </Link>:isJoined?<button className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center gap-2" onClick= {()=>onJoinBookClub()}>Already Joined <FontAwesomeIcon icon = {faBookOpen}/></button>:<button className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg flex items-center gap-2" onClick= {()=>onJoinBookClub()}>Join Club</button>

                }

                

            </div>
            <p className="text-white">{BookClub?.bookClubDescription}</p>


            {/* <button className="text-xl hover:text-gray-900 hover:underline" onClick={()=>generateInviteLink()}>Invite </button> */}
                    {inviteLink && (
                        <div className="flex items-center">
                            {/* <p>Invite Link:</p> */}
                            <input 
                            className="flex-1 w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-l-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all" 
                            type="text" value={inviteLink} readOnly />
                            <button onClick={handleCopy} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-r-md"><Copy/></button>
                        </div>
                    )}
                    {/* <p className="mt-8 text-gray-200">description...</p> */}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-gray-700 rounded-lg shadow-lg p-6">
                    <div className="flex items-center gap-2 mb-4">
                        <Book size={24}/>
                        <h2 className="text-xl font-bold">Currently Reading</h2>
                    </div>
                        <p className="text-xl mb-2">N/A</p>

                    
                </div>
                <div onClick={()=>onViewMembers(clubNameId)} className="bg-gray-700 rounded-lg shadow-lg p-6 hover:bg-gray-900 hover:text-white border border-gray-700">
                    <div className="flex items-center gap-2 mb-4 ">
                        <Users2 size={24}/>
                        <h2 className="text-xl font-bold">Members</h2>
                    </div>
                        <p className="text-xl mb-2">{bookClubMemberCount}</p>  
                </div>
                <div className="bg-gray-700 rounded-lg shadow-lg p-6">
                    <div className="flex items-center gap-2 mb-4">
                        <Calendar size={24}/>
                        <h2 className="text-xl font-bold">Next Meeting</h2>
                    </div>
                        <p className="text-xl mb-2">N/A</p> 
                        <button className="text-purple-600 text-sm hover:underline">Add to calendar</button> 
                </div>
            </div>
            <div className="bg-gray-700 rounded-lg shadow-lg p-6 mt-8">
                <div className="flex flex-col md:flex-row justify-between items-center mb-6 mt-8">
                    <div className="flex items-center gap-2">
                        <MessageSquare size={24}/>
                        <h2 className="text-2xl font-bold">Discussions</h2>
                    </div>
                    <button
                    onClick={()=>setNewSectionFromPressed(!isPressedCreateNewSection)}
                        // ()=>onPressedCreateNewSectionButton()} 
                    className="bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-purple-700">
                        <PlusCircle size={20}/>
                        New Discussion
                    </button>
                </div>
                {isPressedCreateNewSection&&(
                    <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-6">
                    <form onSubmit={createDiscussionSection}>
                    <input
                            class = "w-full border rounded px-4 py-2 mb-4"
                            id = "discussion-board-section"
                            name = "discussionsection"
                            type = "text"
                            required
                            placeholder='Enter discussion board section name'
                            onChange={(e)=>setDiscussionSectionName(e?.target?.value)}
                        />
                    
                    <textarea
                        rows={5} 
                        cols={35}
                        type="text"
                        placeholder="Enter discussion section description"
                        value={bkSection_Description}
                        onChange = {e => saveBKSectionDescription(e.target.value)}
                        className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
                        <br/>

                        <div className="flex justify-end gap-2">
                            <button
                            onClick={()=>setNewSectionFromPressed(false)} 
                            
                            className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">Cancel</button>
                            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Create Section</button>
                        </div>
                    </form>
                    </div>

                )}
                <div className="space-y-4">
                    {
                        discussions?.map((discussion)=>(
                            <div 
                            key={discussion?.id}
                            // onClick={()=>
                            //     setActiveDiscussion(discussion?.discussionSectionNameID===activeDiscussion?null: discussions?.discussionSectionNameID)
                            // }
                            className="border border-gray-200 rounded-lg p-4 hover:bg-gray-900 cursor-pointer">
                                <div className="flex items-center justify-between mb-2">
                                    <h2 className="font-semibold">{discussion?.title}</h2>
                                    <div className="flex items-center gap-4">
                                        <span className="text-gray-300 text-sm flex items-center gap-1">
                                            {discussion.comments.length}
                                           <MessageSquare size={16}/>
                                        </span>
                                        <ChevronDown
                                        onClick={()=>toggleDiscussionSection(discussion?.id)}
                                        size={20}
                                        // className={`transform transition-transform ${
                                        //     expandedDiscussion === discussions?.discussionSectionNameID ? 'roate-180' : ''
                                        // }`}
                                        />

                                    </div>
                                </div>

                                {
                                    expandedDiscussion === discussion?.id &&(
                                        <div className="mt-4 pt-4 border-t border-gray-200">
                                            <form onSubmit={(e)=>onSubmitReply(e, discussion?.id, discussion.user.id, discussion.user.email)}>
                                            <div className="mb-4">
                                                
                                                <textarea
                                                rows="3"
                                                name="db_reply"
                                                placeholder="Add your comment..."
                                                className="w-full p-5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"/>
                                            </div>
                                            <button
                                            className="bg-purple-600 text-white px-4 py-2 rounded-lg mb-4">
                                                Post Comment
                                            </button>
                                            </form>


                                            {
                                                discussion.comments.length ===0 ?
                                                <p className="text-center text-gray-500 py-4">No comments yet. Be the first to comment!</p>:(
                                            discussion.comments?.map((comment, idx)=>(
                                                <div className="flex gap-4">
                                                
                                                <div className="flex-shrink-0" onClick={()=>onViewReaderProfileButton(comment.user.id)}>
                                                    <img 
                                                    src={comment.user.photo? comment.user.photo : photoURL} 
                                                    alt="ProfilePic" 
                                                    className="h-10 w-10 rounded-full object-cover"/>     
                                                </div>
                                                <div className="flex-grow space-y-1">
                                                    <div className="flex items-center gap-2">
                                                        <span>{comment.user.name}</span>
                                                        <span>{formatTimestamp(comment.createdAt)}</span>
                                                    </div>
                                                    <div className="text-gray-200 whitespace-pre-wrap break-words">
                                                        {comment?.text}
                                                    </div>
                                                    
                                                </div> 
                                                                              
                                                
                                                </div>
                                            )
                                            ))
                                        }
                                    

                                        </div>
                                    
                                    )
                                }
                            </div>

                            
                        ))
                    }


                </div>

                {BookClubReplies?.map((responses, idx)=>(
                            <div className="flex gap-4">
                            
                            <div className="flex-shrink-0" onClick={()=>onViewReaderProfileButton(responder[idx])}>
                                <img 
                                src={responder[idx].photoURL? responder[idx].photoURL : photoURL} 
                                alt="ProfilePic" 
                                className="h-10 w-10 rounded-full object-cover"/>     
                                {/* <h2 >by {responder[idx].displayName}</h2> */}
                                {/* <h2 key={idx}>Replied on {responses?.dateReplied}</h2> */}
                            </div>
                            <div className="flex-grow space-y-1">
                                <div className="flex items-center gap-2">
                                    <span>{responder[idx].displayName}</span>
                                    <span>{formatTimestamp(responses?.dateReplied)}</span>
                                </div>
                                <div className="text-gray-200 whitespace-pre-wrap break-words">
                                    {responses?.reply}
                                </div>
                                {/* <h2 key={idx}>{responses?.reply}</h2> */}
                            </div>                               
                            
                            </div>
                        ))
                    }
            </div>

            {/* <BookNominationSelection /> */}

            <div className="bg-gray-700 rounded-lg shadow-lg p-6 mt-8">
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
                <div className="flex items-center gap-2">
                    <Book size={24}/>
                    <h2 className="text-2xl font-bold">Book Nominations</h2>
                    
                </div>
                <buttton
                onClick={()=>setNominationFormOpen(true)} 
                className="bg-purple-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-purple-700">
                    <PlusCircle size={20}/>
                    Nominate Book
                </buttton>
            </div>
            <div className="space-y-4">
                {
                    nominations.map((nomination)=>(
                        <div
                        className="bg-white rounded-lg shadow-sm border border-gray-100 p-4">
                            <div className="flex gap-4">
                                {/* voting controls */}
                                <div className="flex flex-col items-center">
                                    <button
                                    onClick={()=>handleVote(
                                        nomination.id,
                                        nomination.votes,
                                        nomination.hasVoted,
                                        'up'
                                    )} 
                                    className={`p-1 transition-colors ${
                                        nomination.hasVoted?'text-purple-600':
                                        'text-gray-400 hover:text-purple-600'
                                    }`}>
                                        <ChevronUp size={24}/>
                                    </button>
                                    <span className="font-bold text-lg">{nomination.votes}</span>
                                    <button
                                    onClick={()=>handleVote(
                                        nomination.id,
                                        nomination.votes,
                                        nomination.hasVoted,
                                        'down'
                                    )} 
                                    className={"p-1 text-gray-400 hover:text-purple-600"}>
                                        <ChevronDown size={24}/>
                                    </button>

                                </div>
                                {/* content */}
                                <div className="flex-1">
                                    <div className="flrx justify-between items-start">
                                        <div>
                                            <h3>{nomination.book.title}</h3>
                                            <p>by {nomination.book.author}</p>
                                        </div>
                                        <span className="text-gray-700 my-2">{nomination.timestamp}</span>

                                    </div>

                                    <p  className="text-gray-700 my-2">{nomination.description}</p>
                                    <div className="flex items-center gap-1">
                                        {nomination.user.photoURL ?(
                                            <img 
                                            src={nomination.user.photoURL}
                                            className="w-6 h-6 rounded-full"
                                            />

                                        ):(
                                            <User size={16} className="text-gray-500"/>

                                        )}

                                        

                                        <span>Nominated by {nomination.user.name}</span>
                                    </div>

                                    <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">
                                        {nomination.book.category}
                                    </span>
                                </div>

                            </div>

                        </div>
                    ))

                }

            </div>

            {isNominationFormOpen &&(
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10 ">
                    <div className="bg-white rounded-lg w-full max-w-2xl">
                        <div className="p-4 border-b flex justify-between items-center">
                            <h2 className="text-xl font-bold">Nominate A Book</h2>
                            <button
                            onClick={()=>setNominationFormOpen(false)}
                            className="text-gray-500 hover:text-gray-700">
                                <X size={24}/>
                            </button>

                        </div>
                        <div className="p-4">
                            <div className="relative">
                                <input
                                type="text"
                                placeholder="Search books.."
                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                value={searchQuery}
                                onChange={(e)=>setSearchQuery(e.target.value)}
                                />
                                <Search className="absolute left-3 top-2.5 text-gray-400" size={20}/>

                            </div>
                            <div className="mt-4 max-h-60 overflow-y-auto space-y-2">
                                {filteredBooks.map((book)=>(
                                    <div
                                    key = {book.uid}
                                    onClick={()=>setSelectedBook(book)}
                                    className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                                        selectedBook?.uid === book.uid ? 'border-purple-500 bg-purple-50':
                                        'hover:bg-gray-50'
                                    }`}>
                                        <div className="flex items-start gap-3">
                                            <div className="flex-shrink-0">
                                                <div className="w-10 h-12 bg-purple-100 rounded flex items-center justify-center">
                                                    <BookOpen className="text-purple-600" size={20} />
                                                </div>
                                            </div>
                                            <div>
                                                <h3 className="font-medium text-gray-900">{book.title}</h3>
                                                <p className="text-sm text-gray-600">by {book.author}</p>
                                                <div className="flex items-center mt-1">
                                                    <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded">{book.category}</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                ))}
                            </div>

                            {selectedBook && (
                                <div className="mt-4">
                               
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Why are you nominating this book? {selectedBook.title}
                                    </label>
                                    <textarea
                                    // name = "nomination_reason"
                                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    placeholder="Share why you think this book would be great for the group..."
                                    rows="3"/>

                                </div>
                            )}
                            <div className="mt-6 flex justify-end gap-3">
                                <button
                                onClick={()=>setNominationFormOpen(false)}
                                className="px-4 py-2 text-gray-900 border rounded-lg hover:bg-gray-50">
                                    Cancel
                                </button>
                                <button
                                onClick={()=>submitNomination(selectedBook.uid, user.uid)}
                                disabled={!selectedBook}
                                className="px-4 py-2 bg-purple-600 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed hover:bg-purple-700">
                                    Submit Nomination
                                </button>
                                

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>


            
                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your response has been submitted!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title ="Error"
                content = "There was an issue submitting section!"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter a discussion section name!"
            />} 

            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                  title ="Error"
                content = "Discussion Section Already Created!"
            />} 


            {/* {isPressedCreateNewSection && 
                    <div>
                    <Popup
                    handleClose = {onPressedCreateNewSectionButton}
                    content = {
                    <div>
                        <form onSubmit={createDiscussionSection}>
                        <input
                                class = "input_register"
                                id = "discussion-board-section"
                                name = "discussionsection"
                                type = "text"
                                required
                                placeholder='Discussion Board Section'
                                onChange={(e)=>setDiscussionSectionName(e?.target?.value)}
                            />
                        
                        <label  className="prof_bg_inputs">Book Club New Section Description</label>
                        <textarea
                            rows={5} 
                            cols={35}
                            type="text"
                            value={bkSection_Description}
                            onChange = {e => saveBKSectionDescription(e.target.value)}
                            className="prof_bg_inputs"></textarea>
                            <br/>
                            <button>Create Section</button>
                        </form>
                        
                    </div>
                    }
                />
                </div>} */}

        

           

            

            


            {/* <button onClick={()=>onCurrentReadsButton(clubNameId)}>Currently Reading</button>
            <button onClick={()=>onPastReadsButton(clubNameId)}>Past Reads</button>
            <button onClick={()=>onAddBookButton(clubNameId)}>Add Book +</button>
            <button onClick={()=>onPressedCreateNewSectionButton()} >Create New Section</button> */}

{/* {isJoined?<button className="button_joined_club" onClick= {()=>onJoinBookClub()}>Already Joined <FontAwesomeIcon icon = {faBookOpen}/></button>:<button onClick= {()=>onJoinBookClub()}>Join Club</button>} */}

          
            {/* <div className="horizontal-line"></div> */}
                    {/* <h2>Club Sub Discussions</h2> */}
                   
                           {/* {
                        DiscussionSections?.map((section)=>(
                            <div onClick={()=>clickDiscussionSection(section?.discussionSectionName)} className="discussion_section">
                            <h2 >{section?.discussionSectionName}</h2>
                            </div>
                        ))
                    } */}

                    

                    {/* <div className="horizontal-line"></div>
                    <h2>General Discussions</h2> */}

                    {/* <div >
                    <form className="response-format" onSubmit={onSubmitCommentButton}>
                        <textarea className="textarea-format" name="bc_comment" placeholder="Enter response..."/>
                        <button type="submit">Submit Comment</button>
                    </form>
                    </div> */}


               
                           {/* {
                        BookClubReplies?.map((responses, idx)=>(
                            <div className="flex gap-4">
                            
                            <div className="flex-shrink-0" onClick={()=>onViewReaderProfileButton(responder[idx])}>
                                <img 
                                src={responder[idx].photoURL? responder[idx].photoURL : photoURL} 
                                alt="ProfilePic" 
                                className="h-10 w-10 rounded-full object-cover"/>     

                            </div>
                            <div className="flex-grow space-y-1">
                                <div className="flex items-center gap-2">
                                    <span>{responder[idx].displayName}</span>
                                </div>
                                <div className="text-gray-700 whitespace-pre-wrap break-words">
                                    {responses?.reply}
                                </div>
                            </div>                               
                            
                            </div>
                        ))
                    } */}

                 


                
            </div>
        </section>

    );
};
export default ViewBookClub;