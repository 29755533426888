import React, {useState, useEffect, act} from 'react';
import {Calendar, PlusCircle, BarChart2, Award, CheckSquare, Tag, X} from 'lucide-react';
import { auth_, db, fetchNotifications } from "../../firebase";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';




const HabitTracker = () =>{
    const [habits, setHabits] = useState();
    const [activeTab, setActiveTab] = useState('dashboard');
    const [newHabit, setNewHabit] = useState({name:'', tags: [], frequency: 'daily'});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTagModalOpen, setIsTagModalOpen] = useState(false);
    const [availableTags, setAvailableTags] = useState([
        'Leadership',
        'Sales',
        'Product',
        'Personal Growth',
        'Finance'
    ]);
    const [newTag, setNewTag] = useState('');
    const [tagInput, setTagInput] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);

    // to do list related
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [taskCategories, setTaskCategories] = useState([
        'Work',
        'Personal',
        'Urgent',
        'Learning'
    ]);
    const [newTaskCategory, setNewTaskCategory] = useState('');
    const [isTaskCategoryModalOpen, setIsTaskCategoryModalOpen] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState('Medium');
    const [selectedTaskCategory, setSelectedTaskCategory] = useState('Work');

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);


    const today = new Date().toISOString().split('T')[0];

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth_, (user)=>{
            setUser(user);
            setLoading(false);
            if(user){
                fetchHabits(user.uid);
                fetchTags(user.uid);
                fetchTodos(user.uid);
                fetchTaskCategories(user.uid);
            }
        });

        return () => unsubscribe();

    },[]);

    // fetch habits from firestore
    const fetchHabits = async (userId) =>{

    }

    // fetch tags from firestore
    const fetchTags = async (userId) =>{
        
    }

    // fetch task categories from firestore
    const fetchTaskCategories = async (userId) =>{
        
    }

    // fetch todos from firestore
    const fetchTodos = async (userId) =>{
        
    }


    // add a new tag
    const addTag = async () =>{
        
    }

    // add a new task category
    const addTaskCategory = async () =>{
        
    }

    // add tag to habit being created
    const addTagToHabit = async () =>{
        
    }

    // remove tag from habit being created
    const removeTagFromHabit = async () =>{
        
    }

    // filter habits by selected tags
    const filteredHabits = selectedTags.length > 0 ?
    habits.filter(habit => {
        return selectedTags.some(tag => habit.tags.includes(tag));
    }) : habits;

    // toggle tag selection for filtering
    const toggleTagSelection = (tag) =>{

    }

    // calculate overall stats
    const totalCompletions = filteredHabits ? filteredHabits.reduce((sum, habit)=>{
        return sum + (habit.completedDates ? habit.completedDates.filter(date=>{

        }).length : 0) ;

    },0) : 0 ;

    // const totalPossibleCompletions = filteredHabits ? filteredHabits.length *7:0;
    const totalPossibleCompletions = 0;

    const completionRate = totalPossibleCompletions > 0 ?
    Math.round((totalCompletions/totalPossibleCompletions)*100):
    0;

    const longestStreak = filteredHabits?.length > 0 ?
    Math.max(...filteredHabits.map(habit=> habit.streak || 0))
    :0;

    // toggle habit completion for today
    const toggleHabitCompletion = async()=>{

    }

    // add new habit to firestore
    const addHabit = ()=>{

    }

      // add a new todo to firestore
      const addTodo = ()=>{
        
      }

    //   toggle todo completion
      const toggleTodoCompletion = async (id) =>{

      }

    //   delete a habut 
      const deleteHabit = async (id)=>{

      }

       //   delete a todo 
       const deleteTodo = async (id)=>{

       }

    //    count habits by tag
    // const habitsByTag = filteredHabits.reduce((acc, habit)=>{
    //     habit.tags.forEach(tag=>{
    //         acc[tag] = (acc[tag] || 0) + 1;
    //     });

    //     return acc;

    // },{});


    // get completion data from last 7 days 
    const last7Days = Array(7).fill().map((_,i)=>{

    }).reverse();

    const completionsByDay  = last7Days.map(date =>{

    });











    if(loading){
        return(
            <div className='flex justify-center items-center h-screen bg-gray-50'>
                <div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-600'></div>
            </div>

        );
    }
    
 
    return(
        <section className='bg-gray-900'>
            {/* <div className='min-h-screen'> */}
                <div className='max-w-6xl mx-auto p-3 md:p-6'>
                    {/* tab navigation */}
                    <div className='flex mb-6 border-b overflow-x-auto'>
                        <button
                            className={`p-2 flex-shrink-0 ${activeTab === 'dashboard'? 'border-b-2 border-blue-600 text-blue-600 font-medium':'text-white hover:text-gray-100'}`}
                            onClick={()=>setActiveTab('dashboard')}
                        >
                            <span className='flex items-center'>
                            <BarChart2 size={18} className='mr-2'/>
                            Dashboard
                            </span>
                        </button>

                        <button
                            className={`p-2 flex-shrink-0 ${activeTab === 'habits'? 'border-b-2 border-blue-600 text-blue-600 font-medium':'text-white hover:text-gray-100'}`}
                            onClick={()=>setActiveTab('habits')}
                        >
                            <span className='flex items-center'>
                            <CheckSquare size={18} className='mr-2'/>
                            Habits
                            </span>
                        </button>


                        <button
                            className={`p-2 flex-shrink-0 ${activeTab === 'tags'? 'border-b-2 border-blue-600 text-blue-600 font-medium':'text-white hover:text-gray-100'}`}
                            onClick={()=>setActiveTab('tags')}
                        >
                            <span className='flex items-center'>
                            <Tag size={18} className='mr-2'/>
                            Tags
                            </span>
                        </button>

                        <button
                            className={`p-2 flex-shrink-0 ${activeTab === 'todos'? 'border-b-2 border-blue-600 text-blue-600 font-medium':'text-white hover:text-gray-100'}`}
                            onClick={()=>setActiveTab('todos')}
                        >
                            <span className='flex items-center'>
                            <CheckSquare size={18} className='mr-2'/>
                            Today's Tasks
                            </span>
                        </button>
                    </div>
                    {/* tag filter section */}
                    {/* (filteredHabits.length > 0 && (

                    )) */}

                    {activeTab === 'dashboard' && (
                        <div className='space-y-6'>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                                <div className='bg-white p-4 rounded-lg shadow'>
                                    <h2 className='text-lg font-semibold text-gray-700'>Completion Rate</h2>
                                    <div className='mt-2 flex items-center'>
                                        <div className='w-16 h-16 rounded-full border-4 border-blue-500 flex items-center justify-center'>
                                            <span className='text-xl text-gray-500'>{completionRate}%</span>
                                        </div>
                                        <div className='ml-4'>
                                            <p className='text-sm text-gray-500'>{totalCompletions} of {totalPossibleCompletions} in the last 7 days</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='bg-white p-4 rounded-lg shadow'>
                                    <h2 className='text-lg font-semibold text-gray-700'>Longest Streak</h2>
                                    <div className='mt-2 flex items-center'>
                                        <Award size={36} className='text-yellow-500'/>
                                        <span className='ml-2 text-3xl font-bold'>{longestStreak} days</span>
                                    </div>
                                </div>
{/* 
                                <div className='bh-white p-4 rounded-lg shadow'>
                                    <h2 className='text-lg font-semibold text-gray-700'>Habits By Tag</h2>
                                    <div className='mt-2 space-y-2 max-h-32 overflow-y-auto'>
                                        {Object.entries(habitsByTag).length>0 ?(
                                            Object.entries(habitsByTag).map(([tag,count])=>(
                                                <div key={tag} className='flex justify-between'>
                                                    <span className='text-sm'>{tag}</span>
                                                    <span className='font-semibold'>{count}</span>
                                                </div>
                                            ))


                                        ):(
                                            <p className='text-sm text-gray-500'>No habits created yet</p>
                                        )}

                                    </div>
                                </div> */}

                            </div>

                            <div>

                            </div>
                        </div>
                    )}

                    {activeTab === 'todos' && (
                        <div className='space-y-4'>
                            <div className='flex justify-between items-center'>
                                <h2 className='text-xl font-semibold text-white'>Today's Task</h2>
                                <p className='text-sm text-gray-500'>{new Date().toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</p>
                            </div>

                            <div className='bg-white rounded-lg shadow p-4'>
                                <div className='flex flex-col space-y-3 mb-4'>
                                    <input
                                        type="text"
                                        value={newTodo}
                                        onChange={(e) => setNewTodo(e.target.value)}
                                        onKeyPress={(e)=>e.key === 'Enter' && addTodo()}
                                        placeholder='Add a new task for today..'
                                        className='w-full p-2 bg-white text-black border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
                                    />

                                    <div className='flex flex-wrap gap-2'>
                                        <div className='flex-1 min-w-[150px]'>
                                            <label className='block text-xs text-gray-500 mb-1'>Priority</label>
                                            <select
                                                value={selectedPriority}
                                                onChange={(e)=>setSelectedPriority(e.target.value)}
                                                className='w-full p-2 bg-gray-50 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
                                            >
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                                <option>Critical</option>
                                            </select>
                                        </div>

                                        <div className='flex-1 min-w-[150px]'>
                                            <label className='block text-xs text-gray-500 mb-1'>Category</label>
                                            <div className='flex'>
                                                <select>

                                                </select>
                                                <button>
                                                    +
                                                </button>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    )}

                </div>

            {/* </div> */}

        </section>
    );
}

export default HabitTracker;