import React, {useState, useEffect} from 'react';
import image_blog1 from '../../images/adeolu-eletu-E7RLgUjjazc-unsplash.jpg';
// import ReactGA from 'react-ga';
import { useNavigate, useLocation, useParams} from 'react-router-dom';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookBookmark, faMagnifyingGlass, faPencil, faPeopleGroup, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';
import {db, auth_} from './../../firebase';
import {Helmet} from "react-helmet";
import Popup from '../Popup/Popup';
import {EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,  TwitterShareButton,TwitterIcon,  WhatsappShareButton, WhatsappIcon} from "react-share";
import AdsComponent from './../../components/AdComponents';
import { Bookmark, Heart, Send } from 'lucide-react';


const Story1 = () =>{
    // ReactGA.initialize('');
    const analytics = getAnalytics();
    const firestore = getFirestore();
    const navigate = useNavigate();

    const {title} = useParams();
    const shareURL = "https://entrepreneurshipbooksapp.com/blogs/"+title

    var blogID = title.substring(title.lastIndexOf("-")+1);
    // console.log("blogID: "+ blogID);



    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;


    const [BlogInfo, showBlogInfo] = useState([]);
    const [BlogPostComments, showBlogPostComments] = useState([]);
    const [author, getAuthorInfo] = useState([]);


    const [replier, getReplierInfo] = useState([]);




    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const repliesArray = [];
    const replierArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    const onLikeButton =()=>{
        const date = new Date;
        const likeUniqueID = uuidv4();

        if(user){
            setDoc(doc(firestore,"Blogs", blogID, "Likes", user?.uid),{
                date: date,
                displayName: user?.displayName,
                uid: user?.uid,
                email: user?.email,
            });
    
    
            setDoc(doc(firestore,"mail", BlogInfo?.creator_uid + likeUniqueID+user.uid),{
                to: author?.email,
                message: {
                    subject: "You have a new like on your blog!",
                    html:  user?.displayName + " liked on your blog! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                }
            });

        }else{
            navigate("/login");  
        }

    }

    const onSaveBlogButton =()=>{
        const date = new Date;
        if(user){
            setDoc(doc(firestore,"Users", user?.uid, "EBA_Blogs", blogID),{
                dateSaved: date,
                blog_id: blogID
            });     

        }else{
            navigate("/login");
        
        }
        
    }

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const commentUniqueID = uuidv4();

        if(user){

            try{

                if(event?.target?.bp_comment?.value===null|| event?.target?.bp_comment?.value.trim()===""){
                    emptyNotification();
                }else{
    
                setDoc(doc(firestore,"Blogs", blogID, "Comments", user?.uid),{
                    date: date,
                    displayName: user?.displayName,
                    uid: user?.uid,
                    email: user?.email,
                    comment: event?.target?.bp_comment.value,
                });
    
                // setDoc(doc(firestore,"Users",BlogInfo?.uid,"Notifications", BlogInfo?.uid + commentUniqueID+user.uid),{
                //     DateandTime: date,
                //     blog_id: blogID,
                //     comment: event?.target?.bp_comment.value,
                //     commenterDisplayName: user?.displayName,
                //     commentID: commentUniqueID,
                //     commenterUID: user?.uid,
                //     type: "bp_comment",
                //     isRead: false
                // });
    
                setDoc(doc(firestore,"mail", BlogInfo?.creator_uid + commentUniqueID+user.uid),{
                    to: author?.email,
                    message: {
                        subject: "You have a new comment on your blog!",
                        html:  user?.displayName + " commented on your blog! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });
    
                successNotification();
            }
          
    
            }catch(event){
                console.log("Error Adding Response: ",event );
                warningNotification();
    
            }


            

        }else{
            navigate("/login");
        
        }


    }


    const getBlogInfo= async() =>{

        // console.log(blogID);
        // console.log(await (await getDoc(doc(db, "Blogs",blogID.trim()))).get());

        showBlogInfo( (await getDoc(doc(db, "Blogs",blogID))).data());

       const author = (await getDoc(doc(db, "Blogs",blogID))).data()["creator_uid"];

        getAuthorInfo((await getDoc(doc(db, "Users",author))).data())

    }

    const addStylesToHtml = (html) =>{
        const styleMap={
            'h1':'text-4xl font-bold mb-6',
            'h2':'text-2xl font-bold mb-4 mt-6',
            'h3':'text-xl font-bold mb-3 mt-6',
            'p':'mb-4 text-gray-200 leading-relaxed',
            'img':'w-full rounded-lg mb-8',
            'ul':'list-disc list-inside mb-6 space-y-2 ml-4',
            'li':'text-gray-300',
            'blockqoute':'border-l-4 border-gray-300 pl-4 italic my-6 text-gray-700',
            'cite':'block text-sm mt-2 text-gray-300 not-italic',
            'div':'mb-4',
        }
    

    const parser  = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html' );

    const addClassesToElement = (element) =>{
        const tagName = element.tagName.toLowerCase();
        if(styleMap[tagName]){
            element.className = styleMap[tagName];
        }

        // 

        Array.from(element.children).forEach(addClassesToElement);
    }

    Array.from(doc.body.children).forEach(addClassesToElement);

    return doc.body.innerHTML;
}

const StyledHTMLContent = ({content}) =>{
    const styledContent = addStylesToHtml(content);
    return(
        <div
        className='prose max-w-none'
            dangerouslySetInnerHTML={{ __html: styledContent}}
        />
    )

}


const formatDate = (dateValue) =>{
    if(!dateValue) return '';

    
    try{
        // if its a firebase timestamp
        if(dateValue.seconds){
            return new Date(dateValue.seconds * 1000).toLocaleDateString();
            
        }
        // if its a regular date object or timestamp number
        if(dateValue instanceof Date || typeof dateValue === 'number'){
            return new Date(dateValue).toLocaleDateString();
        }

        // if its a string
        return new Date(dateValue).toLocaleDateString();



    }catch(error){
        console.error("Error formatting date:", error)
        return dateValue;
    }

}





    useEffect(()=>{
        getBlogInfo();
        logEvent(analytics, "Blog Post: "+ BlogInfo.title);

    },[])

    return(
        <div className='min-h-screen bg-gray-900'>
            <Helmet>
                <title>{BlogInfo.title}</title>
                <meta name="description" content={BlogInfo.title}/>
                <meta name="keywords" content="tech, entreprenuership, artificial intelligence, blockchain, remote work, quantum computing, startup"/>
                <meta property="og:title" content={BlogInfo.title}/>
                <meta property="og:description" content={BlogInfo.title}/>
                <meta property="og:url" content="https://www.entrepreneurshipbooksapp.com/"/>
            </Helmet>
        <AdsComponent  dataAdSlot='3973055866'/>

            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title ="Success!"
                    content = "Your comment has been submitted!"
                />
                </div>}
            
            <main className='max-w-4xl mx-auto px-4 py-12'>
            <artcle>
                <div className='mb-8'>
            <h1 className='text-4xl font-bold text-gray-200 mb-4'>{BlogInfo.title}</h1>


            <div className='flex items-center justify-between'>
                <div className='flex items-center gap-3'>
                    <img 
                    src={author?.photoURL? author?.photoURL : photoURL} 
                    className='w-10 h-10 rounded-full'/>
                    <div>
                        
                        <p className='font-semibold'>{author.displayName }</p>
                        <p className='text-sm'>Posted on {formatDate(BlogInfo.dateCreated)}</p>
                    </div>
                </div>
            </div>

            {/* <div className='aspect-[2/1] w-full mb-8 rounded-lg overflow-hidden'> */}
                <img 
                    src={BlogInfo.image}
                    className='w-full rounded-lg mb-8'/>

            {/* </div> */}
            
            
            {/* <div className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: BlogInfo.content }} /> */}

            <StyledHTMLContent content={BlogInfo.content}/>

            

            

           

             

      
        <div className='flex items-center justify-between border-t border-b py-4 mb-8'>
            <div className='flex items-center space-x-6'>
                <button
                className='flex items-center space-x-2' 
                onClick={onLikeButton}>
                    <Heart />
                </button>
                <button
                className='flex items-center space-x-2' 
                onClick={onSaveBlogButton}>
                    <Bookmark />
                    Save
                </button>
            </div>
        </div>



          <p>Share:</p>
          <EmailShareButton
           url={shareURL}
           title={BlogInfo.title}>
            <EmailIcon size={32} round={true}/>
            {logEvent(analytics, "web_emailShareBlogPostButton: "+ BlogInfo.title)}
          </EmailShareButton>

          <FacebookShareButton 
          url={shareURL}
          title={BlogInfo.title}>
            <FacebookIcon size={32} round={true}/>
            {logEvent(analytics, "web_facebookShareBlogPostButton: "+ BlogInfo.title)}
          </FacebookShareButton>
       

          <TwitterShareButton 
          url={shareURL}
          title={BlogInfo.title}>
        
            <TwitterIcon size={32} round={true}/>
            {logEvent(analytics, "web_twitterShareBlogPostButton: "+ BlogInfo.title)}
          </TwitterShareButton>

          <WhatsappShareButton
           url={shareURL}
           title={BlogInfo.title}>
            <WhatsappIcon size={32} round={true}/>
            {logEvent(analytics, "web_whatsappShareBlogPostButton: "+ BlogInfo.title)}
          </WhatsappShareButton>

   

            <section className='mt-12'>
                <h2 className='text-2xl font-bold text-gray-200 mb-6'>Comments</h2>
                <form className="mb-8" 
                onSubmit={onSubmitReply}>
                    <div className='flex gap-4'>
                        <div className="flex-1">
                            <textarea 
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-400 focus:border-transparent"
                             name="bp_comment" 
                             placeholder="Enter comment..."/>
                            <button
                            className='mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2' 
                            type="submit">
                                <Send className='w-4 h-4'/>
                                Comment
                            </button>
                        </div>
                    </div>
                </form>
           

            <div className='space-y-6'>
                           {
                        BlogPostComments?.map((comment, idx)=>(
                            <div className="flex gap-4">
                                <img 
                                src={replier[idx].photoURL? replier[idx].photoURL : photoURL} 
                                alt="ProfilePic"
                                onClick={()=>onViewReaderProfileButton(replier[idx])} 
                                className="w-10 h-10 rounded-full"/> 
                            <div className='flex-1'>
                                <div className='bg-white rounded-lg p-4 shadow-sm'>
                                    <div className='flex items-center justify-between mb-2'>
                                        <span className='font-medium text-gray-900'>{replier[idx].displayName}</span>
                                        <span>{comment?.dateReplied}</span>

                                    </div>
                                </div>
                                <p className='text-gray-600'>{comment?.reply}</p>      
                                 
                            </div> 

                            </div>
                        ))
                    }

                    </div>
                </section>
                    </div>
                </artcle>
            </main>
        </div>
    );

}

export default Story1;