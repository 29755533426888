import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {query, where, collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { FirebaseError } from "firebase/app";
import FlatList from 'flatlist-react';
import { useEffect } from "react";
import Card from './../../components/Card';
import {getAuth} from "firebase/auth";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ListCard from "../../components/ListView/ListCard";



const FinishedReadingShelf = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    let user = auth_.currentUser;


    const [Books, showFinishedReadingBooks] = useState([]);


    const onRateBook = (book_name)=>{
        navigate('/rate_book',{
            state: {book_title: book_name}
        });

    }

const getFinishedReadingBooks = async() =>{

    // await getDocs(collection(db, "Users", user.uid, "Finished Book")).then((querySnapshot)=>{
    //         const book = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //         }));
    //         showFinishedReadingBooks(book);
        
    //     });
        
    const finishedBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Finished Book"));
    const finishedReadingBooks = finishedBooksSnapshot.docs.map(doc=>
        doc.data().bookTitle
    );

    if(finishedReadingBooks.length === 0){
        showFinishedReadingBooks([]);
        return;

    }

    console.log("finishedReadingBooks");
    console.log(finishedReadingBooks);

    const bookInfoQuery = query(
        collection(db, "Books"),
        where("title", "in", finishedReadingBooks)
    );

    const bookInfoSnapshot = await getDocs(bookInfoQuery);

    const matchFinishedReadingBooks = bookInfoSnapshot.docs.map(doc =>({
        id: doc.id,
        ...doc.data()

    }));

    showFinishedReadingBooks(matchFinishedReadingBooks);  


    }

    useEffect(()=>{
        getFinishedReadingBooks();
    },[])
      
        
    return (
        <section class="bg-gray-50 dark:bg-gray-900">
            <div>
                <div class=" flex flex-col  items-center px-6 py-8 mx-auto lg:py-0">
                    <h1 class="mb-6 text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Finished Reading</h1>
                </div>
                <ul class=" overflow-auto-y w-full divide-y divide-white dark:divide-white">
                    {Books?.map((book,idx)=>(
                            // <div className="card">
                                // <br/>
                                <div>
                                {/* <ListCard  image={book.bookImage}
                                title= {book.bookTitle}/> */}
                                <li class="pb-3 sm:pb-4">
                                        <div class="flex items-center space-x-4 p-4 rtl:space-x-reverse">
                                            <div class="flex-shrink-0">
                                                <img class="shadow shadow-white h-46 w-24" src={book.cover? book.cover:""}/>
                                            </div>
                                            <div class="flex-1 min-w-0">
                                                <p class="text-sm font-bold text-gray-900 dark:text-white">
                                                    {book.title} 
                                                </p>
                                                <p class="text-sm text-gray-500  dark:text-gray-400">
                                                    By {book.author} 
                                                </p>
                                            </div>
                                            {/* <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                <FontAwesomeIcon icon={faArrowRight}/>
                                            </div> */}
                            
                                        </div>
                                    </li>
                                </div>
                             
                        ))   
                    }
                </ul>
            </div>
        </section>
    );
};

export default FinishedReadingShelf;