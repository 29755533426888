import React, {useState, useEffect, act} from 'react';
import {ArrowLeft, Save, Info, HelpCircle, Check, Edit, Download} from 'lucide-react';
import {doc, getFirestore, setDoc, getDoc} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {jsPDF} from 'jspdf';
import { Placeholder } from 'react-bootstrap';

const OnePageBusinessPlan = () =>{

    const [activeSession, setActiveSession] = useState('problem');
    const [planData, setPlanData] =useState({
        'problem':'',
        'solution':'',
        'audience':'',
        'competition':'',
        'advantage':'',
        'revenue':'',
        'cost':'',
        'milestones':'',
        'lastUpdated':null,
        'planId':`plan_${Date.now()}`,
    });

    const handleChange = (section, value) =>{
        setPlanData({
            ...planData,
            [section]:value
        });
    }

    const [isSaving, setIsSaving] = useState(false);
    const [saveStatus, setSaveStatus] = useState(null);

    const auth = getAuth();
    const db = getFirestore();
    const userId = auth.currentUser?.uid;

    // load saved plan data if available
    useEffect(()=>{
        const loadData = async () => {
            if(!userId) return;

            try{
                // check if the user has a saved plan
                const userPlanRef = doc(db, 'Users', userId, 'BusinessPlans', 'onePage');
                const planSnapshot = await getDoc(userPlanRef);
                if(planSnapshot.exists()){
                    setPlanData(planSnapshot.data());
                }

            }catch(e){
                console.error('Error loading plan data')
            }
        }

        loadData();


    },[userId, db]);


    const savePlan = async() =>{
        if(!userId){
            // Handle not logged in state
            alert('Please log in to save your business plan');
            return;
        }

        setIsSaving(true);
        setSaveStatus('Saving...');

        try{
            // save to firestore
            const userPlanRef = doc(db, 'Users', userId, 'BusinessPlans', 'onePage');
            await setDoc(userPlanRef, planData);

            setSaveStatus('Saved successfully!');
            setTimeout(()=> setSaveStatus(null), 3000);

        }catch(error){
            console.error('Error saving plan');
            setSaveStatus('Error saving. Please try again');

        }finally{
            setIsSaving(false);
        }

    }


    const downloadPlan = () =>{
        const doc = new jsPDF();

        // add title
        doc.setFontSize(20);
        doc.text('One-Page Business Plan', 105, 20, {text: 'center'});

        // add content
        doc.setFontSize(12);
        let y =40;

        sections.forEach(section =>{
            // add section title
            doc.setFont(undefined, 'bold');
            doc.text(`${section.title}`, 20, y);
            y += 10;

            // add section content
            doc.setFont(undefined, 'normal');
            const content = planData[section.id] || 'Not filled in yet';

            // simple word wrapping
            const contentLines = doc.splitTextToSize(content, 170);
            doc.text(contentLines, 20, y);

            y += contentLines.length*7+15;

            // ensure we dont overflow page
            if(y>270){
                doc.addPage();
                y=20;
            }
        });

        // add footer with date
        doc.setFontSize(10);
        doc.text(`Generated on ${new Date().toLocaleDateString()}`, 105, 285, {align: 'center'});


        // download the PDF
        doc.save(`business-plan-${new Date().toLocaleDateString()}.pdf`)

    }

    const sections =[
        {
            id: 'problem',
            title: 'Problem',
            description: 'What problem are you solving?',
            placeholder:'Describe the problem your target customers are experiencing...',
            tip: 'Be specific about who has this problem and why it matters to them. A clear problem statement helps focus your business',
            examples: [
                'Small businesses waste 5+ hours weekly scheduling social media posts manually',
                'Local resturaunts lose 30% of food inventory due to poor tracking systems.'
            ]

        },
        {
            id: 'solution',
            title: 'Solution',
            description: 'How does your product/service solve this problem?',
            placeholder:'Explain your solution and how it addresses the problem..',
            tip: 'Focus on the value you provide, not just features. How does your solution make customers live better?',
            examples: [
                'Small businesses waste 5+ hours weekly scheduling social media posts manually',
                'Local resturaunts lose 30% of food inventory due to poor tracking systems.'
            ]

        },
        {
            id: 'audience',
            title: 'Target Audience',
            description: 'Who are your ideal customers?',
            placeholder:'Describe your target customers in detail..',
            tip: 'The more specific you can be about your ideal customer, the more effectively you can reach them.',
            examples: [
                'Small businesses owners (1-10 employees) who manage their own social media',
                'Independent restaurants with 20-50 seats and $300K - $1M annual revenue'
            ]

        },
        {
            id: 'market',
            title: 'Market Size',
            description: 'How big is your target market?',
            placeholder:'Estimate the size of your potential market...',
            tip: 'Include both total market size (TAM) and the segment you plan to target initially (SAM).',
            examples: [
                'TAM: 32M small businesses in US, SAM: 5.2M businesses active on social media',
                'TAM: $80B restaurant industry, SAM: $25B independent restaurant segment'
            ]

        },
        {
            id: 'competition',
            title: 'Competition',
            description: 'Who are your main competitors?',
            placeholder:'List your main competitors and how they address the problem..',
            tip: 'Don\'t say you have no competition. If no direct competitors exist, look at alternative solutions customers use.',
            examples: [
                'Direct: Hoosuite, Buffer | Indirect: Virtual assistants, manual posting',
                'Direcr: Toast POS, Square | Indirect: Spreadsheets, pen and paper tracking'
            ]

        },
        {
            id: 'advantage',
            title: 'Competitive Advantage',
            description: 'What makes your solution better or unique?',
            placeholder:'Explain your unique value proposition..',
            tip: 'Your advantage could be technology, experience, cost, convenience, or any combination of factors.',
            examples: [
                'Our AI reduces scheduling time by 80% at half the cost of competitors',
                'Only solution with real-time waste alerts and vendor integration'
            ]

        },
        {
            id: 'revenue',
            title: 'Revenue Model',
            description: 'How will you make money?',
            placeholder:'Describe your pricing and revenue streams..',
            tip: 'Include pricing strategy, paymen frequency, and additional revenue opportunities',
            examples: [
                'SaaS subscription: $29/month per busines, with premium tier at $49/month',
                'Monthly subscription: $99/mo plus 0.5% of processed inventory value'
            ]

        },
        {
            id: 'costs',
            title: 'Key Costs',
            description: 'What are your main expenses?',
            placeholder:'List your most significant costs...',
            tip: 'Include both one-time startup costs and ongoing operational expenses.',
            examples: [
                'Development: $40k, Marketing: $2k/mo, Server costs: $300/mo, AI API: $0.05/use',
                'App development: $100k, Hardware: $200/customer, Support: $3k/mo'
            ]

        },
        {
            id: 'milestones',
            title: 'Key Milestones',
            description: 'What are your next steps and goals?',
            placeholder:'List your 3-6 month goals and milestones..',
            tip: 'Focus on specific, measurable goals with timeframes to keep your business on track.',
            examples: [
                'Month 1: Beta with 10 testers, Month 3: 50 paying customers, Month 6: $5k MRR',
                'Month 2: Complete MVP,  Month 4: First 20 paying customers, Month 5: Break-even'
            ]

        },

    ];

    return(
        <section className='bg-gray-900'>
        <div className='max-w-6xl mx-auto p-3 md:p-6 '>
            {/* Header */}
            
            <div className='mt-50 flex items-center justify-between mb-6'>
                <div className='flex items-center'>
                    <h1 className='text-xl md:text-2xl font-bold'>One-Page Business Plan</h1>
                </div>
                <div className='flex gap-2'> 
                    <button
                    onClick={downloadPlan}
                    className='flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounde-lg hover:bg-blue-700 text-sm md:px-4 disabled:bg-blue-400'
                    >
                        <Download className='h4 w4'/>
                        <span className='hidden md:inline'>Download PDF</span>
                    </button>
                    <button
                        onClick={savePlan}
                        disabled={isSaving}
                        className='flex items-center gap-2 bg-blue-600 text-white px-3 py-2 rounde-lg hover:bg-blue-700 text-sm md:px-4 disabled:bg-blue-400'
                    >
                        <Save className='h4 w4'/>
                        <span className='hidden md:inline'>{isSaving? 'Saving...' : 'Save Plan'}</span>
                    </button>

                </div>

            </div>

            {/* Save status message */}
            {saveStatus && (
                <div className={`mb-4 p-2 rounded-lg text-sm text-center ${
                    saveStatus.includes('Error')?
                    'bg-red-100 text-red-700':
                    'bg-green-100 text-green-700'
                }`}>
                    {saveStatus}
                </div>
            )}

            {/* main content */}
            <div className='flex flex-col md:flex-row gap-6'>
                {/* left navigation - goes to top on mobile */}
                <div className='w-full md:w-64 order-2 md:order-1'>
                    <div className='bg-white rounded-lg border p-2 nd:p-3 md:sticky md:top-6'>
                        {sections.map((section)=>(
                            <button
                                onClick={()=>setActiveSession(section.id)}
                                className={`w-full text-left p-3 rounded-lg flex items-center justify-between mb-1 ${
                                    activeSession === section.id?
                                    'bg-blue-50 text-blue-700 font-medium':
                                    'hover:bg-gray-50'
                                }`}
                                key ={section.id}
                            >
                                <span>{section.title}</span>
                                {planData[section.id]?(
                                    <Check className='w-4 h-4 text-green-500'/>):(
                                    <Edit className='w-4 h-4 text-gray-400'/>
                                )}
                            </button>
                        ))}
                        <div className='p-3 mt-4 bg-blue-50 rounded-lg'>
                            <div className='flex items-start gap-2 '>
                                <Info className='w-5 h-5 text-blue-600 mt-0.5'/>
                            </div>
                            <div className='text-sm text-blue-800'>
                                <span className='font-medium block mb-1'>Why One Page?</span>
                                <p className='text-xs'>
                                    A one-page plan forces clarity and focus. It helps you identify the most essential elements of yuor business and communicate them effectively to partners, investors, and/or team members.
                                </p>

                            </div>
                        </div>
                    </div>
                    {planData.lastUpdated &&(
                        <div className='text-xs text-center text-gray-500 mt-4'>
                            Last Saved: {new Date(planData.lastUpdated.toDate?planData.lastUpdated.toDate():planData.lastUpdated ).toLocaleString()}
                        </div>
                    )}

                </div>

            {/* right content */}
            <div className='flex-grow order-1 md:order-2'>
                <div className='bg-white rounded-lg border p-4 md:p-6'>
                    {sections.map((section)=>(
                        section.id === activeSession && (
                            <div 
                            className=''
                            key={section.id}>
                                <h2 className='text-lg md:text-xl font-semibold mb-2'>
                                    {section.title}
                                </h2>
                                <p className='text-gray-600 mb-4'>
                                    {section.description}
                                </p>

                                <div className='mb-4'>
                                    <textarea
                                    className='w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white'
                                    placeholder={section.placeholder}
                                    value={planData[section.id]}
                                    onChange={(e) => handleChange(section.id, e.target.value)}
                                    ></textarea>
                                </div>

                                <div className='bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6'>
                                    <div className='flex items-start gap-2'>
                                        <HelpCircle className='w-5 h-5 text-yellow-600 mt-0.5'/>
                                        <div>
                                            <h3 className='font-medium text-yellow-800 mb-1'>Tip</h3>
                                            <p className='text-sm text-yellow-700 mb-2'>
                                                {section.tip}
                                            </p>

                                            <h4 className='font-medium text-yellow-800 text-sm mt-3 mb-1'>Examples</h4>
                                            <ul className='space-y-1'>
                                                {
                                                    section.examples.map((example, index)=>(
                                                        <li key={index} className='text-xs text-yellow-700'>* {example}</li>
                                                    ))

                                                }
                                            </ul>

                                        </div>
                                    </div>
                                </div>


                                <div className='flex justify-between'>
                                    {sections.findIndex(s => s.id === activeSession)>0 && (
                                        <button
                                        onClick={() => setActiveSession(sections[sections.findIndex(s=>s.id===activeSession)-1].id)}
                                        className='px-4 py-2 border rounded-lg hover:bg-gray-50'
                                        >
                                            Previous
                                        </button>
                                    )}
                                    <div></div> {/* spacer for flex justify between*/ }

                                    {sections.findIndex(s => s.id === activeSession)< sections.length-1 && (
                                        <button
                                        onClick={() => setActiveSession(sections[sections.findIndex(s=>s.id===activeSession)+1].id)}
                                        className='px-4 py-2 border rounded-lg hover:bg-gray-50'
                                        >
                                            Next
                                        </button>
                                    )}

                                </div>

                            </div>

                        )
                    ))}
                </div>
            </div>


            </div>

            {/* progress */}
            <div className='mt-6 bg-gray-100 h-2 rounded-full'>
                <div className='bg-blue-600 h-full rounded-full' style={{width: `${(sections.findIndex(s=>s.id === activeSession)+1)/sections.length*100}%`}}></div>
            </div>


        </div>
        </section>
    );




}

export default OnePageBusinessPlan;