import React, {useState, useEffect, useRef} from 'react';
import {Bold, Italic, List, 
    Heading1, Heading2, Link, 
    Image, Quote, Table, Code, 
    X, Plus, Menu, Users, TrendingUp, 
    BookOpen, MessageSquare, Award, Rocket,
DollarSign, ShoppingCart, Box, Shield, 
UserPlus, AlertTriangle, Tool} from 'lucide-react';
import ReactQuill from 'react-quill'; //using version 1.3.5. Getting errors with 2.0.0
import 'react-quill/dist/quill.snow.css'

import {where, collection, addDoc, updateDoc, setDoc, doc, onSnapshot, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot, serverTimestamp, query, orderBy} from 'firebase/firestore';
import {db, } from '../../firebase';
import { ref,deleteObject, uploadBytes, getDownloadURL, getStorage  } from 'firebase/storage';
import { getAuth} from "firebase/auth";
import { ArrowRight } from 'lucide-react';


const auth_ = getAuth();

const storage = getStorage();
const customStyles =`
.quill{
display: flex;
flex-direction: column;
height: 400px;
}

.quill .ql-container{
 flex: 1;
overflow: auto;
}

.quill .ql-container::-webkit-scrollbar-track{
background: #f1f1f1;
border-radius:4px;
}


.quill .ql-container::-webkit-scrollbar-thumb{
background: #888;
border-radius:4px;
}

.quill .ql-container::-webkit-scrollbar-hover{
background: #555;
}

// for firefox
.quill ql-container{
scrollbar-width: thin;
scrollbar=color: #888 #f1f1f1;
}

// editor content styles
.quill .ql-editor{
font-size: 16px;
line-height: 1.6;
}

// ensures tool bar stays on top
.quill .ql-toolbar{
border-top-left-radius: 8px;
border-top-right-radius: 8px;
border-bottom: 1px solid #ccc;
background: white;
position: sticky;
top: 0;
z-index: 1;

}


.ql-editor img:hover{
    box-shadow: 0 0 0 2px #10b981;
}



.ql-editor img{
            width: 250px;
            max-width: 100%;
            height: auto;
            display: block;
            // margin: 1em auto;
            cursor: pointer;

    }

    .ql-editor .image-container{
        position: relative;
        display: block;
        max-width: 250px;
        margin: 1em 0;
    }


    .ql-editor .image-container:hover{
        outline: 2px solid #10b981;
    }


    .delete-button{
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #ef4444;
        color: white;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: none;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s;
    }

    .image-container:hover delete-button{
        display: flex;
    }

    .delete-button:hover{
        background-color: #dc2626;
    }
`;

const entrepreneurCategories = [
    {id:'startup', name:'Startup', icon: 'Rocket'},
    {id:'funding', name:'Funding & Investment', icon: 'DollarSign'},
    {id:'marketing', name:'Marketing', icon: 'TrendingUp'},
    {id:'sales', name:'Sales', icon: 'ShoppingCart'},
    {id:'product', name:'Product Management', icon: 'Box'},
    {id:'growth', name:'Growth Strategies', icon: 'TrendingUp'},
    {id:'leadership', name:'Leadership', icon: 'Users'},
    {id:'finance', name:'Finance', icon: 'DollarSign'},
    {id:'legal', name:'Legal & Compliance', icon: 'Shield'},
    {id:'hiring', name:'Team Building', icon: 'UserPlus'},
    {id:'failures', name:'Failure Stories', icon: 'AlertTriangle'},
    {id:'success', name:'Success Stories', icon: 'Award'},
    {id:'resources', name:'Tools & Resources', icon: 'Tool'},
    {id:'mentorship', name:'Mentorship', icon: 'Users'}
];



const postTemplates = {
    caseStudy: `# [Company Name] Case Study
    ## Overview
    - Industry:
    - Company Stage:
    - Key Metrics

    ## Challenge
    [Describe the main challenge]

    ## Solution
    [Explain the implemented solution]

    ## Results
    - Key Result 1:
    - Key Result 2:
    - Key Result 3:

    ## Lessons Learned
    1. 
    2. 
    3. 

    ## Pros & Cons
    ### Pros
    - 
    - 
    - 

    ### Cons
    -
    - 
    - 

    ## ROI Analysis
    - Cost:
    - Time Saved:
    - Value Generated: 

    ## Recommendation
    [Your final verdict]`,

    marketAnalysis: `
    # [Market/Industry] Analysis
    
    ## Market Overview
    - Market Size
    - Growth Rate
    - Key Players

    ## Trends
    1.
    2.
    3.

    ## Opportunities
    [Strategic recommendations]`
}

const companyStages = [
    {id: 'idea', name: 'Idea Stage'},
    {id: 'mvp', name: 'MVP'},
    {id: 'early', name: 'Early Traction'},
    {id: 'scale', name: 'Scale'}
];

// const convertContentToHTML = (content) =>{
//     if(!content) return '';

//     let html = content.replace(/^# (.*s)/gm,'<h1 class="text-3xl font-bold mb-4">$1</h1>')
//         // .replace(/^## (.*s)/gm,'<h2 className="text-xl font-bold mb-3">$1</h2>')
//         // .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
//         // .replace(/\*(.*?)\*/g, '<em>$1</em>')
//         // .replace(/^- (.*s)/gm, '<li>$1</li>')
//         // .split('\n\n');

//         return html;

// }

// const convertContentToHTML = (content) =>{
//     if(!content) return '';

//     return content
//     .replace(/^# (.*$)/gm,'<h1 class="text-2xl font-bold mb-4">$1</h1>')
//     .replace(/^## (.*$)/gm,'<h2 class="text-xl font-bold mb-3">$1</h2>')
//     .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
//     .replace(/\*(.*?)\*/g, '<em>$1</em>')
//     .replace(/^- (.*$)/gm, '<li>$1</li>')
//     .split('\n\n')
//     .map(paragraph =>{
//         if(!paragraph.startsWith('<')){
//             return `<p className="mb-4">${paragraph}</p>`
//         }
//         return paragraph;
//     })
//     .join('');
// }




const BlogPlatform = () =>{

    const templates = [
        {
            id: 'success-story',
            name: 'Success Story',
            content: `
            <h2>The Problem</h2>
            <p>Describe the challenge your customers were facing... </p>
    
            <h2>The Solution</h2>
            <p>Explain how your product/service solved this problem...</p>
    
            <h2>Key Results</h2>
            <ul>
                <li>Key metric 1</li>
                <li>Key metric 2</li>
                <li>Key metric 3</li>
            </ul>
    
            <h2>Lessons Learned</h2>
            <p>Share what you discovered along the way...</p>
            `     
        },
        {
            id:'market-analysis',
            name:'Market Analysis',
            content: `<h2>Market Analysis</h2>
            <p>Describe the current state of the market..</p>
    
            <h2>Key Trends</h2>
            <ul>
                <l1>Trend 1</li>
                <l1>Trend 2</li>
                <l1>Trend 3</li>
            </ul>
    
    
            <h2>Opportunities</h2>
            <p>Outline the main opportunities you see...</p>
    
            <h2>Challenges</h2>
            <p>Discuss potential obstacles and solutions...</p>
            `
        }
    ];

    const quillRef = useRef(null);

    const handleImageUpload=()=>{
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*');
        input.click();

        input.onchange = async () =>{
            const file = input.files[0];
            if(!file) return;

            if(file){
                
                try{
                    setUploading(true);
                    // resize image before upload
                    const resizedFile = await resizeImage(file);

                    //  create folder structure using Blog ID
                    // const filename = `${Date.now()}-${file.name}`;
                    // const storageRef = ref(storage, `Blogs/${blogId}/images/${filename}`);

                    // upload to firebase storage
                    // await uploadBytes(storageRef, resizedFile);
                    // const downloadURL = await getDownloadURL(storageRef);
                    // console.log('Download URL:', downloadURL);

                    // track uploaded image
                    // setUploadedImages(prev => [...prev,{
                    //     url: downloadURL,
                    //     path: `Blogs/${blogId}/images/${filename}`
                    // }]);

                    const reader = new FileReader();
                    reader.onload = (e) =>{

                    // insert into editor with standard size
                    const editor = quillRef.current.getEditor();
                    const range = editor.getSelection(true) || {index: editor.getLength()};
                    // if(range > 0){
                    //     editor.insertText(range.index, '\n');

                    // }
                   
                    // console.log('Inserting at index:', range.index);


                    // custom image with standard size
                    // editor.insertEmbed(range.index, 'image', downloadURL);

                    const imageHTML = `
                    <div class="image-container">
                        <img src="${e.target.result}"/>
                        <div class="delete-button">
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                                <path d="M18 6L6 18M6 6l12 12"/>
                            </svg>

                        </div>
                    </div>
                    `;

                    editor.clipboard.dangerouslyPasteHTML(range.index, imageHTML);
                    editor.setSelection(range.index +1, '\n');
                    
                    
                    // else{
                    //     console.log('No range found, inserting at end');
                    // }




                    // const reader = new FileReader();
                    // reader.onload = () => {
                    //     console.log('File read sucessfully');
                    //     const editor = quillRef.current.getEditor();
                    //     const range = editor.getSelection(true);
                    //     // const quill = quillRef.current.getEditor().getSelection();
                    //     // const range = quillRef.current.getEditor().getSelection(true);
                    //     if(range){
                    //         console.log('Inserting at index:', range.index);
                    //         editor.insertEmbed(range.index, 'image', reader.result, 'user');
                    //         // quillRef.current.getEditor().insertEmbed(range.index, 'image', reader.result);
                    //         editor.setSelection(range.index + 1)
                    //     }else{
                    //         console.log('No range found, inserting at end');

                    //     };

                    //     reader.onerror = (error) =>{
                    //         console.error('Error reading file: ', error)
                    //     }
                        
                    // }
                    // reader.readAsDataURL(file);


                    setNewPost(
                        prev =>({
                            ...prev,
                            content: editor.root.innerHTML,
                        })
                    );
                }

                    reader.readAsDataURL(resizedFile);
                    console.log("Image inserted in editor");
                


            }catch(e){
                console.log('Error uploading image:', e);
                alert('Failed to upload image');

            }finally{
                setUploading(false);
            }
        }

    }
}

// handle image deletion
const handleImageDelete = async (imagePath) =>{
    try{
        // delete from storage
        const imageRef = ref(storage, imagePath);
        await deleteObject(imageRef);

        // remove from tracking array
        setUploadedImages(prev => prev.filter(
            img => img.path !== imagePath
        ));

        // remove from editor content
        const editor = quillRef.current.getEditor();
        const content = editor.getContents();

        // find and remove image from content
        content.ops = content.ops.filter(op =>{
            if(op.insert && op.insert.image){
                return op.insert.image !== imagePath;
            }
        });

        editor.setContents(content);

    }catch(e){
        console.log('Error deleting image:', e);
        alert('Failed to delete image');
    }


}


    const modules = {
        toolbar: {
            container: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['link'],
                // ['image'],
                ['clean']
            ], 

            // handlers: {
            //     image: handleImageUpload
            // }
        }
        }
    
    
        const formats = [
            'header',
            'bold',
            'italic',
            'underline',
            'list',
            'bullet',
            'link',
            'image'
        ]


        const editorStyles = `
        .ql-editor img{
            width: 800px;
            max-width: 100%;
            height: auto;
            display: block;
            margin: 1em auto;
            cursor: pointer;

        }

        ql-editor img:hover{
            box-shadow: 0 0 0 2px #10b981;
        }
        `;


       
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [pendingPosts, setPendingPosts] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const [posts, setPosts] = useState([]);
    const [drafts, setDrafts] = useState([]);
    const [showWritePost, setShowWritePost] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [activeTemplate, setActiveTemplate] = useState('');
    const [newTag, setNewTag] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const [newPost, setNewPost] = useState({
        title:'',
        headline:'',
        content:'',
        image: null,
        categories: [],
        customTags: [],
        companyStage: '',
        metrics: {
            revenue: '',
            growth: '',
            users: '',
            fundingStage: ''
        }
    });

    const POST_STATUS = {
        DRAFT: 'draft',
        PENDING: 'pending',
        PUBLISHED: 'published',
    }


    const [blogId, setBlogId] = useState('');
    const [uploading, setUploading] = useState(false); 
    const [uploadedImages, setUploadedImages] = useState([]); //track uploaded images

    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageOptions, setShowImageOptions] = useState(false);

    const [selectedText, setSelectedText] = useState("");
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    const [showPreview, setShowPreview] = useState(false);


    // fetch both posts and drafts
    const fetchPosts = async() =>{
        try{
            const postQuery = query(
                collection(db, 'Blogs'),
                where('status', '==', POST_STATUS.PUBLISHED),
                where('creator_uid','==', auth_.currentUser.uid),
                // orderBy('dateCreated', 'desc')
            );

            const postsSnapshot = await getDocs(postQuery);
            const publishedPosts = postsSnapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }));

            console.log("publishedPosts");
            console.log(publishedPosts);

            setPosts(publishedPosts);

            // fetch drafts
            const draftsQuery = query(
                collection(db, 'Blogs'),
                where('status', '==', POST_STATUS.DRAFT),
                where('creator_uid','==', auth_.currentUser.uid),
                // orderBy('dateCreated', 'desc')
            );

            const draftsSnapshot = await getDocs(draftsQuery);
            const draftPosts = draftsSnapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }));

            setDrafts(draftPosts);


              // fetch pending posts
              const pendingQuery = query(
                collection(db, 'Blogs'),
                where('status', '==', POST_STATUS.PENDING),
                where('creator_uid','==', auth_.currentUser.uid),
                // orderBy('dateCreated', 'desc')
            );

            const pendingSnapshot = await getDocs(pendingQuery);
            const pendingPosts = pendingSnapshot.docs.map(doc=>({
                id: doc.id,
                ...doc.data()
            }));

            setPendingPosts(pendingPosts);



        }catch(e){
            console.error('Error fetching posts..', e)
        }

    }

    const handleSubmitForReview =  async () =>{
        if(!newPost.title.trim()){
            alert('Please add a title to you draft');
            return;
        }

          // check if content is empty or only contains empty HTML tags
          const strippedContent = newPost.content.replace(/<[^>]*>/g,"").trim();
          if(!strippedContent){
              alert("Please add content to your post");
              return;
          }

        try{
            if(!newPost.id){
                const docRef = await addDoc(
                    collection(db, 'Blogs'),{
                        creator_uid: auth_.currentUser.uid,
                        title: newPost.title,
                        headline: newPost.headline,
                        content: newPost.content,
                        status: POST_STATUS.PENDING,
                        categories: newPost.categories,
                        // image: imagesData,
                        dateCreated: serverTimestamp(),
                        updatedAt: serverTimestamp()

                    }
                );

                setNewPost(prev => ({...prev, id: docRef.id}));

            }else{

                await updateDoc(doc(db, 'Blogs', newPost.id),{
                    creator_uid: auth_.currentUser.uid,
                        title: newPost.title,
                        headline: newPost.headline,
                        content: newPost.content,
                        status: POST_STATUS.PENDING,
                        categories: newPost.categories,
                        // image: imagesData,
                        dateCreated: serverTimestamp(),
                        updatedAt: serverTimestamp()

                });



            }

            alert('Your post has been submitted for review!');
      

        }catch(e){
            console.error('Error submitting blog:', e);
            alert('Failed to save blog');


        }

    }


    const handleSaveDraft = async () =>{

        if(!newPost.title.trim()){
            alert('Please add a title to you draft');
            return;
        }

        try{
            if(!newPost.id){
                const docRef = await addDoc(
                    collection(db, 'Blogs'),{
                        creator_uid: auth_.currentUser.uid,
                        title: newPost.title,
                        headline: newPost.headline,
                        content: newPost.content,
                        status: POST_STATUS.DRAFT,
                        categories: newPost.categories,
                        // image: imagesData,
                        dateCreated: serverTimestamp(),
                        updatedAt: serverTimestamp()

                    }
                );

                setNewPost(prev => ({...prev, id: docRef.id}));

            }else{

                await updateDoc(doc(db, 'Blogs', newPost.id),{
                    creator_uid: auth_.currentUser.uid,
                        title: newPost.title,
                        headline: newPost.headline,
                        content: newPost.content,
                        status: POST_STATUS.DRAFT,
                        categories: newPost.categories,
                        // image: imagesData,
                        dateCreated: serverTimestamp(),
                        updatedAt: serverTimestamp()

                });



            }

            alert('Draft saved successfully');
            await fetchPosts();

        }catch(e){
            console.error('Error saving draft:', e);
            alert('Failed to save draft');


        }
        
    }


    // generate blogId
    useEffect(()=>{
        // const timestamp = Date.now();
        // const randomStr = Math.random().toString(36).substring(2,8);
        // const newBlogId = `blog-${timestamp}=${randomStr}`;
        // setBlogId(newBlogId);

        // set up image click handler
        if(quillRef.current){

            const editor = quillRef.current.getEditor();
            const editorContainer = editor.root;

            const handleImageDelete = async (imageContainer, imageURL) =>{
                try{
                    imageContainer.remove();

                    const newContent = editor.root.innerHTML;
                    setNewPost(prev=>({
                        ...prev,
                        content: newContent
                    }));

                    const imagePath = uploadedImages.find(img => img.url === imageURL)?.path;
                    if(imagePath){
                        const imageRef = ref(storage, imagePath);
                        await deleteObject(imageRef);
                        setUploadedImages(prev => prev.filter(img=>img.url !==imageURL));
                    }

                }catch(e){
                    console.error('Error deleting image');
                }

            }

















            const handleClick = (event) =>{
                if(event.target.closest('.delete-button')){
                    event.preventDefault();
                    event.stopPropagation();

                    const imageContainer = event.target.closest(".image-container");
                    const image = imageContainer.querySelector('img');
                    if(image){
                        handleImageDelete(imageContainer, image.src);
                    }

    
                }
            };
        
    
                editorContainer.addEventListener('click', handleClick);
    
                return ()=>{
                    editorContainer.removeEventListener('click', handleClick);
                }



             // handle editor image click for deletion

        // const handleImageClick = (event) =>{
        //     if(event.target.tagName === 'IMG'){
        //         const shouldDelete = window.confirm('Do you want to delete this image?');


        //         if(shouldDelete){

        //             try{

        //             const imgNode = event.target;
        //             const imgURL = imgNode.src;

        //             const delta = editor.getContents();




        //             // remove from editor
        //             // const imgIndex = editor.getIndex(imgNode);

        //             let index = 0;
        //             delta.ops.forEach((op,i)=>{
        //                 if(op.insert && op.insert.image === imgURL){
        //                     index = 1;
        //                 }

        //             });
        //             // editor.deleteText(imgIndex, 1);
        //             editor.deleteText(index, 1);


        //             const imagePath = uploadedImages.find(img => img.url === imgURL)?.path;
        //             if(imagePath){
        //                 const imageRef = ref(storage, imagePath);
        //                 deleteObject(imageRef).catch(console.error);
        //                 setUploadedImages(prev => prev.filter(img => img.url!==imgURL));

        //                 // handleImageDelete(imagePath);

        //             }
        //         }catch(e){
        //             console.error('Error deleting image:', e);
        //             alert('Failed to delete image');
        //         }
        //         }
        //     }
        // };
    

        //     editorContainer.addEventListener('click', handleImageClick);

        //     return ()=>{
        //         editorContainer.removeEventListener('click', handleImageClick);
        //     }

        }
    },[quillRef.current, uploadedImages]);


    const addStylesToHtml = (html) =>{
        const styleMap={
            'h1':'text-4xl font-bold mb-6',
            'h2':'text-2xl font-bold mb-4 mt-6',
            'h3':'text-xl font-bold mb-3 mt-6',
            'p':'mb-4 text-gray-200 leading-relaxed',
            'img':'w-full rounded-lg mb-8',
            'ul':'list-disc list-inside mb-6 space-y-2 ml-4',
            'li':'text-gray-300',
            'blockqoute':'border-l-4 border-gray-300 pl-4 italic my-6 text-gray-700',
            'cite':'block text-sm mt-2 text-gray-300 not-italic',
            'div':'mb-4',
        }


    const parser  = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html' );

    const addClassesToElement = (element) =>{
        const tagName = element.tagName.toLowerCase();
        if(styleMap[tagName]){
            element.className = styleMap[tagName];
        }

        // 

        Array.from(element.children).forEach(addClassesToElement);
    }

    Array.from(doc.body.children).forEach(addClassesToElement);

    return doc.body.innerHTML;
    }

    // image resize function
    const resizeImage = (file) =>{
        return new Promise((resolve)=>{
            const reader = new FileReader();
            // reader.readAsDataURL(file);

            reader.onload = (event) =>{
                const img = new window.Image();
                // img.src = event.target.result;
                img.onload = () =>{
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 600; // standard width 
                    const MAX_HEIGHT = 400; // standard height

                    let width = img.width;
                    let height = img.height;

                    // calcuate new dimensions
                    if(width>height){
                        if(width > MAX_WIDTH){
                            height *= MAX_WIDTH/width;
                            width = MAX_WIDTH;
                        }
                    }else{
                        if(height>MAX_HEIGHT){
                            width *= MAX_HEIGHT/height;
                            height = MAX_HEIGHT;

                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0,0,width, height);

                    // convert to blob
                    canvas.toBlob((blob)=>{
                        resolve(new File(
                            [blob], file.name,{
                                type: 'image/jpeg',
                                lastModified: Date.now()
                                
                            }));       
                    },
                'image/jpeg',.9); //90% quality JPEG

                };
                img.src = event.target.result;
            };

            reader.readAsDataURL(file);

        });
    };


    



    

    // const handleTextSelect = (e) =>{
    //     const textarea = e.target;
    //     setSelectedText(textarea.value.substring(textarea.selectionStart, textarea.selectionEnd));
    //     setSelectionStart(textarea.selectionStart);
    //     setSelectionEnd(textarea.selectionEnd);

    // }

    // const handleImageUpload = (e) =>{
    //     const file = e.target.files[0];
    //     if(file){
    //         const reader = new FileReader();
    //         reader.onload = (e) =>{
    //             const imageURL = e.target.result;
    //             setNewPost(prev => ({
    //                 ...prev,
    //                 images: [...prev.images, imageURL],
    //                 content: prev.content + `\n![${file.name}](${imageURL})\n`
    //             }));
    //         }
    //         reader.readAsDataURL(file);
    //     }

    // }

    // const insertTable = ()=>{
    //     const tableTemplate = `
    //     | Header 1 | Header 2 | Header 3 |
    //     |----------|----------|----------|
    //     | Row 1    |  Cell 2  | Cell 3   |
    //     | Row 2    |  Cell 2  | Cell 3   |
    //     `;

    //     const newContent = newPost.content + tableTemplate;
    //     setNewPost({...newPost, content: newContent});

    // }

    // const applyFormatting = (format) =>{
    //     const textarea = document.getElementById('story-content');
    //     const content = textarea.value;
    //     let formattedText = '';

    //     switch(format){
    //         case 'bold':
    //             formattedText = `**${selectedText}**`;
    //             break;
    //         case 'italic':
    //             formattedText = `*${selectedText}*`;
    //             break;
    //         case 'h1':
    //             formattedText = `#${selectedText}`;
    //             break;
    //         case 'h2':
    //             formattedText = `##${selectedText}`;
    //             break;
    //         case 'qoute':
    //             formattedText = `>${selectedText}`;
    //             break;
    //         case 'list':
    //             formattedText = selectedText.split('\n').map(line => `- ${line}`).join('\n');
    //             break;
    //         case 'code':
    //             formattedText = `\`\`\`\n${selectedText}\n\`\`\``;
    //             break;
    //         default:
    //             return;
    //     }

    //     const newContent = content.substring(0, selectionStart) + formattedText + content.substring(selectionEnd);
    //     setNewPost({...newPost, content: newContent})

    // }

    const formatDate = (dateValue) =>{
        if(!dateValue) return '';
    
        
        try{
            // if its a firebase timestamp
            if(dateValue.seconds){
                return new Date(dateValue.seconds * 1000).toLocaleDateString();
                
            }
            // if its a regular date object or timestamp number
            if(dateValue instanceof Date || typeof dateValue === 'number'){
                return new Date(dateValue).toLocaleDateString();
            }
    
            // if its a string
            return new Date(dateValue).toLocaleDateString();
    
    
    
        }catch(error){
            console.error("Error formatting date:", error)
            return dateValue;
        }
    
    }

    const handleTemplateSelect = (template) =>{

        setSelectedTemplate(template);
        console.log("template selected:");
        console.log(template.content);
        console.log("------");
        setEditorContent(template.content);

        setNewPost(prev =>({
            ...prev,
            content: template.content
        }));

        // if(editorRef.current){
        //     editorRef.current.setContent(template.content);

        // }
    }

    const handleEditorChange= (content)=>{
        setEditorContent(content);
        setNewPost(prev=>
            ({...prev, content: content})
        )


    }


    // const handleTemplateSelect = (templateType) =>{
    //     setNewPost(prev =>({
    //         ...prev,
    //         content: postTemplates[templateType]
    //     }));
    //     setActiveTemplate(templateType);

    // }

    const handleCategoryToggle = (categoryId) =>{
        setNewPost(prev => ({
            ...prev,
            categories: prev.categories.includes(categoryId)?
            prev.categories.filter(id => id !== categoryId):
            [...prev.categories, categoryId]
        }));
        
    }

    const handleAddCustomTag = (e) =>{
        e.preventDefault();
        if(newTag.trim() && !newPost.customTags.includes(newTag.trim())){
            if(newPost.customTags.length <5){
                setNewPost(prev =>({
                    ...prev,
                    customTags: [...prev.customTags, newTag.trim()]
                }));
                setNewTag('');
            }

        }
        
    }

    const handleSubmitPost = async (e) =>{
        e.preventDefault();

        // check if title is empty
        if(!newPost.title.trim()){
            alert("Please add a title to your blog");
            return;
        }

        // check if content is empty or only contains empty HTML tags
        const strippedContent = newPost.content.replace(/<[^>]*>/g,"").trim();
        if(!strippedContent){
            alert("Please add content to your post");
            return;
        }

        try{
            setUploading(true);
            // console.log("IMAGES");
            const imagesData = uploadedImages.map(img=>({
                url: img.url,
                path: img.path
            }));

            console.log(imagesData.url);

            // create post document
            const postData = {
                // blog_id: blogId,
                creator_uid: auth_.currentUser.uid,
                title: newPost.title,
                headline: newPost.headline,
                // content: editorContent,
                content: newPost.content,
                categories: newPost.categories,
                // image: imagesData,
                dateCreated: serverTimestamp(),
                updatedAt: serverTimestamp()

            };

            // add to firestore
            // const docRef = await addDoc(collection(db, 'Blogs'), postData);

            const docRef = await addDoc(collection(db, 'Blogs'), {
                creator_uid: auth_.currentUser.uid,
                title: newPost.title,
                headline: newPost.headline,
                // content: editorContent,
                content: '',
                status: 'published',
                categories: newPost.categories,
                // image: imagesData,
                dateCreated: serverTimestamp(),
                updatedAt: serverTimestamp()

            });


            console.log('Post added ID: ', docRef.id);
            // setBlogId(docRef.id);
            const {content, headlineImage} = await processImagesWithDocId(newPost.content, docRef.id);

            console.log(content);
            console.log(headlineImage);

            await updateDoc(docRef,
                            {
                                blog_id: docRef.id,
                                content: content,
                                image: headlineImage
                            }
                        );

            console.log(editorContent);

            // reset form
            setNewPost({
                title: '',
                headline: '',
                content: '',
                categories: [],
                image: null,
                status: 'draft'
            });

            setEditorContent('');
            setUploadedImages([]);
            setEditorContent(false);


            alert("Post published sucessfully");

        }catch(e){
            console.error('Error adding post:', e);
            alert("Failed to publish post");

        }finally{
            setUploading(false);
        }  
    }

    

    const processImagesWithDocId = async (content, docId)=>{
        try{
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const imageList = Array.from(doc.getElementsByTagName('img'));
            let headlineImage = null;

            // process each image
            for (let i=0; i< imageList.length; i++){
                const img = imageList[i];
                const imageURL = img.getAttribute('src');
                console.log("1");
                // console.log(imageURL);

                if(imageURL.startsWith('data:')){
                    console.log("2");

                    try{

                    // convert data URL to blob
                    const response = await fetch(imageURL);
                    const blob = await response.blob();
                    console.log("3");

                    // create a new filename
                    const filename = `${Date.now()}-${Math.random().toString(36).substring(7)}.jpg`;

                    console.log("4");
                    const storageRef = ref(storage, `Blogs/${docId}/images/${filename}`);
                    // create reference with docId in path
                    await uploadBytes(storageRef, blob);
                    const downloadURL = await getDownloadURL(storageRef);

                    // replace the src in the image
                    img.src = downloadURL;
                    console.log("5");

                    // if this is the first image set it as a headline
                    if(i===0 ){
                        headlineImage = downloadURL;
                    }
                }catch(e){
                    console.log('Error processing images 1:',e)
                }

                }else if(i===0 ){

                    console.log("test");
                    console.log(imageURL);

                    headlineImage = imageURL;
                    
                }
            }

            return{ 
                content: doc.body.innerHTML,
                headlineImage
            };


        }catch(error){
            console.error('Error processing images',error);
            throw error;
        }

    }

    const checkAdminStatus = async()=>{
        try{
            const docRef = doc(db, 'Users', auth_.currentUser.uid);
            const docSnap = await getDoc(docRef);
            if(docSnap.exists()){
                setIsAdmin(docSnap.data().blogAdminApprover || false);

            }

        }catch(e){
            console.error("Error checking admin status:", e);
        }
    }

    useEffect(()=>{
        checkAdminStatus();

        const styleTag = document.createElement('style');
        styleTag.textContent = customStyles;
        document.head.appendChild(styleTag);
        return ()=> styleTag.remove();


    },[]);

    useEffect(()=>{
        fetchPosts();

    }, [showWritePost])

    const handleApprovePost = async (postId) => {
        try{
            await updateDoc(
                doc(db, 'Blogs', postId),{
                    status: POST_STATUS.PUBLISHED,
                    updatedAt: serverTimestamp()
                });

                fetchPosts();

                alert('Post approved and published!');

        }catch(e){
            console.error('Error approving blog:', e);
            alert('Failed to approve blog');
        }

    }

    const handleRejectPost = async (postId) => {
        const reason = prompt('Please provide a reason for rejection');

        if(reason){
            try{
                await updateDoc(
                    doc(db, 'Blogs', postId),{
                        status: POST_STATUS.DRAFT,
                        rejectionReason: reason,
                        updatedAt: serverTimestamp()
                    });

                    fetchPosts();
    
                    alert('Post rejected and returned to drafts!');
    
            }catch(e){
                console.error('Error rejecting blog:', e);
                alert('Failed to reject blog');
            }

        }
        

    }

  



    return(
        <div className='min-h-screen bg-gray-900'>

            {/* <style>{editorStyles}</style> */}

            

            {/* <main className='max-w-7xl mx-auto px-4 py-4 md:py-8'> */}
                {showWritePost?(
                    <div style={{minHeight: 'calc(100vh - 100px)'}}>
                        {/* top bar */}
                        {/* <div className='border-b border-gray-200'> */}
                        <div className='sticky top-16 z-10'>
                            <div className='max-w-screen-2xl mx-auto px-4 py-3 flex justify-between items-center'>
                                <div className='flex items-center space-x-4 flex-1'>
                                <button 
                                    onClick={()=>setShowWritePost(false)}
                                    className='bg-green-600 text-white px-4 py-2  hover:bg-green-700 transition-colors'>
                                        Share Experience
                                    </button>


                                    {/* title */}
                                    {/* <input
                                    required
                                    type="text"
                                    placeholder='Title of your story'
                                    value={newPost.title}
                                    onChange={(e)=>setNewPost(
                                        {
                                            ...newPost,
                                            title: e.target.value
                                        }
                                    )}
                                    className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500'/> */}

                                    {/* headline */}
                                    {/* <input
                                    type="text"
                                    placeholder='Headline of your story'
                                    value={newPost.headline}
                                    onChange={(e)=>setNewPost(
                                        {
                                            ...newPost,
                                            headline: e.target.value
                                        }
                                    )}
                                    className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500'/> */}
                                </div>
                                {/* mobile preview toggle */}
                                <div className='flex items-center space-x-3'>
                                    <div className='md:hidden'>
                                        <button 
                                        onClick={()=>setShowPreview(!showPreview)} 
                                        className='px-3 py-1 text-sm border border-gray-200 rounded-full'>
                                            {showPreview? 'Edit': 'Preview'}
                                            </button>
                                        <button className='px-4 py-1.5 ext-gray-600 hover:text-white text-sm'>Save Draft</button>
                                        <button
                                        // onClick={handleSubmitPost} 
                                        onClick={handleSubmitForReview}
                                        className='px-4 py-1.5 bg-green-600 text-white text-sm rounded-full hover:bg-green-700'>
                                            Submit For Review
                                        </button>

                                    </div>

                                </div>

                            </div>


                            <div className='grid grid-cols-2 h-[calc(100vh - 73px)] relative'>

                                    <div className={`border-r border-gray-200 overflow-auto ${showPreview? 'hidden md:block':'block'}`}>
                    <div className='bg-gray-700 p-4 md:p-6 rounded-lg shadow'>

                        {/* <form onSubmit={handleSubmitPost} className='space-y-6'> */}
                        <form onSubmit={handleSubmitForReview} className='space-y-6'>

                            {/* title */}
                            <input
                            required
                            type="text"
                            placeholder='Title of your story'
                            value={newPost.title}
                            onChange={(e)=>setNewPost(
                                {
                                    ...newPost,
                                    title: e.target.value
                                }
                            )}
                            className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500'/>

                            {/* headline */}
                            <input
                            type="text"
                            placeholder='Headline of your story'
                            value={newPost.headline}
                            onChange={(e)=>setNewPost(
                                {
                                    ...newPost,
                                    headline: e.target.value
                                }
                            )}
                            className='w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500'/>

                            

                            {/* Templates */}
                            {/* <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                                {Object.entries(postTemplates).map(([key, template])=>(
                                    <button
                                    key={key}
                                    type="button"
                                    onClick={()=>handleTemplateSelect(key)}
                                    className={`p-4 border rounded-lg text-left hover:border-green-500 ${
                                        activeTemplate === key? 'border-green-500 bg-green-50':'border-gray-200'
                                    }`}>
                                        <BookOpen className='w-6 h-6 mb-2'/>
                                        <h4 className='font-medium'>
                                            {key.replace(/([A-Z])/g,' $1').trim()}
                                        </h4>
                                    </button>

                                ))}

                            </div> */}
                            <div className='mb-6'>
                                <h3 className='text-lg font-medium mb-3'>Choose a Template</h3>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                                {templates.map( template=>(
                                    <button
                                    type="button"
                                    key={template.id}
                                    onClick={()=>handleTemplateSelect(template)}
                                    className={`p-4 border rounded-lg text-left hover:border-green-500 ${
                                        activeTemplate?.id === template?.id? 'border-green-500 bg-green-50':'border-gray-200'
                                    }`}>
                                        <BookOpen className='w-6 h-6 mb-2'/>
                                        <h4 className='font-medium'>
                                            {template.name}
                                        </h4>
                                    </button>

                                ))}

                            </div>
                            </div>

                            

                            <div className='bg-white'>
                                <button
                                type="button" 
                                onClick={()=>handleImageUpload()}
                                className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
                                >
                                    <span>Upload Image</span>
                                </button>
                                
                                {/* <div onClick={()=>handleEditorClick()}> */}
                                < ReactQuill
                                    theme='snow'
                                    ref={quillRef}
                                    className='h-64 mb-20 text-black overflow-auto'
                                                                        // value={editorContent}
                                    // value={editorContent}

                                    value = {newPost.content}
                                    onChange={(content)=>{
                                        setEditorContent(content);
                                        console.log('Editor content changed: ', content)
                                        setNewPost(prev =>({
                                            ...prev,
                                            content: content
                                        }));

                                    }}
                                    modules ={modules}
                                    // formats={formats}
                                />
                                {/* </div> */}
                            </div>

{/* 
                            <div className='flex items-center space-x-2 mb-2 p-2 border border-gray-300 rounded'>
                                <button
                                type="button"
                                onClick={()=>applyFormatting("bold")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="bold">
                                    <Bold className='w-5 h-5'/>
                                </button>

                                <button
                                type="button"
                                onClick={()=>applyFormatting("italic")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="italic">
                                    <Italic className='w-5 h-5'/>
                                </button>

                                <button
                                type="button"
                                onClick={()=>applyFormatting("h1")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Heading 1">
                                    <Heading1 className='w-5 h-5'/>
                                </button>

                                <button
                                type="button"
                                onClick={()=>applyFormatting("h2")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Heading 2">
                                    <Heading2 className='w-5 h-5'/>
                                </button>

                                <button
                                type="button"
                                onClick={()=>applyFormatting("list")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Bullet List">
                                    <List className='w-5 h-5'/>
                                </button>


                                <button
                                type="button"
                                onClick={()=>applyFormatting("qoute")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Quote">
                                    <Quote className='w-5 h-5'/>
                                </button>


                                <button
                                type="button"
                                onClick={()=>applyFormatting("code")}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Code Block">
                                    <Code className='w-5 h-5'/>
                                </button>

                                <button
                                type="button"
                                onClick={insertTable}
                                className='p-2 hover:bg-gray-100 rounded'
                                title="Insert Table">
                                    <Table className='w-5 h-5'/>
                                </button>


                                <label
                                className='p-2 hover:bg-gray-100 rounded cursor-pointer'
                                title="Upload Image">
                                    <Image className='w-5 h-5'/>
                                    <input 
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className='hidden'/>
                                </label>
                        

                            </div> */}


                            {/* content */}
                            {/* <div className='grid grid-cols-2 gap-4'>
                                <div>

                      



                                    <textarea
                                    id ="story-content"
                                    className='w-full h-96 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500'
                                    placeholder='Share your experience...'
                                    value={newPost.content}
                                    onSelect={handleTextSelect}
                                    onChange={(e)=>setNewPost({
                                        ...newPost,
                                        content: e.target.value
                                
                                    })}
                                    />
                                </div>
                                <div className='border border-gray-300 rounded p-4 h-96 overflow-auto'>
                                    <h3 className='text-lg font-medium mb-2'>Preview</h3>
                                    <div
                                    dangerouslySetInnerHTML={{__html: convertContentToHTML(newPost.content)}}
                                    className='prose'>

                                    </div>

                                </div>

                            </div> */}

                            {/* Categories */}
                            <div className='space-y-2'>
                                <h3 className='font-medium'>Categories</h3>
                                <div className='flex flex-wrap gap-2'>
                                    {entrepreneurCategories.map(category=>(
                                        <button
                                        type="button"
                                        key={category.id}
                                        onClick={()=>handleCategoryToggle(category.id)}
                                        className={`px-3 py-1 rounded-full text-sm ${
                                            newPost.categories.includes(category.id)?
                                            'bg-green-100 text-green-800 border-2 border-green-600':
                                            'bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200'
                                        }`}>
                                            {category.name}
                                        </button>
                                    ))}

                                </div>

                            </div>
                            {/* Submit */}
                            <div className='flex justify-end gap-4'>
                                <button
                                type="button"
                                className='px-4 py-2 text-gray-300 hover:text-gray-900 border rounded'
                                onClick={()=>setShowWritePost(false)}>
                                    Cancel
                                </button>
                                {/* <button
                                type="submit"
                                disabled={uploading}
                                className={`px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700
                                ${uploading?'opacity-50 cursor-not-allowed':''}`}>
                                    {uploading?'Publishing...':'Publish'}
                                </button> */}


                                <button
                                type='button'
                                onClick={()=>handleSaveDraft()} 
                                className='px-4 py-1.5 bg-gray-800 text-white text-sm rounded hover:bg-gray-600'>
                                    Save Draft
                                </button>

                                {/* <button
                                className={`px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700
                                    ${uploading?'opacity-50 cursor-not-allowed':''}`}
                                type="submit">
                            Publish
                                </button> */}

                                <button
                                className={`px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700
                                    ${uploading?'opacity-50 cursor-not-allowed':''}`}
                                type="submit">
                            Submit For Review
                                </button>

                            </div>

                        </form>
                        </div>
                        
                    </div>

                            {/* preview */}
                            <div className={`p-4 overflow-auto ${!showPreview?'hidden md:block': 'block'}`}>
                                <h1 className='text-2xl'>Preview</h1>
                        <div className='prose max-w-none'>
                            <h1>{newPost.title}</h1>
                            <div dangerouslySetInnerHTML={{__html: addStylesToHtml(newPost.content)}}/>

                        </div>

                    </div>


            

                    </div>
                    </div>
                    </div>
                     
                    
                        
                      



                    
                ):(
                    <main className='max-w-7xl mx-auto px-4 py-8'>
                        {/* new post button */}
                        <div className='mb-8'>
                            <button
                                onClick={()=>setShowWritePost(true)}
                                className='bg-green-600 text-white px-4 py-2 hover:bg-green-700'
                            >
                                Write A Story
                            </button>
                        </div>
                        {/* draft section */}
                        {drafts.length>0 && (
                                <div className='mb-8'>
                                    <h2 className='text-xl font-bold mb-4'>Drafts</h2>
                                    <div className='space-y-4'>
                                    {drafts.map(draft=>(
                                        <div key={draft.id}
                                        className='bg-white p-4 rounded-lg shadow cursor-pointer hover:shadow-md'
                                        onClick={()=>{
                                            setNewPost(draft);
                                            setShowWritePost(true)
                                        }}>
                                            <h2 className='font-medium'>{draft.title || 'Untitled Draft'}</h2>
                                            <p className='text-sm text-gray-500'>
                                                Last edited {formatDate(draft.updatedAt)}
                                            </p>

                                        </div>
                                    ))}
                                    </div>
                                </div>
                            )}


                        {/* published section */}
                        <h2 className='text-xl font-bold mb-4'>Published</h2>
                        <div className='mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                        
                            {posts.map(post=>(
                                // <BlogPost key={post.id} post={post}/>

                                <article
                                // onClick={() => goToStory(post.title, post.blog_id)}
                                 className='bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow '>
    
                                    <div className=' aspect-[3/2] w-full relative overflow-hidden'>
                                        <img 
                                        src={post.image}
                                        className='absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition-transform duration-300'
                                        />
                                        
                                    </div>
                                    <div className='p-6'>
                                    
                                        
                                        <h3 className='text-xl font-semibold text-gray-700 mb-2'>{post.headline}</h3>
                                        <p className='text-gray-600 mb-4'>{post.headline}</p>
                                         
                                        <button className='text-blue-600 font-semibold hover:text-blue-900 transition-colors flex items-center gap-1'>Read More <ArrowRight className='w-4 h-4'/> </button> 
                                        
                                 
                                    </div>
                                
                                </article>
                                
                            ))}

                        </div>


                        {isAdmin && pendingPosts.length>0 && (
                            <div className='mb-8'>
                                <h2 className='text-xl font-bold mb-4'>Post Pending Review</h2>
                                <div className='space-y-4'>
                                    {pendingPosts.map(post =>(
                                        <div key={post.id} className='bg-gray-700 p-6 rounded-lg shadow'>
                                            <h3 className='text-xl font-bold mb-2'>{post.title}</h3>
                                            {/* <p className='text-gray-600 mb-4'>{post.creator_uid}</p> */}
                                            <div className='prose max-w-none mb-4'>
                                                <div dangerouslySetInnerHTML={{__html: addStylesToHtml(post.content)}}/>

                                            </div>

                                            <div className='flex space-x-4'>
                                                <button
                                                onClick={()=>handleApprovePost(post.id)}
                                                className='px-4 py-2 bg-green-600 text-white rounded-full'>
                                                    Approve
                                                </button>

                                                <button
                                                onClick={()=>handleRejectPost(post.id)}
                                                className='px-4 py-2 bg-red-600 text-white rounded-full'>
                                                    Reject
                                                </button>
                                            </div>


                                        </div>
                                    ))}

                                </div>

                            </div>

                        )}


                                </main>
                            )}
                

            {/* </main> */}
            </div>

           
   
    )

}

export default BlogPlatform;