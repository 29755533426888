import React, {useState, useEffect} from 'react';
import { ReactReader, ReactReaderStyle } from 'react-reader';
import { useParams } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import {getFirestore, collection, query, where, getDocs} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import { BackTop } from 'antd';

const Reader = () =>{
    const {bookId} = useParams();
    const [epubUrl, setEpubUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [location, setLocaton] = useState(0);

    const [size, setSize] = useState(100);
    const [theme, setTheme] = useState('light');
    const [sidebarOpen, setSidebarOpen] = useState('false');
    const [toc, setToc] = useState([]);
    const [rendition, setRendition] = useState(null);



    const auth = getAuth();
    const storage = getStorage();
    const db = getFirestore();

    useEffect(()=>{
        const checkAccessAndGetUrl= async()=>{
            if(!auth.currentUser){
                setError('Please login to read!');
                setLoading(false);
                return;
            }
        

        try{
            const purchaseRef = collection(db, "customers", auth.currentUser.uid, "checkout_sessions");
            const q = query(
                purchaseRef,
                where("userId", "==", auth.currentUser.uid),
                where("bookId", "==", bookId)
            );

            const querySnapshot = await getDocs(q);

            if(!querySnapshot.empty){
                            // setHasAccess(true);
                            try{
                                const epubRef = ref(storage, `Books/${bookId}/${bookId}.epub`);
                                const url = await getDownloadURL(epubRef);
                                setEpubUrl(url);
                                setLoading(false);
                            }catch(err){
                                setError("Failed to retrieve PDF. Please try again later.");
                                console.error("Error fetching PDF: ", err )
            
                            }
                        }
            
                
        }catch(err){
            console.error('Error loading book:', err);
            setError('Failed to load book.');
            setLoading(false);

        }

    }
    checkAccessAndGetUrl();
    

}, [bookId, auth.currentUser]);

useEffect(()=>{
    if(rendition){
        rendition.themes.select(theme);
        
    }
},[theme, rendition]);


// custom styles that override ReactReader defaults
const ownStyles = {
    ...ReactReaderStyle,
    arrow: {
        ...ReactReaderStyle.arrow,
        color: theme === 'dark' ? '#fff' : '#000'
    },
    container: {
        ...ReactReaderStyle.container,
        backgroundColor: theme === 'dark' ? '#1a1a1a' : '#fff',
        color: theme === 'dark' ? '#fff' : '#000'
    }
};

const getRendition = (rendition) =>{
    setRendition(rendition);
    // set default font size
    rendition.themes.fontSize(`${size}%`);

    // apply theme
    if(theme === 'dark'){
        rendition.themes.register('dark',{
            body:{
                color: '#fff',
                background: '#1a1a1a'
            },
            '*':{
                color: 'inherit !important',
                background: 'inherit !important',
            },
            'p, div, span, article, section':{
                color: '#fff !important',
                background: '#1a1a1a !important',
            }
        });
        rendition.themes.select('dark');
    }
    // custom text selection styling
    rendition.themes.default({
        '::selection':{
                background: theme==='dark'?'rgba(255,255,255,0.2)':'rgba(0,0,0,0.1)'
            }
    });

}

if(loading){
    return <div>Loading...</div>
}

if(error){
    return <div className='text-red-600'>{error}</div>
}

return(
    <div className='flex h-screen overflow-hidden'>
        {/* sidebar */}
        <div className={`${sidebarOpen ? 'w64':'w-0'} transition-all duration-300 bg-white dark:bg-gray-800 shadow-lg flex flex-col h-screen`}>
                <div>
                <h2 className='text-lg font-bold mb-4'>Contents</h2>
                </div>
                <div className='flex-1 overflow-y-auto'>
                <nav className='p-4'>
                    {toc.map((item,index)=>(
                        <button
                        key={index} 
                        onClick={()=>setLocaton(item.href)}
                        className='block w-full text-left py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-700 rounded'>
                            {item.label}
                        </button>
                    ))}

                </nav>
                </div>


            </div>
            
 
        {/* main content */}
        <div className='flex-1 flex flex-col'>
            {/* top controls */}
            <div className='flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow'>
                <button
                onClick={()=>setSidebarOpen(!sidebarOpen)}
                className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded'
                >
                    {sidebarOpen? 'X Close':'= Menu'}
                </button>

                {/* <div className='flex items-center gap-4'> */}
                    {/* font size controls */}
                    {/* <div className='flex items-center gap-2'>
                        <button>
                            A-
                        </button>
                        <span>{size}%</span>
                        <button>
                            A+
                        </button> */}
                        {/* Toggle Theme  */}
                        {/* <button
                            onClick={()=>setTheme(theme=='light'?'dark':'light')}
                            className='p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded'
                        >
                            {theme==='light'?'Dark':'Light'}

                        </button>

                    </div>

                </div>  */}

            </div>

            <div className='flex-1 relative'>
                    {epubUrl && (<ReactReader
                    url={epubUrl}
                    location={location}
                    locationChanged={setLocaton}
                    getRendition={getRendition}
                    tocChanged={setToc}
                    styles ={ownStyles}
                    
                    />)}
                </div>

        </div>
        
    </div>

);

}

export default Reader;