import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {query, where, collection, DocumentSnapshot, getDocs, QuerySnapshot, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { FirebaseError } from "firebase/app";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader} from '@fortawesome/free-solid-svg-icons';



const MyBooksScreen = () =>{
    const navigate = useNavigate();
    const user = auth_.currentUser;
    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [CurrentlyReadingBooks, showCurrentlyReadingBooks] = useState([]);
    const [WantToReadBooks, showWantToReadBooks] = useState([]);
    const [Wishlist, showWishlistBooks] = useState([]);
    const [Favorites, showFavoriteBooks] = useState([]);

   

    const getCurrentlyReadingBooks = async() =>{

            // await getDocs(collection(db, "Users", user.uid, "Currently Reading")).then((querySnapshot)=>{
            //     const book = querySnapshot.docs.map((doc)=>({
            //         ...doc.data()
            //     }));

            //         showCurrentlyReadingBooks(book);
            // });
            
            
            const currentlyReadingBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Currently Reading"));
                const currentlyReadingBooks = currentlyReadingBooksSnapshot.docs.map(doc=>
                    doc.data().bookTitle
                );
            
                if(currentlyReadingBooks.length === 0){
                    showCurrentlyReadingBooks([]);
                    return;
            
                }
            
                const bookInfoQuery = query(
                    collection(db, "Books"),
                    where("title", "in", currentlyReadingBooks)
                );
            
                const bookInfoSnapshot = await getDocs(bookInfoQuery);
            
                const matchCurrentlyReadingBooks = bookInfoSnapshot.docs.map(doc =>({
                    id: doc.id,
                    ...doc.data()
            
                }));
            
                showCurrentlyReadingBooks(matchCurrentlyReadingBooks);  
            
        }


        const getFinishedReadingBooks = async() =>{
                // await getDocs(collection(db, "Users", user.uid, "Finished Book")).then((querySnapshot)=>{
                //     const book = querySnapshot.docs.map((doc)=>({
                //         ...doc.data()
                //     }));
                
                //         showFinishedReadingBooks(book);       
                // });   


                  const finishedBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Finished Book"));
                    const finishedReadingBooks = finishedBooksSnapshot.docs.map(doc=>
                        doc.data().bookTitle
                    );
                
                    if(finishedReadingBooks.length === 0){
                        showFinishedReadingBooks([]);
                        return;
                
                    }
                
                    const bookInfoQuery = query(
                        collection(db, "Books"),
                        where("title", "in", finishedReadingBooks)
                    );
                
                    const bookInfoSnapshot = await getDocs(bookInfoQuery);
                
                    const matchFinishedReadingBooks = bookInfoSnapshot.docs.map(doc =>({
                        id: doc.id,
                        ...doc.data()
                
                    }));
                
                    showFinishedReadingBooks(matchFinishedReadingBooks);  
                
            }

            const getWantToReadBooks = async() =>{
                // await getDocs(collection(db, "Users", user.uid, "Want to Read")).then((querySnapshot)=>{
                //     const book = querySnapshot.docs.map((doc)=>({
                //         ...doc.data()
                //     }));
                //         // console.log("BOOKS:");
                //         // console.log(book);
                //         showWantToReadBooks(book);       
                // });   

                    const wantingToReadBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Want to Read"));
                    const wantingToReadReadBooks = wantingToReadBooksSnapshot.docs.map(doc=>
                        doc.data().bookTitle
                    );
                
                    if(wantingToReadReadBooks.length === 0){
                        showWantToReadBooks([]);
                        return;
                
                    }
                
                    const bookInfoQuery = query(
                        collection(db, "Books"),
                        where("title", "in", wantingToReadReadBooks)
                    );
                
                    const bookInfoSnapshot = await getDocs(bookInfoQuery);
                
                    const matchWantToReadBooks = bookInfoSnapshot.docs.map(doc =>({
                        id: doc.id,
                        ...doc.data()
                
                    }));
                
                    showWantToReadBooks(matchWantToReadBooks); 
            }        
        
            const getWishlistBooks = async() =>{
                // await getDocs(collection(db, "Users", user.uid, "Wishlist")).then((querySnapshot)=>{
                //     const book = querySnapshot.docs.map((doc)=>({
                //         ...doc.data()
                //     }));
            
                //         showWishlistBooks(book);       
                // });  
                
                

                const wishlistBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Wishlist"));
                        const wishlistBooks = wishlistBooksSnapshot.docs.map(doc=>
                            doc.data().bookTitle
                        );
                    
                    if(wishlistBooks.length === 0){
                        showWishlistBooks([]);
                        return;
                
                    }
                
                    const bookInfoQuery = query(
                        collection(db, "Books"),
                        where("title", "in", wishlistBooks)
                    );
                
                    const bookInfoSnapshot = await getDocs(bookInfoQuery);
                
                    const matchWishlistBooks = bookInfoSnapshot.docs.map(doc =>({
                        id: doc.id,
                        ...doc.data()
                
                    }));
                
                    showWishlistBooks(matchWishlistBooks); 
                
            } 


            const getFavoriteBooks = async() =>{
                // await getDocs(collection(db, "Users", user.uid, "Favorites")).then((querySnapshot)=>{
                //     const book = querySnapshot.docs.map((doc)=>({
                //         ...doc.data()
                //     }));

                //         showFavoriteBooks(book);       
                // }); 
                
                
                    const favoriteBooksSnapshot = await getDocs(collection(db, "Users", auth_.currentUser.uid, "Favorites"));
                    const favoriteBooks = favoriteBooksSnapshot.docs.map(doc=>
                        doc.data().bookTitle
                    );
                
                    if(favoriteBooks.length === 0){
                        showFavoriteBooks([]);
                        return;
                
                    }
                
                    const bookInfoQuery = query(
                        collection(db, "Books"),
                        where("title", "in", favoriteBooks)
                    );
                
                    const bookInfoSnapshot = await getDocs(bookInfoQuery);
                
                    const matchFavoriteBooks = bookInfoSnapshot.docs.map(doc =>({
                        id: doc.id,
                        ...doc.data()
                
                    }));
                
                    showFavoriteBooks(matchFavoriteBooks); 
            } 



    const onFinishedReadingButton  = () =>{
 
        navigate('/finishedreading');
    
    }

    const onCurrentlyReadingButton  = () =>{
 
        navigate('/currentlyreading');
    
    } 
    
    const onWantToReadButton  = () =>{
 
        navigate('/wantotread');
    
    }

    const onWishlistButton  = () =>{
 
        navigate('/wishlist');

    }

    const onFavoritesButton  = () =>{
 
        navigate('/favorites');
    
    } 

    useEffect(()=>{
        getFinishedReadingBooks();
        getCurrentlyReadingBooks();
        getWantToReadBooks();
        getWishlistBooks();
        getFavoriteBooks();

    },[])
        
    return (
        <section class="bg-gray-50 dark:bg-gray-900">


                <div class=" flex flex-col  items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <h1 class="mb-6 text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">My Library</h1>


                    <div class=" overflow-auto w-full md:mt-0 sm:max-w-2xl xl:pl-0 bg-white rounded-lg shadow dark:border dark:bg-stone-600 dark:border-stone-950 dark:border-4 shadow-lg shadow-stone-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    
                    <div>
                        <div class="flex items-center justify-between">
                        <p class="text-md font-bold text-stone-500 dark:text-stone-950">Finished Reading</p>
                        <button class="hover:text-stone-950" onClick={onFinishedReadingButton}>
                        View All
                        </button>
                        </div>
                    
                    <div class="carousel carousel-center bg-transparent rounded-box max-w-md space-x-4 p-4">
                            
                        {FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div class="carousel-item">
                            
                                     <img class="h-46 w-24 border-black shadow-md shadow-stone-900" src={book.cover? book.cover: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                                </div>
                                     
                                ))  
                            }
                            
                            
                            </div>
                    
                    </div>
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-stone-950 border-2 shadow-md shadow-black"/>
                    </div>

                    <div >
                    <div class="flex items-center justify-between">
                    <p class="text-md font-bold text-stone-500 dark:text-stone-950">Currently Reading</p>
                    <button class="hover:text-stone-950" onClick={onCurrentlyReadingButton}>
                    View All
                    </button>
                    </div>
                    <div class="carousel carousel-center bg-transparent rounded-box max-w-md space-x-4 p-4">
                            
                        {CurrentlyReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div class="carousel-item">
                            
                                     <img class="h-46 w-24 border-black shadow-md shadow-stone-900" src={book.cover? book.cover: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                                </div>
                                     
                                ))  
                            }
                            
                            
                            </div>
                    
                    </div>
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-stone-950 border-2 shadow-md shadow-black"/>
                    </div>
                    <div>
                    <div class="flex items-center justify-between">  
                    <p class="text-md font-bold text-stone-500 dark:text-stone-950">Want to Read</p>
                    <button class="hover:text-stone-950" onClick={onWantToReadButton}>
                    View All
                    </button>
                    </div>
                    <div class="carousel carousel-center bg-transparent rounded-box max-w-md space-x-4 p-4">
                            
                        {WantToReadBooks?.slice(0,4).map((book,idx)=>(
                            <div class="carousel-item">
                            
                                     <img class="h-46 w-24 border-black shadow-md shadow-stone-900" src={book.cover? book.cover: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                                </div>
                                     
                                ))  
                            }
                            
                            
                            </div>
                    
                    </div>
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-stone-950 border-2 shadow-md shadow-black"/>
                    </div>
                    <div>
                    <div class="flex items-center justify-between"> 
                    <p class="text-md font-bold text-stone-500 dark:text-stone-950">Wishlist</p>
                    <button class="hover:text-stone-950" onClick={onWishlistButton}>
                    View All
                    </button>
                    </div>
                    <div class="carousel carousel-center bg-transparent rounded-box max-w-md space-x-4 p-4">
                            
                        {Wishlist?.slice(0,4).map((book,idx)=>(
                            <div class="carousel-item">
                            
                                     <img class="h-46 w-24 border-black shadow-md shadow-stone-900" src={book.cover? book.cover: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                                </div>
                                     
                                ))  
                            }
                            
                            
                            </div>
                    
                    </div>
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-stone-950 border-2 shadow-md shadow-black"/>
                    </div>
                    <div>
                    <div class="flex items-center justify-between">  
                    <p class="text-md font-bold text-stone-500 dark:text-stone-950">Favorites</p>
                    <button class="hover:text-stone-950" onClick={onFavoritesButton}>
                    View All
                    </button>
                    </div>
                    <div class="carousel carousel-center bg-transparent rounded-box max-w-md space-x-4 p-4">
                            
                        {Favorites?.slice(0,4).map((book,idx)=>(
                            <div class="carousel-item">
                            
                                     <img class="h-46 w-24 border-black shadow-md shadow-stone-900" src={book.cover? book.cover: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                                </div>
                                     
                                ))  
                            }
                            
                            
                            </div> 
                    
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-stone-950 border-2 shadow-md shadow-black"/>
                    </div>
                    </div> 
                    </div>
                    </div>                                    
                </div>
     
        </section>


    );
};

export default MyBooksScreen;