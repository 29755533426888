import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {query, collection, setDoc, doc, onSnapshot, getCountFromServer, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_, getProfilePic} from './../firebase';
import { FirebaseError } from "firebase/app";
import FlatList from 'flatlist-react';
import { useEffect } from "react";
import Card from './../components/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning, faChess, faArrowTrendUp, faCheck, faUser, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore } from '@fortawesome/free-solid-svg-icons';

import Papa from "papaparse";
import ReaderProfile from "./readerProfile"
import {v4 as uuidv4} from 'uuid';

import Readers from "./readers";

import {Helmet} from "react-helmet";
import AdsComponent from './../components/AdComponents';
import { count } from "sneaks-api/models/Sneaker";
import {Quote, Sparkles, Loader2, ArrowBigRight,Library, Bell, BookLock, Bookmark, BookMarked, BookOpen, Heart, MessageCircle, Search, Star, TrendingUp, User, User2, Users2} from 'lucide-react';
// import InspirationalQoutes from "./../components/Qoutes";

const Discover = () =>{

    
    const navigate = useNavigate();
    // const querySnapshot = new QuerySnapshot();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;


    const [dailyInspiration, setDailyInspiration] = useState(null);
    const [Users, showReaders] = useState([]);
    const [DiscussionSnippets, showDiscussionSnippets] = useState([]);
    const [BookClubSnippets, showBookClubSnippets] = useState([]);
    const [BookClubMembers, getMembers] = useState([]);



    const [User, showReader] = useState([]);
    const [csvData, parsedCsvData] = useState([]);
    const [isFollowingReader, setAsFollowingReader] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [currentFollower, showFollowers] = useState([]);
    const [eBooks, showEbooks] = useState([]);
    const [books, showBooks] = useState([]);
    const [featuredBook, setRandomFeaturedBook] = useState([]);
    const [isAddToLibraryDropdownOpen, setAddToLibraryDropdownOpen] = useState(false);

    const [isDuplicate, setDuplicate] = useState(false);
    const [isBookAlreadyIn_FinishedBookShelf, setBookAlreadyIn_FinishedBookShelf] = useState(false);
    const [isBookAlreadyIn_CurrentlyReadingBookShelf, setBookAlreadyIn_CurrentlyReadingBookShelf] = useState(false);
    const [isBookAlreadyIn_WantToReadBookShelf, setBookAlreadyIn_WantToReadBookShelff] = useState(false);

    // Radio Buttons
    const [selectedFinishedReadingValue, setSelectedFinishedReadingValue] = useState("option1");
    const [selectedCurrentlyReadingValue, setSelectedCurrentlyReadingValue] = useState("option2");
    const [selectedWantToReadValue, setSelectedWantToReadValue] = useState("option3");

    const [isBookAddedToBookShelfOpen, setBookAddedToBookShelf] = useState(false);


    
    const readerArray = [];
    const followerArray = [];
    const clubMemberArray = [];
    const clubInforArray = [];
    
    const onBookRecsButton  = () =>{
        navigate('/bookrecs');
    }     

    const onPopularBooksButton  = () =>{
        navigate('/popular_books');
    }

    const onEBooksButton  = () =>{
        navigate('/ebooks_unleashed');
    }

 

    const onReadersButton  = () =>{
        navigate('/readers');
    }


    // const checkFollowing = async (reader_uid)=>{
    //     const firestore = getFirestore();
    //     const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

    //     if(followingReader.exists()){
    //         console.log("Member already is following!");

    //         setAsFollowingReader(!isFollowingReader);

    //     }else{
    //         console.log("Member is not following!");
    //         setAsFollowingReader(isFollowingReader);
        

    //     }



    // }


    const followReader = async (reader, reader_uid, reader_email) =>{
        const firestore = getFirestore();
        const date = new Date;
        const followUniqueID = uuidv4();
    
        // console.log(reader_uid);

        const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

        if(followingReader.exists()){
            console.log("Member already is following!");

            setAsFollowingReader(!isFollowingReader);
            deleteDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));
            deleteDoc(doc(firestore,"Users",user.uid,"Following", reader_uid));
            if(followingReader.followID){
                deleteDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followingReader.followID + reader_uid));
            }

        }else{
            console.log("Member is now following!");
            setAsFollowingReader(!isFollowingReader);

            setDoc(doc(firestore,"Users", user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: reader.displayName,
                followingEmail: reader_email,
                followID: followUniqueID,
                followingUID: reader_uid,
            });
            
            setDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followerUID: user.uid,
            });

            setDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followUniqueID+reader_uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
                type: "follow",
                isRead: false
            });

            setDoc(doc(firestore,"mail", user.uid + followUniqueID+reader_uid),{
                to: reader_email,
                message: {
                    subject: "You have a new follower!",
                    html:  user?.displayName + " just started following you! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

        }

    }

    const onBookDetailsButton  = (id, title) =>{

        navigate('/book_details/'+title,{
            state: {
                book_uid: id,
                book_title: title

            }
        });
    }

    const onSearchBookButton  = () =>{
        navigate('/search_books');
    }


    const onViewReaderProfileButton  = (user) =>{

        navigate('/profile/'+user.displayName);
     
    //     navigate('/reader_profile/'+user.displayName.toLowerCase().replace(/ /g,''),{
    //     state: {
    //         id: user.uid,
    //         username: user.displayName,
    //         email: user.email
    //     }
    // });
    }

    const onBookClubButton = (club_name)=>{
        navigate('/bookclub/'+club_name,{
            state: {
                id: club_name
            }});

    }


    const onDiscussionQuestionButton = (club, question) =>{
        navigate('/discussionboardquestion/'+club.toLowerCase().replace(/ /g,"")+"/"+question.toLowerCase().replace(/ /g,"") ,{
            state:{
                user_question: question, 
                club_name: club
            }

        });
    }

    const getTodaysSeed = ()=>{
        const today = new Date();
        return parseInt(
            today.getFullYear().toString()+
                (today.getMonth()+1).toString().padStart(2,'0')+
                today.getDate().toString().padStart(2,'0')

            );
    }

    const getIndexFromSeed =(seed, max)=>{
        const hash = seed %max;
        return Math.abs(hash);

    }


    const getEbooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const books = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
             showEbooks(books);
            //  get a random book to be featured
            //  setRandomFeaturedBook(books[Math.floor(Math.random()*books.length)]);

            const seed = getTodaysSeed();
            const todaysRandomIndex = getIndexFromSeed(seed, books.length);

            setRandomFeaturedBook(books[todaysRandomIndex]);

        });
    }


    const getReaders = async() =>{
        await getDocs(collection(db, "Users")).then((querySnapshot)=>{
            const reader = querySnapshot.docs.filter(doc => doc.data().displayName!=="" && doc.data().photoURL).slice(0,4).map((doc)=>({
                ...doc.data()
            }));
            // if(reader.displayName!=""){
                // showReaders(reader);
                // console.log(reader);
            // }


            
            reader.map(async (x)=>{
                // if(x.displayName!=="" && x.uid){
                readerArray.push(x);
                showReaders(readerArray);
                // console.log(x.displayName);
                // console.log(x.uid);
                const userFollowingInfo =  await getDoc(doc(db, "Users", user.uid, "Following", x.uid));
                // console.log(userFollowingInfo.data());
                if(userFollowingInfo.data()){
                followerArray.push(true);
                showFollowers(followerArray);
                // console.log(userFollowingInfo.data());
                }else{
                    followerArray.push(false);
                    showFollowers(followerArray);
                }
                
                
                // }
            });
        });   
    }

    const getDiscussionBoardQuestionSnippets = async() =>{
        await getDocs(collection(db, "DiscussionBoards","Novice Entrepreneurs","Questions")).then((querySnapshot)=>{
            const questions = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));

            showDiscussionSnippets(questions);

        });   
    }


    const getBookClubSnippets = async() =>{
        const snippet = await getDocs(collection(db, "BookClubs"));
        const clubs  = snippet.docs;

            clubs.forEach( async(club) => {
            clubInforArray.push(club.data());
            showBookClubSnippets(clubInforArray);
                // console.log(club.data());
            const clubInfo =  await getCountFromServer(collection(db, "BookClubs", club.data().bookClubName, "Members"));

            // console.log(clubInfo.data().count)

            clubMemberArray.push(clubInfo.data().count);
            getMembers(clubMemberArray);
            });


        // });   
    }






    // const checkFollowing = ()=>{
    //     Users.map(reader=>{


    //         console.log(reader.displayName);
    //     });


    // }


    // const getReaders = async() =>{

    //     const readersInfo = await getDocs(collection(db, "Users"));
    //     const readerInfo = readersInfo.docs;

        // readerArray.push(readerInfo.data());
        // showReaders(readerArray);

        // readerInfo.forEach( async(reader) => {
            // const userInfo =  await getDoc(doc(db, "Users", reader.data().uid));

            // console.log(userInfo.data())

            // readerArray.push(userInfo.data());
            // showReaders(readerArray);

        


            // const userFollowingInfo =  await getDoc(doc(db, "Users", user.uid, "Following", reader.data().uid));

            // if(userFollowingInfo.data()){
            //     followerArray.push(!isFollowingReader);
            // }else{
            //     followerArray.push(isFollowingReader);
            // }

            
            // showFollowers(followerArray);

        
        //   });


        // await getDocs(collection(db, "Users")).then((querySnapshot)=>{
        //     const reader = querySnapshot.docs.map((doc)=>({
        //         ...doc.data(), id:doc.id, name: doc.displayName
        //     }));

        //     if(reader.name!==""){
        //         showReaders(reader);
    
                  
        //         // console.log(Users,reader)
        //     }


           
            

        // });   


        // const userFollowing = await getDoc(doc(db, "Users", location?.state?.id, "Followers", user.uid));
        // // console.log("Test FOLLOW");
        // // console.log(userFollowing.data());
        // if(userFollowing.data()){

        //     setAsFollowingReader(!isFollowingReader)
        // }
        


    // }
    


    var file = require("./../books/bookDatabase100_General.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results.data)
            },
        });
    }

    const onEBookUnleashedDetailsButton  = (title, description, image, price, link, author, date, id) =>{

        navigate('/ebook_unleashed_detail/'+title,{
            state: {
                book_title: title,
                book_description: description,
                book_image: image,
                book_price: price,
                shop: link,
                book_author: author,
                book_published: date,
                book_category: "ebooks_unleashed",
                uid: id


            }
        });
    }


    const onRaisingMoneyButton  = () =>{
 
        navigate('/raising_money');
    
    }

    const onNonprofitButton  = () =>{
 
        navigate('/nonprofit');
    
    }   
    
    const onBeginnerEntrepreneurButton  = () =>{
 
        navigate('/beginner_entrepreneurship_books');
    
    }

    const onBusinessStrategyButton  = () =>{
 
        navigate('/business_strategy');
    
    }

    const onFinanceButton  = () =>{
 
        navigate('/finance');
    
    }
        
    const onFranchiseButton  = () =>{
 
        navigate('/franchise');
    
    }


   
    
    const onFinancialFreedomBooksButton  = () =>{
 
        navigate('/financial_freedom');
    
    }

    



    const onViewAllBookClubsButton = ()=>{
        navigate("/bookclubs")

    }

    const onViewAllDiscussionBoardButton = ()=>{
        navigate("/discussionboards")

    }

    const MoveFinishedBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_FinishedBookShelf(!isBookAlreadyIn_FinishedBookShelf);   
    }

    const MoveCurrentReadingBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_CurrentlyReadingBookShelf(!isBookAlreadyIn_CurrentlyReadingBookShelf);   
    }

    const MoveWantToReadBook = () =>{
        // console.log("empty message");
        setBookAlreadyIn_WantToReadBookShelff(!isBookAlreadyIn_WantToReadBookShelf);   
    }


    const successAddingBookToShelfNotification = () =>{
        // console.log("success message");
        setBookAddedToBookShelf(!isBookAddedToBookShelfOpen);   
    }

    const handleRadioChangeForFinishedReadingShelf = (value) =>{
   
            setSelectedFinishedReadingValue(value);
  
       
    }

    const handleRadioChangeForCurrentlyReadingShelf = (value) =>{
        setSelectedCurrentlyReadingValue(value);
    }

    const handleRadioChangeForWantToReadReadingShelf = (value) =>{
        setSelectedWantToReadValue(value);
    }

    const onToFinishedBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
            // if(read.exists() || current.exists() || want.exists()){
            //     // return (<div><p>Already exists!</p></div>);
            //     console.log("book exists in shelves. Would you like to move it?");
            //     // if(read.exists()){
            //         MoveFinishedBook();
                
            // // }

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     return (<div></div>);

            //     {/* {console.log("book exists in shelves. Would you like to move it?")}; */}
                

            // }
            if(read.exists()){
                MoveFinishedBook();

            }else if(current.exists()){
                MoveCurrentReadingBook();

            }else if(want.exists()){
                MoveWantToReadBook();

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Currently Reading", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            // if(read.exists || current.exists || want.exists){
            //     console.log("book exists in shelves. Would you like to move it?");

            // }else{
                if(read.exists()){
                    MoveFinishedBook();
    
                }else if(current.exists()){
                    MoveCurrentReadingBook();
    
                }else if(want.exists()){
                    MoveWantToReadBook();
    
                }else{
                setDoc(doc(firestore,"Users",user.uid, "Want to Read", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user.uid, "Wishlist",title));
       

            if(wishlist.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Wishlist", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();

            }

        }catch(event){
            console.log("Error Adding Book: " + event );

        }


    }


    const onToFavoritesBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        setAddToLibraryDropdownOpen(false);
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user.uid, "Favorites",title));
    

            if(favorities.exists){
                // console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Favorites", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                // console.log("done!");
                successAddingBookToShelfNotification();
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onRateBook = (book_name)=>{
        navigate('/rate_book/'+book_name);

    }



    const MantraDisplay = ({text}) => (
        <div className="mt-8 mb-8 bg-gradient-to-r from-purple-50 to-blue-50 rounded-lg p-6">
            <p className="font-bold text-gray-900">Daily Inspiration</p>
            <div className="flex items-center justify-center gap-3">
                <Sparkles className="text-purple-500 w-5 h-5"/>
                    <h3 className="text-2xl font-light tracking-wide text-gray-700">
                        {text}
                    </h3>
                <Sparkles className="text-purple-500 w-5 h-5"/>
            </div>
        </div>

    );

    const QouteDisplay = ({text, author}) => (
        <div className="mt-8 mb-8 bg-gray-50 rounded-lg p-6">
            <p className="font-bold text-gray-900">Daily Inspiration</p>
            <div className="flex items-start gap-3">
                <Quote className="text-blue-500 w-6 h-6 mt-1"/>
                <blockquote className="text-gray-700">
                    <p className="text-lg font-serif italic">"{text}"</p>
                    {author && (
                        <footer className="text-sm text-gray-500 mt-2">
                            - {author}
                        </footer>
                    )}

                </blockquote>
            </div>
        </div>

    );

    const getTodayString = () =>{
        return new Date().toISOString().split('T')[0];

    }

    const getCachedInspiration = () =>{
        const cached = localStorage.getItem('dailyInspiration');
        if(cached){
            const {date, content} = JSON.parse(cached);
            if(date === getTodayString()){
                return content;
            }
        }
        return null;
    }

    const cacheInspiration = (content) =>{
        localStorage.setItem('dailyInspiration', JSON.stringify({
            date: getTodayString(),
            content
        }));
    }

    const fetchDailyInspiration = async() =>{
        try{
            const cached = getCachedInspiration();
            if(cached){
                setDailyInspiration(cached);
                return;

            }

            const collections = ['Mantras', 'InspirationalQoutes'];
            const randomCollection = collections[Math.floor(Math.random()*collections.length)];

            const contentQuery = query(
                collection(db, randomCollection)
            );

            const contentSnapshot = await getDocs(contentQuery);

            if(!contentQuery.empty){

                const randomIndex = Math.floor(Math.random()*contentSnapshot.docs.length);
                const randomDoc = contentSnapshot.docs[randomIndex];
                const content = {
                    id: randomDoc.id,
                    type: randomCollection,
                    ...randomDoc.data()
                }

                cacheInspiration(content);
                setDailyInspiration(content);

            }

        }catch(e){
            console.error('Error fetching daily inspiration', e);
        }
    }


    useEffect(()=>{
        getEbooks();
        getReaders();
        getDiscussionBoardQuestionSnippets();
        getBookClubSnippets();
        // getReader();
        parseFile();
        // checkFollowing();
        // console.log("i fire once");
        fetchDailyInspiration();
    },[user])

    const renderInspiration = ()=>{
        if(!dailyInspiration) return null;

        return dailyInspiration.type === 'qoutes'
        ? <QouteDisplay text={dailyInspiration.qoute} author={dailyInspiration.author}/>
        :<MantraDisplay text ={dailyInspiration.mantra}/>

    }

    


     

    return (
        <section class="bg-gray-50 dark:bg-gray-900">

            {/* <header class="bg-gray-700 shadow-sm sticky top-0 z-50">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div class="flex items-center justify-between">
                        <h1 class="text-3xl font-bold text-gray-300">Welcome!</h1>
                        <div class="hidden md:flex flex-1 max-w-2xl mx-8">
                            <div class="relative w-full">
                                <input 
                                class="w-full px-4 py-2 pl-10 pr-4 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                type="text"
                                placeholder="Search books"/>
                                <Search class="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            </div>
                        </div>
                        <nav class="hidden md:flex items-center space-x-8">
                            <a>Discover</a>
                            <a>Books</a>
                            <a>Book Club</a>
                            <a>Discussions</a>
                            <div class="flex items-center space-x-4"> */}
                                {/* notifications bell */}
                               {/* <button class="text-gray-600 hover:text-gray-900">
                                    <Bell class="h-6 w-6"/>
                                </button>
                                <div class="flex items-center space-x-2 cursor-pointer">
                                    <img
                                    alt="User Profile Pic"
                                    class="w-8 h-8 rounded-full"/>
                                    <span class="text-sm font-medium">User Name</span>
                                </div>
                            </div>
                        </nav>
                        <button class="md:hidden">
                            <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h15M4 18h16"/>

                            </svg>
                        </button>
                    </div> */}
                    {/* mobile search */}
                    {/* <div class="mt-4 md:hidden">
                        <input
                        class="w-full px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        type="text"
                        placeholder="Search books" 
                        />
                    </div>
                </div>
            </header> */}

        
           
                <main class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">

                {renderInspiration()}
                    {/* Featured Book Section */}
                    <section className="mb-12 bg-gradient-to-r from-gray-700 to-blue-500 rounded-xl text-white overflow-auto">
                        
                    {featuredBook? (
                        <div className="flex flex-col md:flex-row items-center p-8" >
                            <img
                            src={featuredBook.cover}
                            alt = {featuredBook.title} 
                            class="w-48 h-72 object-cover rounded-lg shadow-lg mb-6 md:mb-0 md:mr-8"
                            />
                            <div className="flex-1" >
                                <div className="flex items-center mb-2">
                                    <span class="px-3 py-1 bg-white/20 rounded-full text-sm hover:bg-purple-900 cursor-pointer" onClick ={()=>onBookDetailsButton( featuredBook.uid, featuredBook.title)}>Featured Book</span>
                                </div>
                                <h2 className="text-3xl font-bold mb-2">{featuredBook.title}</h2>
                                <p className="text-xl mb-2">By {featuredBook.author}</p>
                                <p className="text-lg mb-6">{featuredBook.description}</p>
                                <div className="flex space-x-4">
                                    {(featuredBook?.ebooks_unleashed===true && featuredBook?.price==="FREE")?(<button 
                                    class="mt-4 mb-4 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2">
                                        <BookOpen class="h-4 w-4"/>
                                        Read Now
                                    </button>):("")}
                                    <button 
                                    class="mt-4 mb-4 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2"
                                    onClick={()=>onRateBook(featuredBook?.title)}>
                                        <Star class="h-4 w-4"/>
                                        Rate Book
                                    </button> 
                                    <div class="relative">
                                    <button class="w-full mt-4 mb-4 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-900 transition-colors flex items-center justify-center gap-2"
                                    onClick={()=>setAddToLibraryDropdownOpen(!isAddToLibraryDropdownOpen)}>
                                        <Library class="h-4 w-4"/>
                                        Add to Shelf
                                    </button>
                                    {isAddToLibraryDropdownOpen && (
                                        <div class="absolute top-full left-0 w-full mt-1 bg-white border border-gray-200 text-gray-900 rounded-lg shadow-lg py-1 z-10">
                                            <button 
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 
                                                    onClick={(e)=>onToFinishedBooksShelf(featuredBook?.title, featuredBook?.category, featuredBook?.cover,featuredBook?.uid,e)}>
                                                        <BookLock class="h-4 w-4 text-blue-500"/>
                                                        Finished Reading
                                                    </button>
                                                    <button
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 
                                                    onClick={(e)=>onToCurrentlyReadingBooksShelf(featuredBook?.title, featuredBook?.category, featuredBook?.cover,e)}>
                                                        <BookOpen class="h-4 w-4 text-blue-500"/>
                                                        Currently Read
                                                    </button>
                                                    <button
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left" 

                                                    onClick={(e)=>onToWantToReadBooksShelf(featuredBook?.title, featuredBook?.category, featuredBook?.cover, featuredBook?.uid, e)}>
                                                        <BookMarked class="h-4 w-4"/>
                                                        Want to Read
                                                    </button>
                                                    <button 
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                                        onClick={(e)=>onToWishlistBooksShelf(featuredBook?.title, featuredBook?.category, featuredBook?.cover,featuredBook?.uid,e)}>
                                                            <Star class="h-4 w-4 text-yellow-500"/>
                                                            Add to Wishlist
                                                        </button>
                                                    <button
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                                        onClick={(e)=>onToFavoritesBooksShelf(featuredBook?.title, featuredBook?.category, featuredBook?.cover,featuredBook?.uid,e)}>
                                                        <Heart class="h-4 w-4 text-red-500"/>
                                                        Add to Favorites
                                                    </button>
                                                    {/* <div class="border-t border-gray-200 my-1"></div>
                                                    <button
                                                    class="w-full px-4 py-2 hover:bg-gray-100 flex items-center gap-2 text-left"
                                                        // onClick={}
                                                        >
                                                        <BookPlus class="h-4 w-4"/>
                                                        Create New Shelf
                                                    </button> */}
                                                </div>
                                            )}  
                                        </div>

                                </div>
                            </div>

                        </div> 
                        ):(<div>Loading...</div>)}
                        
                    </section>

                    <AdsComponent dataAdSlot='8393832390'/>

                    {/* <InspirationalQoutes/> */}

                    {/* Categories */}
                    <section class="mb-12">
                        <h3 class="text-2xl font-bold text-gray-300 mb-6 flex items-center">
                            <Bookmark class="mr-2"/> Categories                           
                        </h3>
                        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    <button class=" w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onBusinessStrategyButton}>  
                        Business Strategy
                        
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChess} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFinanceButton}>
                        Finance  
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faArrowTrendUp} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFranchiseButton}>
                        Franchise
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faStore} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onNonprofitButton}>
                        Nonprofit Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faSchool} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onRaisingMoneyButton}>
                        Raising Money For Your Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faDollarSign} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onBeginnerEntrepreneurButton}>
                        Business Lessons
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChalkboardTeacher} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onPopularBooksButton}>
                        Popular Books
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faFire} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFinancialFreedomBooksButton}>
                        Financial Freedom
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faBoltLightning} size ="2xl"/>
                    </button>
                    </div>
                    </section>                    
{/* 
                    <button onClick={onBookRecsButton}>Book Recommendations</button>
                    <button onClick={onSearchBookButton}>Search More Books</button> */}

                    
                    {/* Connect with Readers */}
                    <section class="bg-gray-700 p-6 rounded-lg shadow-sm">
                  
                    <div class="flex flex-col md:flex-row items-center justify-between mb-8">
                        <div>
                        <h3 class="text-xl font-bold text-gray-300 mb-4 flex items-center">
                           <User2 class="mr-2"/> Connect With Readers
                        </h3>
                        </div>
                        <a
                            onClick={onReadersButton} 
                            class="mt-2 mb-2 cursor-pointer flex items-center gap-2 text-gray-200 hover:text-blue-600"
                            >
                                
                                <span class="text-sm">
                                        Search For More Readers 
                                </span>
                                <ArrowBigRight size={20}/>

                        </a>
                    </div>
                      
                        <hr class="mt-2 mb-2"/>

                        <div class="space-y-4">
                        {Users?.slice(0,4).map((reader,idx)=>( 
                            //  <div onClick={()=> onViewReaderProfileButton(reader)} className="card-home"> 
                            <div onClick={()=>onViewReaderProfileButton(reader)} className="flex items-center space-x-3 hover:bg-gray-900 p-2 rounded cursor-pointer"> 

                             <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="w-20 h-20 rounded-full"/>
                             <div>
                             
                                <h4 key={idx}
                                class="font-medium">{reader.name}
                                </h4>

                                {/* <div className="flex gap-2">
                               
                                {currentFollower[idx]? <button className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={()=> followReader(reader, reader.uid, reader.email)} >Following <FontAwesomeIcon icon={faCheck}/></button>:<button className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={()=> followReader(user, reader.uid, reader.email)}>Follow</button>}                             
                                <button onClick={()=>onViewReaderProfileButton(reader)}>View Profile</button>
                                </div>  */}
                                
                            </div>

                      </div> 

                        ))}

                        </div>

                    </section>

                    <section class="mt-6 bg-gray-700 p-6 rounded-lg shadow-sm">
                        <div class="flex flex-col md:flex-row items-center justify-between mb-8">
                            <div>
                            <h3 class="text-xl font-bold text-gray-300 mb-4 flex items-center">
                            <TrendingUp class="mr-2"/> Popular Books
                            </h3>
                            </div>
                            <a
                                onClick={onPopularBooksButton} 
                                class="mt-2 mb-2 cursor-pointer flex items-center gap-2 text-gray-200 hover:text-blue-600"
                                >
                                    
                                    <span class="text-sm">
                                           View All Popular Books
                                    </span>
                                    <ArrowBigRight size={20}/>

                            </a>
                        </div>
                      
                        <hr class="mt-2 mb-2"/>

                        {eBooks?.filter(data => data.category=="Popular Books/General").slice(0,4).map((data,idx)=>(
                            <div class="flex items-center space-x-3 hover:bg-gray-900 p-2 rounded cursor-pointer" onClick={() =>onBookDetailsButton(data.uid, data.title)} >
                                <img className="w-24 h-48" src={data.cover} /> 
                                <div>
                                    <h4 class="font-medium group-hover:text-blue-600 transition-colors">{data.title} </h4>
                                    <p class="text-sm text-gray-200">By {data.author} </p>
                                </div>
                            </div>
                           
               
                           )) 
                            }

                    </section>

                    <section class="mt-6 bg-gray-700 p-6 rounded-lg shadow-sm">
                        <div class="flex flex-col md:flex-row items-center justify-between mb-8">
                            <div>
                                <h3 class="text-xl font-bold text-gray-300 mb-4 flex items-center">
                                <Users2 class="mr-2"/> Active Groups
                                </h3>
                            </div>
                            <a
                                onClick={()=>onViewAllBookClubsButton()} 
                                class="mt-2 mb-2 cursor-pointer flex items-center gap-2 text-gray-200 hover:text-blue-600"
                                >
                                    
                                    <span class="text-sm">
                                           Create A Group
                                    </span>
                                    <ArrowBigRight size={20}/>

                            </a>
                        </div>
                
                        <div class="space-y-4">
                        {BookClubSnippets?.map((club,idx)=>( 
                            <div className="border-b last:border-0 pb-4 last:pb-0 hover:bg-gray-900 cursor-pointer transition-colors" onClick={()=>onBookClubButton(club.bookClubName)}> 
                             
                            <h4 class="font-medium">{club.bookClubName}</h4> 
                             <p class="text-sm text-gray-300"><FontAwesomeIcon icon={faUser}/> {BookClubMembers[idx]} Members</p>
                        
                      </div> 

                        ))    
       
                     }
                     </div>

                    </section>


                    <section class="mt-6 bg-gray-700 p-6 rounded-lg shadow-sm">
                        <div class="flex flex-col md:flex-row items-center justify-between mb-8">
                            <div>
                            <h3 class="text-xl font-bold text-gray-300 mb-4 flex items-center">
                            <BookOpen class="mr-2"/> Ebooks Available To Read and Purchase On Platform
                            </h3>
                            </div>
                            <a
                                onClick={onEBooksButton} 
                                class="mt-2 mb-2 cursor-pointer flex items-center gap-2 text-gray-200 hover:text-blue-600"
                                >
                                    
                                    <span class="text-sm">
                                           View More
                                    </span>
                                    <ArrowBigRight size={20}/>

                            </a>
                        </div>
                      
                        <hr class="mt-2 mb-2"/>

                        {eBooks?.filter(data => data.ebooks_unleashed==true).slice(0,4).map((data,idx)=>(
                            <div class="flex items-center space-x-3 hover:bg-gray-900 p-2 rounded cursor-pointer"onClick={() =>onBookDetailsButton(data.uid, data.title)} >
                                <img className="w-24 h-48" src={data.cover} /> 
                                <div>
                                    <h4 class="font-medium group-hover:text-blue-600 transition-colors">{data.title} </h4>
                                    <p class="text-sm text-gray-200">By {data.author} </p>
                                </div>
                            </div>
                           
               
                           )) 
                        }

                    </section>

                    <section class="mt-8">
                        <div class="flex flex-col md:flex-row items-center justify-between mb-8">
                            <div>
                                <h3 class="text-2xl font-bold text-gray-300 mb-6 flex items-center">
                                    <MessageCircle class="mr-2"/> Discussions
                                </h3>
                            </div>
                            <a
                            class="mt-2 mb-2 cursor-pointer flex items-center gap-2 text-gray-200 hover:text-blue-600" 
                            onClick={()=>onViewAllDiscussionBoardButton()}>
                                <span class="text-sm">
                                    View/Create More Discussions!
                                </span>
                                <ArrowBigRight size={20}/>
                            </a>
                           
                        </div>
                        <div class="bg-gray-700 rounded-lg shadow-sm">
                            {DiscussionSnippets?.map((question,idx)=>( 
                                <div className="border-b last:border-0 p-4 hover:bg-gray-900 cursor-pointer transition-colors" onClick={()=>onDiscussionQuestionButton("Novice Entrepreneurs", question.question)}> 
                                    <h4 class="font-medium" key={idx}>{question.question}</h4> 
                                </div> 
                                ))    
                            }
                        </div>

                    </section>
            
                    <AdsComponent  dataAdSlot='3973055866'/>

                </main>
        </section>
    );
                
};

export default Discover;